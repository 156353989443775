import { Navigate, Route, Routes } from 'react-router-dom';
import CoachSearch from './coach-search';
import CoachSearchViewCoach from './coach-search-view-coach';

const CoachSearchRouting = () => {
  return (
    <Routes>
      <Route path='' element={<CoachSearch />} />
      <Route path='*' element={<Navigate replace to='' />} />
      <Route path=':uid' element={<CoachSearchViewCoach />} />
    </Routes>
  );
};

export default CoachSearchRouting;
