import { ICoachDao } from 'core/api/coach/coach-api.interface';
import StorageApiService from 'core/api/storage/storage-api.service';
import { CoachPlan } from 'core/constants/coach-plan';
import { useState, useCallback, useEffect } from 'react';
import { AlertCircle, User } from 'react-feather';
import SharedSpinner from 'shared/spinner/spinner';
import Recommended from 'assets/images/recommended_badge.png';
import Sponsor from 'assets/images/sponsor_badge.png';
import { Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';

interface ICoachSearchTableRow {
  coach: ICoachDao;
}

const CoachSearchTableRow = ({ coach }: ICoachSearchTableRow) => {
  const [avatar, setAvatar] = useState<string>();
  const [avatarState, setAvatarState] = useState('loading');
  const navigate = useNavigate();

  const getAvatar = useCallback(async (path: string) => {
    try {
      const downloadUrl = await StorageApiService.getFileDownloadUrl(path);
      setAvatar(downloadUrl);
      setAvatarState('success');
    } catch (error) {
      setAvatarState('error');
    }
  }, []);

  useEffect(() => {
    if (coach.profileImagePath) {
      getAvatar(coach.profileImagePath);
    } else {
      setAvatarState('unspecified');
    }
  }, [coach.profileImagePath, getAvatar]);

  const getBadges = () => {
    const recommended = (
      <Tooltip title='Recommended' color='#FF875B'>
        <img src={Recommended} alt='recommended' className='max-h-[28px]' />
      </Tooltip>
    );
    const sponsor = (
      <Tooltip title='Coaching sponsor 2024/25' color='#DA004D'>
        <img src={Sponsor} alt='sponsor' className='max-h-[28px]' />
      </Tooltip>
    );

    return (
      <div className='flex space-x-4 items-center'>
        {coach.recommended && recommended}
        {coach.plan === CoachPlan.GIFT_BEARER && sponsor}
      </div>
    );
  };

  return (
    <div className='flex justify-between items-center cursor-pointer' onClick={() => navigate(coach.uid)}>
      <div className='flex items-center'>
        <div className='h-[70px] w-[70px] bg-gray-200 rounded-full mr-6 relative flex justify-center items-center overflow-hidden'>
          {avatarState === 'loading' && <SharedSpinner color='#FF875B' size={24} />}
          {avatarState === 'error' && <AlertCircle color='red' size={24} />}
          {avatarState === 'unspecified' && <User size={32} className='text-gray-600' />}
          {avatarState === 'success' && <img src={avatar} alt='avatar' className='w-full' />}
        </div>
        <p className='text-[16px] mr-8'>{coach.fullName}</p>
        {getBadges()}
      </div>
    </div>
  );
};

export default CoachSearchTableRow;
