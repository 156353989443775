import { App, Button, Form, Space, Upload, UploadProps } from 'antd';
import { useForm } from 'antd/es/form/Form';
import CoachApiService from 'core/api/coach/coach-api.service';
import SchoolApiService from 'core/api/school/school-api.service';
import StorageApiService from 'core/api/storage/storage-api.service';
import { FileType } from 'core/helpers/interface.helpers';
import { getActionTimestampFromUser } from 'core/helpers/user-action.helpers';
import { useUserState } from 'core/providers/user-provider';
import { deleteField } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';

interface ISharedEditProfilePhoto {
  closeDrawer: () => void;
  accountType: 'coach' | 'school';
}

const SharedEditProfilePhoto = ({ closeDrawer, accountType }: ISharedEditProfilePhoto) => {
  const { userData, coachData, schoolData } = useUserState();
  const [form] = useForm();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const { message } = App.useApp();
  const [photoImageUrl, setPhotoImageUrl] = useState<string | undefined>();
  const [photoImageFileList, setPhotoImageFileList] = useState<FileType[]>([]);
  const [removing, setRemoving] = useState(false);
  const [photoRemovable, setPhotoRemovable] = useState(false);
  const isCoach = accountType === 'coach';

  const getAvatar = useCallback(
    async (path: string) => {
      try {
        const downloadUrl = await StorageApiService.getFileDownloadUrl(path);
        setPhotoImageUrl(downloadUrl);
      } catch (error) {
        message.error('The existing profile photo could not be fetched');
      }
    },
    [message]
  );

  useEffect(() => {
    if (isCoach && coachData?.profileImagePath) {
      setPhotoRemovable(true);
      getAvatar(coachData.profileImagePath);
    }

    if (!isCoach && schoolData?.profilePhotoPath) {
      setPhotoRemovable(true);
      getAvatar(schoolData?.profilePhotoPath);
    }
  }, [coachData?.profileImagePath, getAvatar, isCoach, schoolData?.profilePhotoPath]);

  const onFinish = async (data: any) => {
    if (photoImageFileList.length > 0) {
      try {
        setFormSubmitting(true);
        const photo = photoImageFileList[0];
        const photoPath = isCoach
          ? `coach-profile/${coachData?.uid}/${photo.name}`
          : `school-profile/${schoolData?.uid}/${photo.name}`;

        await StorageApiService.uploadFile(photoPath, photo, photo.type ?? 'image/jpeg');
        if (isCoach) {
          await CoachApiService.update(coachData?.uid!, {
            profileImagePath: photoPath,
            updated: getActionTimestampFromUser(userData),
          });
        } else {
          await SchoolApiService.update(schoolData?.uid!, {
            profilePhotoPath: photoPath,
            updated: getActionTimestampFromUser(userData),
          });
        }
        closeDrawer();
      } catch (err) {
        message.info('Failed to upload new photo, please try again');
        setFormSubmitting(false);
      }
    } else {
      message.info('Please upload a new photo to save');
    }
  };

  const photoProps: UploadProps = {
    beforeUpload: (file: FileType) => {
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        message.info("We're sorry, but only jpg or png is supported");
        return;
      }

      if (!file.size || file.size > 2000000) {
        message.info("We're sorry, but files must be under 2MB");
        return;
      }
      setPhotoImageFileList([file]);
      const reader = new FileReader();
      reader.onload = () => setPhotoImageUrl(reader.result as string);
      reader.readAsDataURL(file);
      return false;
    },
    showUploadList: false,
    listType: 'picture-circle',
    maxCount: 1,
    fileList: photoImageFileList,
  };

  const removeExistingPhoto = async () => {
    setRemoving(true);
    try {
      if (isCoach) {
        await CoachApiService.update(coachData?.uid!, {
          profileImagePath: deleteField(),
          updated: getActionTimestampFromUser(userData),
        });
      } else {
        await SchoolApiService.update(schoolData?.uid!, {
          profilePhotoPath: deleteField(),
          updated: getActionTimestampFromUser(userData),
        });
      }

      closeDrawer();
    } catch (err) {
      message.error('The existing photo could not be removed, please try again');
    }
  };

  return (
    <Form
      name='edit-profile-photo-form'
      form={form}
      onFinish={onFinish}
      layout='vertical'
      variant='filled'
      requiredMark={false}
      disabled={formSubmitting}
    >
      <Form.Item label='Profile photo' name='profilePhoto'>
        <Upload {...photoProps}>
          {photoImageUrl ? (
            <div className='h-[90px] w-[90px] flex items-center justify-center rounded-full overflow-hidden'>
              <img src={photoImageUrl} alt='avatar' />
            </div>
          ) : (
            <button style={{ border: 0, background: 'none' }} type='button'>
              <div style={{ marginTop: 8 }}>Upload</div>
            </button>
          )}
        </Upload>
      </Form.Item>

      <Form.Item className='flex justify-end'>
        <Space className='md:mt-4'>
          {photoRemovable && (
            <Button onClick={removeExistingPhoto} loading={removing} danger>
              Remove existing photo
            </Button>
          )}
          <Button type='primary' htmlType='submit' loading={formSubmitting}>
            Save
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default SharedEditProfilePhoto;
