import { Input, Button, Form, Flex, Upload, UploadProps, App } from 'antd';
import { useEffect, useState } from 'react';
import SharedAddressSearch from 'shared/address-search/address-search';
import { IAddressDao } from 'core/api/address/address-api-interface';
import { ISchoolRegistrationChild } from './school-registration';
import { FileType } from 'core/helpers/interface.helpers';

const SchoolRegistrationSchoolDetails = ({ nextStep, save, existingDetails }: ISchoolRegistrationChild) => {
  const [form] = Form.useForm();
  const [address, setAddress] = useState<IAddressDao>();
  const [addressError, setAddressError] = useState<string>();
  const { message } = App.useApp();
  const [photoImageUrl, setPhotoImageUrl] = useState<string | undefined>();
  const [photoImageFileList, setPhotoImageFileList] = useState<FileType[]>([]);

  useEffect(() => {
    if (existingDetails) {
      const { address, profilePhoto, ...rest } = existingDetails;
      form.setFieldsValue(rest);
      setAddress(existingDetails.address);

      if (profilePhoto) {
        setPhotoImageUrl(profilePhoto.url);
        setPhotoImageFileList([profilePhoto.file]);
      }
    }
  }, [existingDetails, form]);

  const onFinish = async (data: any) => {
    if (!address) {
      setAddressError('You must select a valid address from the results list');
      return;
    }

    save({
      ...data,
      address,
      ...(photoImageFileList.length > 0 && {
        profilePhoto: {
          file: photoImageFileList[0],
          url: photoImageUrl,
        },
      }),
    });
    nextStep();
  };

  const photoProps: UploadProps = {
    beforeUpload: (file: FileType) => {
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        message.info("We're sorry, but only jpg or png is supported");
        return;
      }

      if (!file.size || file.size > 2000000) {
        message.info("We're sorry, but files must be under 2MB");
        return;
      }
      setPhotoImageFileList([file]);
      const reader = new FileReader();
      reader.onload = () => setPhotoImageUrl(reader.result as string);
      reader.readAsDataURL(file);
      return false;
    },
    showUploadList: false,
    listType: 'picture-circle',
    maxCount: 1,
    fileList: photoImageFileList,
  };

  return (
    <Form
      name='school-registration-school-details-form'
      form={form}
      onFinish={onFinish}
      layout='vertical'
      variant='filled'
      requiredMark='optional'
    >
      <Form.Item label='Profile photo' name='profilePhoto'>
        <Upload {...photoProps}>
          {photoImageUrl ? (
            <div className='h-[90px] w-[90px] rounded-full flex items-center justify-center overflow-hidden'>
              <img src={photoImageUrl} alt='avatar' />
            </div>
          ) : (
            <button style={{ border: 0, background: 'none' }} type='button'>
              <div style={{ marginTop: 8 }}>Upload</div>
            </button>
          )}
        </Upload>
      </Form.Item>
      <Form.Item
        label='School name'
        name='schoolName'
        rules={[{ required: true, message: 'Please enter the name of our school' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='Name of main staff contact'
        name='mainStaffContact'
        rules={[{ required: true, message: 'Please enter the name of your main staff contact' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='Email address'
        name='emailAddress'
        rules={[{ type: 'email', required: true, message: 'Please enter a valid email address' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label='Address of school' required>
        <SharedAddressSearch onChange={setAddress} value={address} error={addressError} setError={setAddressError} />
      </Form.Item>

      <Form.Item>
        <Flex gap='small' className='md:mt-4'>
          <Button type='primary' htmlType='submit' block>
            Next
          </Button>
        </Flex>
      </Form.Item>
    </Form>
  );
};

export default SchoolRegistrationSchoolDetails;
