import { Button, Input, Popconfirm } from 'antd';
import { AccountType } from 'core/constants/account-type';
import { CoachPlan } from 'core/constants/coach-plan';
import { isNotNullOrEmpty } from 'core/helpers/null-checkers';
import { useUserState } from 'core/providers/user-provider';
import { useState } from 'react';

interface IConversationInput {
  sendMessage: (message: string | undefined) => Promise<void>;
  sending: boolean;
}

const ConversationInput = ({ sendMessage, sending }: IConversationInput) => {
  const [message, setMessage] = useState<string>();
  const { userData, coachData } = useUserState();
  const isCoach = userData?.accountType === AccountType.COACH;
  const canSendCustomMessages = !isCoach || coachData?.plan !== CoachPlan.GROUND_BREAKER;

  const handleMessageSend = async (message: string | undefined) => {
    if (!isNotNullOrEmpty(message) || !userData) {
      return;
    }

    await sendMessage(message);
    setMessage(undefined);
    setTimeout(() => {
      document.getElementById('messageInput')?.focus();
    }, 100);
  };

  const templates = [
    {
      key: 'introduction',
      title: 'Introduction',
      content:
        "Dear Head/Wellbeing Lead, \n\nAs part of Coaches in Kind's social mission, I am able to offer some pro bono coaching sessions to either a child or young person, or to a school professional.\n\nIf you would be interested in hearing more about this offer, please reply to this message with the best contact details and I will be in touch with more information. Please note: I cannot write personal messages via this service so will require your email address or phone number to reply.",
    },
    {
      key: 'followUp',
      title: 'Follow up',
      content:
        'Thank you for your message. Unfortunately, I cannot send personal messages via this service so please could you let me know how I can contact you via an email address or phone number.',
    },
  ];
  return (
    <div className='basis-[72px] flex-grow-0 flex-shrink-0 bg-gray-50 border-t p-4'>
      {!canSendCustomMessages && (
        <>
          <p className='font-semibold mb-1'>Send a template message</p>
          <div className='flex space-x-2'>
            {templates.map((template) => {
              const content = `${template.content}\n\nBest wishes\n\n${coachData.fullName}`;
              return (
                <Popconfirm
                  okButtonProps={{ loading: sending }}
                  key={template.key}
                  icon={<></>}
                  placement='top'
                  title='Template message'
                  description={
                    <p className='whitespace-pre-wrap max-w-[300px]'>{`Are you sure you want to send this message? \n\n${content}`}</p>
                  }
                  onConfirm={() => sendMessage(template.content)}
                >
                  <Button>{template.title}</Button>
                </Popconfirm>
              );
            })}
          </div>
        </>
      )}
      {canSendCustomMessages && (
        <form
          className='flex items-center space-x-2'
          onSubmit={(event) => {
            event.preventDefault();
            handleMessageSend(message);
          }}
        >
          <Input
            autoFocus
            disabled={sending}
            value={message}
            onChange={(event) => setMessage(event.currentTarget.value)}
            size='large'
            id='messageInput'
          />

          <Button loading={sending} size='large' type='primary' htmlType='submit'>
            Send
          </Button>
        </form>
      )}
    </div>
  );
};

export default ConversationInput;
