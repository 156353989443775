import { Navigate, Route, Routes } from 'react-router-dom';
import OurSchool from './our-school';

const OurSchoolRouting = () => {
  return (
    <Routes>
      <Route path='' element={<OurSchool />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default OurSchoolRouting;
