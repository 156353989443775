import UsersApiService from 'core/api/user/user-api.service';
import { auth } from 'core/config/firebase';
import { useDialog } from 'core/providers/dialog-provider';
import { signInWithEmailAndPassword, updatePassword } from 'firebase/auth';
import { useState, useEffect } from 'react';
import SharedDialogBase from './dialog-base';
import { useUserState } from 'core/providers/user-provider';

import { getActionTimestampFromUser } from 'core/helpers/user-action.helpers';
import { App, Button, Form, Input, Space } from 'antd';
import { assertIsError } from 'core/helpers/asset-is-error';

const SetPasswordDialog = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const dialog = useDialog();
  const { userData, user } = useUserState();
  const { message } = App.useApp();
  const [form] = Form.useForm();

  useEffect(() => {
    if (userData?.resetPassword === false) {
      dialog?.closeDialog();
    }
  }, [dialog, userData?.resetPassword]);

  const onFinish = async (data: any) => {
    try {
      setFormSubmitting(true);

      if (!userData || !user) {
        throw new Error('An error has occurred, if this persists, contact your administrator.');
      }

      if (data.newPassword !== data.confirmNewPassword) {
        throw new Error('New passwords do not match');
      }

      try {
        await signInWithEmailAndPassword(auth, userData.emailAddress, data.currentPassword);
      } catch (error) {
        throw new Error('Incorrect email address or password');
      }

      try {
        await updatePassword(user, data.newPassword);
        await UsersApiService.update(user.uid, {
          resetPassword: false,
          updated: getActionTimestampFromUser(userData),
        });
      } catch (error) {
        throw new Error('An error has occurred, if this persists, contact your administrator.');
      }
    } catch (error) {
      setFormSubmitting(false);
      const assertedError = assertIsError(error);
      message.error(assertedError.message);
    }
  };

  const customContent = () => {
    return (
      <div className='px-4 pt-4 overflow-y-auto'>
        <p className='text-sm mb-4'>
          You are required to set a new password in order to continue. You will be asked to login after your new
          password has been set
        </p>
        <div>
          <Form
            name='admin-user-form'
            form={form}
            onFinish={onFinish}
            layout='vertical'
            variant='filled'
            requiredMark={false}
            disabled={formSubmitting}
          >
            <Form.Item
              label='Current password'
              name='currentPassword'
              rules={[{ required: true, message: 'Please enter your current password' }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label='New password'
              name='newPassword'
              rules={[{ required: true, message: 'Please enter a new password' }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label='Confirm new password'
              name='confirmNewPassword'
              rules={[{ required: true, message: 'Please confirm your new password' }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Space className='md:mt-4'>
                <Button type='primary' htmlType='submit' loading={formSubmitting}>
                  Submit
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  return <SharedDialogBase title='Set your password' customContentTemplate={customContent()} showButtons={false} />;
};

export default SetPasswordDialog;
