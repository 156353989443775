import { Button, Checkbox, Flex, Form, Input } from 'antd';
import { ICoachRegistrationChild } from './coach-registration';
import { useEffect } from 'react';
import { SpecialismQualificationData } from 'core/constants/specialism-qualification';

const CoachRegistrationSpecialisms = ({ nextStep, previousStep, save, existingDetails }: ICoachRegistrationChild) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (existingDetails) {
      form.setFieldsValue(existingDetails);
    }
  }, [existingDetails, form]);

  const onFinish = (data: any) => {
    save({ ...data });
    nextStep();
  };

  const checkboxGroupValidator = (value: any) => {
    if (!value || value.length === 0) {
      return Promise.reject(new Error('At least 1 option must be selected'));
    }
    return Promise.resolve();
  };

  return (
    <Form
      name='coach-registration-specialisms-form'
      form={form}
      onFinish={onFinish}
      layout='vertical'
      variant='filled'
      requiredMark='optional'
    >
      <Form.Item
        label='I also have specialist qualifications in'
        name='specialistQualifications'
        rules={[
          () => ({
            required: true,
            validator(_, value) {
              return checkboxGroupValidator(value);
            },
          }),
        ]}
      >
        <Checkbox.Group>
          <Flex vertical gap='small'>
            {Object.values(SpecialismQualificationData).map((specialism) => (
              <Checkbox key={specialism.value} value={specialism.value}>
                {specialism.label}
              </Checkbox>
            ))}
            <Checkbox key='other' value='other'>
              Other - please specify
            </Checkbox>
          </Flex>
        </Checkbox.Group>
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.specialistQualifications !== currentValues.specialistQualifications
        }
      >
        {({ getFieldValue }) =>
          getFieldValue('specialistQualifications')?.includes('other') && (
            <Form.Item
              name='otherSpecialism'
              label='Other specialism details'
              rules={[{ required: true, message: 'Please provide details of your other specialist qualification' }]}
            >
              <Input />
            </Form.Item>
          )
        }
      </Form.Item>

      <Form.Item>
        <Flex gap='small' className='md:mt-4'>
          <Button type='primary' block onClick={() => previousStep()}>
            Previous
          </Button>
          <Button type='primary' htmlType='submit' block>
            Next
          </Button>
        </Flex>
      </Form.Item>
    </Form>
  );
};

export default CoachRegistrationSpecialisms;
