import { useDialog } from 'core/providers/dialog-provider';
import { useState } from 'react';
import SharedDialogBase, { ISharedDialogButton } from './dialog-base';
import { Eye, EyeOff } from 'react-feather';
import { App } from 'antd';

interface INewUserPasswordDialog {
  fullName: string;
  emailAddress: string;
  temporaryPassword: string;
}

const NewUserPasswordDialog = ({ fullName, emailAddress, temporaryPassword }: INewUserPasswordDialog) => {
  const [showPassword, setShowPassword] = useState(false);
  const dialog = useDialog();
  const { message } = App.useApp();

  const password = showPassword
    ? temporaryPassword
    : temporaryPassword
        .split('')
        .map(() => '•')
        .join('');

  const copyPassword = () => {
    navigator.clipboard.writeText(temporaryPassword);
    message.success('The generated password was copied to the clipboard.');
  };

  const customContent = () => {
    return (
      <div className='p-4'>
        <p className='mb-4'>
          A new user account has been created for <span className='font-semibold'>{fullName}</span>. The credentials
          below can be used to access their account. They'll be asked to set their own password on first log in.
        </p>

        <p>{`Email address: ${emailAddress}`}</p>
        <div className='flex items-center'>
          <p>{`Password: ${password}`}</p>
          <div
            className='flex items-center justify-center w-10 text-gray-400 hover:text-gray-700 cursor-pointer'
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <EyeOff size={18} /> : <Eye size={18} />}
          </div>
        </div>
      </div>
    );
  };

  const customButtons: ISharedDialogButton[] = [
    {
      key: 'copyPassword',
      props: {
        onClick: () => copyPassword(),
        title: 'Copy password',
      },
    },
    {
      key: 'done',
      props: {
        onClick: () => dialog?.closeDialog(),
        title: 'Done',
        type: 'primary',
      },
    },
  ];

  return (
    <SharedDialogBase title='User created' customContentTemplate={customContent()} customButtons={customButtons} />
  );
};

export default NewUserPasswordDialog;
