import FooterLogo from 'assets/images/footer_logo.png';
import FooterLogoMobile from 'assets/images/footer_logo_mobile.png';

const SharedFooter = () => {
  return (
    <div className='absolute bottom-0 left-0 md:flex w-full items-end'>
      <img src={FooterLogoMobile} alt='CiK footer mobile' className='block md:hidden w-full' />
      <div className='flex-grow flex items-center bg-cik_gray md:h-[50px] py-3 md:py-0 px-4'>
        <p className='quicksand text-[14px] text-center md:text-left'>
          Coaches in Kind is operated by Coaches in Kind C.I.C. (not-for-profit) Registered Company Number: 15369404
        </p>
      </div>
      <img src={FooterLogo} alt='CiK footer' className='hidden md:block' />
    </div>
  );
};

export default SharedFooter;
