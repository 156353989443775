import { Alert } from 'antd';
import { useUserState } from 'core/providers/user-provider';
import { useState } from 'react';
import SharedPageHeader from 'shared/page-header/page-header';
import { IDropdownMenuOnClickInfo } from 'core/helpers/interface.helpers';
import EditProfileDrawer from './edit-profile-drawer';
import SharedCoachProfile from 'shared/coach-profile/coach-profile';
import { useDialog } from 'core/providers/dialog-provider';
import CloseAccountDialog from './close-account-dialog';

const MyProfile = () => {
  const { coachData } = useUserState();
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const dialog = useDialog();

  const headerMenu = {
    items: [
      {
        label: 'Edit profile',
        key: 'editProfile',
      },
      {
        label: 'Close my account',
        key: 'closeMyAccount',
      },
    ],
    onClick: ({ key }: IDropdownMenuOnClickInfo) => {
      if (key === 'editProfile') {
        return setOpenEditDrawer(true);
      } else if (key === 'closeMyAccount') {
        dialog?.openDialog(<CloseAccountDialog />)
      }
    },
  };

  return (
    <>
      <SharedPageHeader title='My profile' menu={headerMenu} />
      <EditProfileDrawer isOpen={openEditDrawer} onClose={() => setOpenEditDrawer(false)} />
      <div className='pt-4 px-4'>
        {!coachData ? (
          <Alert
            message='Profile details have failed to load, please refresh your page to try again'
            type='error'
            showIcon
          />
        ) : (
          <SharedCoachProfile coachData={coachData} />
        )}
      </div>
    </>
  );
};

export default MyProfile;
