import { Outlet } from 'react-router-dom';
import SharedFooter from './footer';
import SharedSidebar from 'shared/sidebar/sidebar';
import { Menu } from 'react-feather';
import { useState } from 'react';

const SharedLayout = () => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  return (
    <div className='flex-grow pb-[50px] flex flex-col md:flex-row'>
      <SharedSidebar mobileOpen={openMobileMenu} setOpenMobileMenu={setOpenMobileMenu} />
      <div className='md:hidden h-[60px] bg-cik_gray flex items-center justify-between px-4'>
        <p className='alegreya text-white text-[20px]'>Coaches in Kind</p>
        <Menu color='white' onClick={() => setOpenMobileMenu(true)} />
      </div>
      <div className='flex-grow md:max-h-trueVH overflow-y-auto pb-[90px]'>
        <Outlet />
      </div>
      <SharedFooter />
    </div>
  );
};

export default SharedLayout;
