export const CoachPlan = {
  GROUND_BREAKER: 'groundBreaker',
  GAME_CHANGER: 'gameChanger',
  GIFT_BEARER: 'giftBearer',
};

export const CoachPlanBenefit = {
  PROFILE: 'Coach profile visible to schools',
  PRO_BONO_MESSAGING: 'Pro bono messaging to schools',
  PERSONALISED_MESSAGING: 'Personalised messaging to schools',
  FUNDED_DELIVERY: 'Eligible for CiK funded delivery',
  RECOMMENDED: "'Recommended' status",
  VIDEO_PROFILE: 'Photo & Video profile',
  RATES: 'Locked-In Rates',
  TESTIMONIALS: 'Testimonials',
  RIBBONS: 'Sponsor ribbons',
};

export const CoachPlanData = {
  [CoachPlan.GROUND_BREAKER]: {
    key: CoachPlan.GROUND_BREAKER,
    title: 'The Ground Breaker',
    price: {
      month: 'Free',
      year: 'Free',
      concatenated: 'Free',
    },
    commitment: '3 x pro bono programmes of 3 or more sessions, annually',
    benefits: [CoachPlanBenefit.PROFILE, CoachPlanBenefit.PRO_BONO_MESSAGING],
  },
  [CoachPlan.GAME_CHANGER]: {
    key: CoachPlan.GAME_CHANGER,
    title: 'The Game Changer',
    price: {
      month: '£12.50/mo',
      year: '£125/yr',
      concatenated: '£12.50/mo OR £125/yr',
    },
    commitment: '2 x pro bono programmes of 3 or more sessions, annually',
    benefits: [
      CoachPlanBenefit.PROFILE,
      CoachPlanBenefit.PERSONALISED_MESSAGING,
      CoachPlanBenefit.FUNDED_DELIVERY,
      CoachPlanBenefit.RECOMMENDED,
      CoachPlanBenefit.VIDEO_PROFILE,
      CoachPlanBenefit.RATES,
      CoachPlanBenefit.TESTIMONIALS,
    ],
  },
  [CoachPlan.GIFT_BEARER]: {
    key: CoachPlan.GIFT_BEARER,
    title: 'The Gift Bearer',
    price: {
      month: '£32.50/mo',
      year: '£325/yr',
      concatenated: '£32.50/mo OR £325/yr',
    },
    commitment: 'No Pro bono coaching required',
    benefits: [
      CoachPlanBenefit.PROFILE,
      CoachPlanBenefit.PERSONALISED_MESSAGING,
      CoachPlanBenefit.FUNDED_DELIVERY,
      CoachPlanBenefit.RECOMMENDED,
      CoachPlanBenefit.VIDEO_PROFILE,
      CoachPlanBenefit.RATES,
      CoachPlanBenefit.TESTIMONIALS,
      CoachPlanBenefit.RIBBONS,
    ],
  },
};

export const CoachPlans = [
  CoachPlanData[CoachPlan.GROUND_BREAKER],
  CoachPlanData[CoachPlan.GAME_CHANGER],
  CoachPlanData[CoachPlan.GIFT_BEARER],
];
