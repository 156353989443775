import { Input, Button, Form, Flex, App } from 'antd';
import { useEffect, useState } from 'react';
import SharedAddressSearch from 'shared/address-search/address-search';
import { IAddressDao } from 'core/api/address/address-api-interface';
import { useUserState } from 'core/providers/user-provider';
import { getActionTimestampFromUser } from 'core/helpers/user-action.helpers';
import SchoolApiService from 'core/api/school/school-api.service';

interface IEditSchoolDetails {
  closeDrawer: () => void;
}

const EditSchoolDetails = ({ closeDrawer }: IEditSchoolDetails) => {
  const { schoolData, userData } = useUserState();
  const [form] = Form.useForm();
  const [address, setAddress] = useState<IAddressDao>();
  const [addressError, setAddressError] = useState<string>();
  const { message } = App.useApp();
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (schoolData) {
      setAddress(schoolData.address);
      form.setFieldsValue(schoolData);
    }
  }, [form, schoolData]);

  const onFinish = async (data: any) => {
    if (!address) {
      setAddressError('You must select a valid address from the results list');
      return;
    }

    try {
      setSubmitting(true);
      await SchoolApiService.update(schoolData?.uid!, {
        schoolName: data.schoolName,
        address,
        updated: getActionTimestampFromUser(userData),
      });
      closeDrawer();
    } catch (error) {
      message.error('There was an error updating your school details, please try again');
      setSubmitting(false);
    }
  };

  return (
    <Form
      name='school-registration-school-details-form'
      form={form}
      onFinish={onFinish}
      layout='vertical'
      variant='filled'
      requiredMark='optional'
      disabled={submitting}
    >
      <Form.Item
        label='School name'
        name='schoolName'
        rules={[{ required: true, message: 'Please enter the name of our school' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label='Address of school' required>
        <SharedAddressSearch onChange={setAddress} value={address} error={addressError} setError={setAddressError} />
      </Form.Item>

      <Form.Item>
        <Flex gap='small' className='md:mt-4'>
          <Button type='primary' htmlType='submit' block loading={submitting}>
            Save
          </Button>
        </Flex>
      </Form.Item>
    </Form>
  );
};

export default EditSchoolDetails;
