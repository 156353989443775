import { FirebaseFunctionName } from 'core/constants/firebase-function-name';
import ApiService from '../api.service';
import {
  ICoachRegistrationDao,
  ICreateCoachRegistrationRequestData,
  ICreateSchoolRegistrationRequestData,
} from './registration-api-interface';
import {
  DocumentData,
  FirestoreError,
  QueryConstraint,
  QueryDocumentSnapshot,
  QuerySnapshot,
  collection,
  query,
} from 'firebase/firestore';
import { firestore } from 'core/config/firebase';
import { CollectionID } from 'core/constants/collection-id';

const collectionRef = collection(firestore, CollectionID.REGISTRATIONS);

const registerCoach = async (payload: ICreateCoachRegistrationRequestData): Promise<string> => {
  return ApiService.callFunction<ICreateCoachRegistrationRequestData, string>(
    payload,
    FirebaseFunctionName.REGISTER_COACH
  );
};

const onSnapshot = (
  resultHandler: (snap: QuerySnapshot<ICoachRegistrationDao, DocumentData>) => void,
  errorHandler: (error: FirestoreError) => void,
  constraints: QueryConstraint[] = []
) => {
  const q = query(collectionRef, ...constraints);
  return ApiService.subscribeToCollection(q.withConverter(converter()), resultHandler, errorHandler);
};

const approveCoach = async (registrationUid: string): Promise<string> => {
  return ApiService.callFunction({ uid: registrationUid }, FirebaseFunctionName.APPROVE_COACH);
};

const rejectCoach = async (uid: string) => {
  return ApiService.callFunction({ uid }, FirebaseFunctionName.REJECT_COACH);
};

const registerSchool = async (payload: ICreateSchoolRegistrationRequestData): Promise<string> => {
  return ApiService.callFunction<ICreateSchoolRegistrationRequestData, string>(
    payload,
    FirebaseFunctionName.REGISTER_SCHOOL
  );
};

const converter = () => ({
  toFirestore: (dao: ICoachRegistrationDao) => dao,
  fromFirestore: (snapshot: QueryDocumentSnapshot): ICoachRegistrationDao => {
    const data = snapshot.data();
    return data as ICoachRegistrationDao;
  },
});

const RegistrationApiService = {
  registerCoach,
  onSnapshot,
  approveCoach,
  rejectCoach,
  registerSchool,
};

export default RegistrationApiService;
