import {
  DocumentData,
  DocumentSnapshot,
  FirestoreError,
  QueryDocumentSnapshot,
  QuerySnapshot,
  UpdateData,
  collection,
  deleteDoc,
  doc,
  getDoc,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { ICoachDao } from './coach-api.interface';
import { firestore } from 'core/config/firebase';
import { CollectionID } from 'core/constants/collection-id';
import ApiService from '../api.service';

const subscribeToDoc = (
  uid: string,
  resultHandler: (snap: DocumentSnapshot<ICoachDao, DocumentData>) => void,
  errorHandler: (error: FirestoreError) => void
) => {
  const docRef = doc(firestore, CollectionID.COACHES, uid).withConverter(converter());
  return ApiService.subscribeToDoc(docRef, resultHandler, errorHandler);
};

const subscribeToInRangeCoaches = (
  resultHandler: (snap: QuerySnapshot<ICoachDao, DocumentData>) => void,
  errorHandler: (error: FirestoreError) => void,
  schoolUid: string
) => {
  const q = query(
    collection(firestore, CollectionID.COACHES),
    where('schoolsInRange', 'array-contains', schoolUid),
    orderBy('fullName', 'desc')
  ).withConverter(converter());
  return ApiService.subscribeToCollection(q, resultHandler, errorHandler);
};

const update = async (uid: string, payload: UpdateData<ICoachDao>) => {
  const docRef = doc(firestore, CollectionID.COACHES, uid).withConverter(converter());
  return updateDoc(docRef, payload);
};

const deleteCoach = async (uid: string) => {
  const docRef = doc(firestore, CollectionID.COACHES, uid).withConverter(converter());
  return deleteDoc(docRef);
};

const get = (uid: string) => {
  const docRef = doc(firestore, CollectionID.COACHES, uid).withConverter(converter());
  return getDoc(docRef);
};

const converter = () => ({
  toFirestore: (dao: ICoachDao) => dao,
  fromFirestore: (snapshot: QueryDocumentSnapshot): ICoachDao => snapshot.data() as ICoachDao,
});

const CoachApiService = {
  subscribeToDoc,
  subscribeToInRangeCoaches,
  update,
  deleteCoach,
  get,
};

export default CoachApiService;
