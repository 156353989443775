import React, { ReactElement } from 'react';
import { useDialog } from 'core/providers/dialog-provider';
import { Button, ButtonProps } from 'antd';

interface ISharedDialogBase {
  title: string;
  subtitle?: string;
  textContent?: string;
  customContentTemplate?: ReactElement;
  showButtons?: boolean;
  customButtons?: ISharedDialogButton[];
}

export interface ISharedDialogButton {
  key: string;
  props: ButtonProps;
}

const SharedDialogBase = ({
  title,
  subtitle,
  textContent,
  customContentTemplate,
  showButtons = true,
  customButtons,
}: ISharedDialogBase) => {
  const dialog = useDialog();
  const defaultButtons: ISharedDialogButton[] = [
    {
      key: 'close',
      props: {
        onClick: () => dialog?.closeDialog(),
        title: 'Close',
      },
    },
  ];
  const buttons = customButtons ?? defaultButtons;
  return (
    <div className='rounded-lg bg-white w-full md:w-[600px] flex flex-col'>
      <div className='border-b p-4'>
        <p className='font-semibold'>{title}</p>
        {subtitle && <p className='mt-1'>{subtitle}</p>}
      </div>
      {textContent && <p className='py-6 px-4'>{textContent}</p>}
      {customContentTemplate && <>{customContentTemplate}</>}
      {showButtons && (
        <div className='border-t p-4 flex justify-end space-x-2'>
          {buttons.map((button) => (
            <Button key={button.key} {...button.props}>
              {button.props.title}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};

export default SharedDialogBase;
