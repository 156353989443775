import {
  DocumentData,
  FirestoreError,
  QueryConstraint,
  QueryDocumentSnapshot,
  QuerySnapshot,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
} from 'firebase/firestore';
import { firestore } from 'core/config/firebase';
import { CollectionID } from 'core/constants/collection-id';
import { IMessageDao } from './messages-api.interface';
import ApiService from '../api.service';
import { FirebaseFunctionName } from 'core/constants/firebase-function-name';

const collectionRef = collection(firestore, CollectionID.MESSAGES);

const onSnapshot = (
  resultHandler: (snap: QuerySnapshot<IMessageDao, DocumentData>) => void,
  errorHandler: (error: FirestoreError) => void,
  constraints: QueryConstraint[] = []
) => {
  const q = query(collectionRef, ...constraints);
  return ApiService.subscribeToCollection(q.withConverter(converter()), resultHandler, errorHandler);
};

const set = (dao: IMessageDao) => {
  const docRef = doc(firestore, CollectionID.MESSAGES, dao.uid);
  return setDoc(docRef, dao);
};

const list = (constraints: QueryConstraint[] = []) => {
  const q = query(collectionRef, ...constraints);
  return getDocs(q.withConverter(converter()));
};

const sendBulkMessage = async (message: string, audience: string) => {
  return ApiService.callFunction({ message, audience }, FirebaseFunctionName.SEND_BULK_MESSAGE);
};

const converter = () => ({
  toFirestore: (dao: IMessageDao) => dao,
  fromFirestore: (snapshot: QueryDocumentSnapshot): IMessageDao => snapshot.data() as IMessageDao,
});

const MessagesApiService = {
  onSnapshot,
  set,
  list,
  sendBulkMessage,
};

export default MessagesApiService;
