import { configureStore } from '@reduxjs/toolkit';
import registrationsReducer, {
  IDomainCoachRegistration,
  IDomainSchoolRegistration,
} from 'modules/admin/admin-registrations-slice';

export type DataStatus = 'loading' | 'error' | 'success';

export interface IListDataSlice<T> {
  data: T[];
  status: DataStatus;
}

export interface StoreState {
  registrations: IListDataSlice<IDomainCoachRegistration | IDomainSchoolRegistration>;
}

export default configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    registrations: registrationsReducer,
  },
});
