import { App, Select, Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import { ICoachDao } from 'core/api/coach/coach-api.interface';
import CoachApiService from 'core/api/coach/coach-api.service';
import { CoachAudienceData } from 'core/constants/coach-audience';
import { CoachLocationData } from 'core/constants/coach-location';
import { CoachingTypeData } from 'core/constants/coach-type';
import { GroupSizeData } from 'core/constants/group-size';
import { SpecialismQualificationData } from 'core/constants/specialism-qualification';
import { useUserState } from 'core/providers/user-provider';
import { QuerySnapshot, Unsubscribe } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { ParamKeyValuePair, useSearchParams } from 'react-router-dom';
import SharedPageHeader from 'shared/page-header/page-header';
import CoachSearchTableRow from './coach-search-table-row';

interface ICoachSearchFilters {
  [key: string]: undefined | string[];
  coachingTypes?: string[];
  coachingAudience?: string[];
  coachingGroupSize?: string[];
  coachingLocation?: string[];
  specialistQualifications?: string[];
}

const CoachSearch = () => {
  const { schoolData } = useUserState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterValues, setFilterValues] = useState<ICoachSearchFilters>({});
  const { message } = App.useApp();
  const [coaches, setCoaches] = useState<ICoachDao[]>();
  const [filteredCoaches, setFilteredCoaches] = useState<ICoachDao[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setFilterValues({});
    searchParams.forEach((value, key) => {
      setFilterValues((prevState) => {
        return { ...prevState, [key]: value.split(',') };
      });
    });
  }, [searchParams]);

  const handleSubscriptionError = useCallback(
    (error: any) => {
      message.error('Coach search failed, please try again');
    },
    [message]
  );

  const createSubscription = useCallback(() => {
    const handleSnapshot = (querySnapshot: QuerySnapshot<ICoachDao>) => {
      const list: ICoachDao[] = [];
      querySnapshot.forEach((snap) => {
        list.push(snap.data());
      });
      setCoaches(list);
      setLoading(false);
    };
    return CoachApiService.subscribeToInRangeCoaches(handleSnapshot, handleSubscriptionError, schoolData?.uid ?? '');
  }, [handleSubscriptionError, schoolData?.uid]);

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    unsubscribe = createSubscription();
    return () => {
      unsubscribe();
    };
  }, [createSubscription]);

  useEffect(() => {
    if (coaches) {
      const { coachingAudience, coachingGroupSize, coachingLocation, coachingTypes, specialistQualifications } =
        filterValues;
      const filtered = coaches.filter((coach) => {
        const {
          coachingAudience: ca,
          coachingGroupSize: cg,
          coachingLocation: cl,
          coachingTypes: ct,
          specialistQualifications: sq,
        } = coach;

        const checks = [
          {
            inputs: coachingAudience,
            values: ca,
          },
          {
            inputs: coachingGroupSize,
            values: cg,
          },
          {
            inputs: coachingLocation,
            values: cl,
          },
          {
            inputs: coachingTypes,
            values: ct,
          },
          {
            inputs: specialistQualifications,
            values: sq,
          },
        ];

        return checks.every(
          (check) => check.inputs === undefined || check.inputs.every((input) => check.values.includes(input))
        );
      });

      setFilteredCoaches(filtered);
    }
  }, [coaches, filterValues]);

  const checkboxSections = [
    {
      label: 'Coaching methods',
      name: 'coachingTypes',
      gap: 'small',
      options: Object.values(CoachingTypeData).map((option) => ({ value: option.value, label: option.altLabel })),
    },
    {
      label: 'Age groups',
      name: 'coachingAudience',
      gap: 'small',
      options: Object.values(CoachAudienceData),
    },
    {
      label: 'Group sizes',
      name: 'coachingGroupSize',
      gap: 'small',
      options: Object.values(GroupSizeData),
    },
    {
      label: 'Location of coaching',
      name: 'coachingLocation',
      gap: 'small',
      options: Object.values(CoachLocationData),
    },
    {
      label: 'Specialisms',
      name: 'specialistQualifications',
      gap: 'small',
      options: [...Object.values(SpecialismQualificationData), { label: 'Other', value: 'other' }],
    },
  ];

  const handleFilterChange = (key: string, checkedValues: string[]) => {
    if (!checkedValues || checkedValues.length === 0) {
      searchParams.delete(key);
      setSearchParams(searchParams, { replace: true });
    } else {
      const newValues: ParamKeyValuePair[] = [];
      searchParams.forEach((currentVal, currentKey) => {
        if (currentKey !== key) {
          newValues.push([currentKey, currentVal]);
        }
      });
      newValues.push([key, checkedValues.join(',')]);
      setSearchParams(newValues);
    }
  };

  const columns: ColumnType<ICoachDao>[] = [
    {
      key: 'coach',
      title: 'Matching coaches in your area',
      render: (_, record) => <CoachSearchTableRow coach={record} />,
    },
  ];

  return (
    <>
      <SharedPageHeader title='Find a coach' />
      <div className='px-4 pt-4'>
        <div className='shadow-sm bg-white rounded-md mb-4'>
          <div className='px-4 py-2 border-b bg-gray-50'>
            <p className='font-semibold text-[16px]'>Filters</p>
          </div>
          <div className='p-4 grid grid-cols-1 md:grid-cols-5 gap-4'>
            {checkboxSections.map((section) => (
              <div key={section.name}>
                <p className='font-semibold mb-2'>{section.label}</p>
                <Select
                  mode='multiple'
                  allowClear
                  style={{ width: '100%' }}
                  placeholder='Nothing selected'
                  onChange={(values) => handleFilterChange(section.name, values)}
                  options={section.options}
                  value={filterValues[section.name]}
                />
              </div>
            ))}
          </div>
        </div>
        <Table
          loading={loading}
          columns={columns}
          dataSource={filteredCoaches}
          pagination={false}
          scroll={{ x: '300px' }}
        />
      </div>
    </>
  );
};

export default CoachSearch;
