import { useSelector } from 'react-redux';
import SharedPageHeader from 'shared/page-header/page-header';
import { RegistrationsSlice } from '../admin-registrations-slice';
import { ChevronRight } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';
import { StoreState } from 'store';
import SharedPaginatedTable from 'shared/table/paginated-table';
import { collection, orderBy } from 'firebase/firestore';
import { firestore } from 'core/config/firebase';
import { CollectionID } from 'core/constants/collection-id';
import { ColumnType } from 'antd/es/table';
import { ICoachDao } from 'core/api/coach/coach-api.interface';
import { IActionTimestamp } from 'core/api/api.interface';
import dayjs from 'dayjs';
import { App, Button, Popconfirm } from 'antd';
import CoachApiService from 'core/api/coach/coach-api.service';
import { useTable } from 'core/providers/table-data-provider';
import { CoachPlanData } from 'core/constants/coach-plan';
import { useState } from 'react';
import AdminCoachDetail from './admin-coach-detail';
import { useUserState } from 'core/providers/user-provider';
import ConversationApiService from 'core/api/conversation/conversation-api.service';
import { AccountType } from 'core/constants/account-type';
import { getActionTimestampFromUser } from 'core/helpers/user-action.helpers';
import { v4 as uuid } from 'uuid';

const AdminCoachesList = () => {
  const { coachRegistrations, status } = useSelector((state: StoreState) =>
    RegistrationsSlice.selectRegistrationsByType(state, 'coach')
  );
  const table = useTable('adminCoaches');
  const { message } = App.useApp();
  const [viewCoachDetails, setViewCoachDetails] = useState<ICoachDao>();
  const [showViewDrawer, setShowViewDrawer] = useState(false);
  const { userData } = useUserState();
  const navigate = useNavigate();
  const [messageButtonLoading, setMessageButtonLoading] = useState(false);

  const handleDelete = async (uid: string) => {
    try {
      await CoachApiService.deleteCoach(uid);
      table.refreshTable();
    } catch (err) {
      message.error('This coach could not be deleted, please check your permissions and try again');
    }
  };

  const messageCoach = async (record: ICoachDao) => {
    if (!userData) {
      return;
    }
    setMessageButtonLoading(true);
    try {
      const snap = await ConversationApiService.getConversationBetweenParticipantAndAdmin(
        record.uid,
        userData.uid,
        AccountType.COACH
      );
      if (snap.empty) {
        const actor = getActionTimestampFromUser(userData);
        const uid = uuid();
        await ConversationApiService.set({
          admin: userData,
          coach: record,
          uid,
          created: actor,
          updated: actor,
          messageCount: 0,
          participants: [
            { uid: userData.uid, accountType: AccountType.ADMIN },
            { uid: record.uid, accountType: AccountType.COACH },
          ],
        });
        navigate(`/admin/messages/conversation/${uid}`);
      } else {
        navigate(`/admin/messages/conversation/${snap.docs[0].id}`);
      }
      setMessageButtonLoading(false);
    } catch (error) {
      setMessageButtonLoading(false);
      message.error('Unable to go to conversation, please try again');
    }
  };

  const columns: ColumnType<ICoachDao>[] = [
    {
      title: 'Name',
      dataIndex: 'fullName',
      key: 'fullName',
      width: 250,
    },
    {
      title: 'Email',
      dataIndex: 'emailAddress',
      key: 'emailAddress',
    },
    {
      title: 'Plan',
      dataIndex: 'plan',
      key: 'plan',
      render: (plan: string) => CoachPlanData[plan]?.title ?? 'Unknown',
    },
    {
      title: 'Last updated',
      dataIndex: 'updated',
      key: 'lastUpdated',
      render: (updated: IActionTimestamp) => (
        <p>{`${dayjs(updated.at.toDate()).format('DD/MM/YYYY HH:mm')} by ${updated.by.fullName}`}</p>
      ),
    },
    {
      key: 'actions',
      render: (_, record) => (
        <div className='space-x-2'>
          <Button
            onClick={() => {
              setShowViewDrawer(true);
              setViewCoachDetails(record);
            }}
          >
            View
          </Button>
          <Button
            loading={messageButtonLoading}
            onClick={() => {
              messageCoach(record);
            }}
          >
            Message
          </Button>
          <Popconfirm
            icon={<></>}
            okButtonProps={{ danger: true }}
            placement='left'
            title='The coach will be immediately removed from the CiK hub, this action is irreversible, are you sure you want to continue?'
            onConfirm={() => handleDelete(record.uid)}
          >
            <Button danger>Delete</Button>
          </Popconfirm>
        </div>
      ),
      width: '270px',
    },
  ];

  return (
    <>
      <SharedPageHeader title='Coaches' />
      {showViewDrawer && (
        <AdminCoachDetail
          details={viewCoachDetails}
          isOpen={viewCoachDetails !== undefined}
          onClose={() => {
            setViewCoachDetails(undefined);
            setTimeout(() => {
              setShowViewDrawer(false);
            }, 250);
          }}
        />
      )}

      <div className='p-4'>
        {coachRegistrations.length > 0 && status === 'success' && (
          <Link to='registrations'>
            <div className='p-4 bg-white rounded-md shadow-sm flex justify-between items-center cursor-pointer hover:shadow-md transition ease-in-out'>
              <div className='flex space-x-2'>
                <p className='w-[22px] h-[22px] flex items-center justify-center rounded-full text-white bg-red-500'>
                  {coachRegistrations.length}
                </p>
                <p>Coach registrations outstanding</p>
              </div>
              <ChevronRight className='text-gray-600' />
            </div>
          </Link>
        )}

        <div className='mt-4'>
          <SharedPaginatedTable
            tableKey='adminCoaches'
            collectionRef={collection(firestore, CollectionID.COACHES)}
            queryConstraints={[]}
            queryOrder={orderBy('updated.at', 'desc')}
            errorMessage='The coaches list could not be retrieved, please check your permissions and try again.'
            columns={columns}
            minWidth={1000}
          />
        </div>
      </div>
    </>
  );
};

export default AdminCoachesList;
