import ApiService from 'core/api/api.service';
import { ICreateUserRequestData, IUpdateUserPayload, IUserDao } from './user-api.interface';
import {
  DocumentData,
  DocumentSnapshot,
  FirestoreError,
  QueryDocumentSnapshot,
  deleteDoc,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { firestore } from 'core/config/firebase';
import { CollectionID } from 'core/constants/collection-id';
import { FirebaseFunctionName } from 'core/constants/firebase-function-name';

const subscribeToUser = (
  uid: string,
  resultHandler: (snap: DocumentSnapshot<IUserDao, DocumentData>) => void,
  errorHandler: (error: FirestoreError) => void
) => {
  const docRef = doc(firestore, CollectionID.USERS, uid).withConverter(converter());
  return ApiService.subscribeToDoc(docRef, resultHandler, errorHandler);
};

const createAdmin = async (payload: ICreateUserRequestData): Promise<string> => {
  return ApiService.callFunction<ICreateUserRequestData, string>(payload, FirebaseFunctionName.CREATE_ADMIN);
};

const deleteUser = async (uid: string) => {
  const docRef = doc(firestore, CollectionID.USERS, uid).withConverter(converter());
  return deleteDoc(docRef);
};

const update = async (uid: string, payload: IUpdateUserPayload) => {
  const docRef = doc(firestore, CollectionID.USERS, uid).withConverter(converter());
  return updateDoc(docRef, payload);
};

const resetUserPassword = (uid: string) => {
  return ApiService.callFunction<{ uid: string }, string>({ uid }, FirebaseFunctionName.RESET_USER_PASSWORD);
};

const converter = () => ({
  toFirestore: (dao: IUserDao) => dao,
  fromFirestore: (snapshot: QueryDocumentSnapshot): IUserDao => snapshot.data() as IUserDao,
});

const UsersApiService = {
  subscribeToUser,
  createAdmin,
  deleteUser,
  update,
  resetUserPassword,
};

export default UsersApiService;
