export const CoachAudience = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  YOUNG_ADULTS: 'youngAdults',
  PROFESSIONALS: 'professionals',
};

export const CoachAudienceData = {
  [CoachAudience.PRIMARY]: {
    value: CoachAudience.PRIMARY,
    label: 'Primary-school aged children',
  },
  [CoachAudience.SECONDARY]: {
    value: CoachAudience.SECONDARY,
    label: 'Secondary-school aged children',
  },
  [CoachAudience.YOUNG_ADULTS]: {
    value: CoachAudience.YOUNG_ADULTS,
    label: 'Young adults 18-24',
  },
  [CoachAudience.PROFESSIONALS]: {
    value: CoachAudience.PROFESSIONALS,
    label: 'Educational professionals',
  },
};
