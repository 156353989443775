import { App, Button, Form, Input, InputNumber } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { IAddressDao } from 'core/api/address/address-api-interface';
import CoachApiService from 'core/api/coach/coach-api.service';
import { getActionTimestampFromUser } from 'core/helpers/user-action.helpers';
import { useUserState } from 'core/providers/user-provider';
import { useEffect, useState } from 'react';
import SharedAddressSearch from 'shared/address-search/address-search';

interface IEditPersonalDetails {
  closeDrawer: () => void;
}

const EditPersonalDetails = ({ closeDrawer }: IEditPersonalDetails) => {
  const [form] = useForm();
  const [address, setAddress] = useState<IAddressDao>();
  const [addressError, setAddressError] = useState<string>();
  const { userData, coachData } = useUserState();
  const { message } = App.useApp();
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (coachData) {
      const { address, ...rest } = coachData;
      form.setFieldsValue(rest);
      setAddress(coachData.address);
    }
  }, [coachData, form]);

  const onFinish = async (data: any) => {
    if (!address) {
      setAddressError('You must select a valid address from the results list');
      return;
    }

    try {
      setSubmitting(true);
      await CoachApiService.update(coachData?.uid!, {
        fullName: data.fullName,
        address,
        maxTravel: data.maxTravel,
        updated: getActionTimestampFromUser(userData),
      });
      closeDrawer();
    } catch (error) {
      message.error('There was an error updating your personal details, please try again');
      setSubmitting(false);
    }
  };

  return (
    <Form
      name='edit-personal-details-form'
      form={form}
      onFinish={onFinish}
      layout='vertical'
      variant='filled'
      requiredMark='optional'
      disabled={submitting}
    >
      <Form.Item label='Full name' name='fullName' rules={[{ required: true, message: 'Please enter your name' }]}>
        <Input />
      </Form.Item>

      <Form.Item label='Address' required>
        <SharedAddressSearch onChange={setAddress} value={address} error={addressError} setError={setAddressError} />
      </Form.Item>

      <Form.Item
        label='Maximum travel distance from your postcode'
        name='maxTravel'
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <InputNumber style={{ width: '100%' }} suffix='miles' />
      </Form.Item>

      <Form.Item>
        <Button type='primary' htmlType='submit' block loading={submitting}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditPersonalDetails;
