import { App } from 'antd';
import { IConversationDao } from 'core/api/conversation/conversation-api.interface';
import ConversationApiService from 'core/api/conversation/conversation-api.service';
import { IMessageDao } from 'core/api/messages/messages-api.interface';
import MessagesApiService from 'core/api/messages/messages-api.service';
import { AccountType } from 'core/constants/account-type';
import { useUserState } from 'core/providers/user-provider';
import { Unsubscribe } from 'firebase/auth';
import {
  DocumentSnapshot,
  QueryConstraint,
  QuerySnapshot,
  limit,
  orderBy,
  startAfter,
  where,
} from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SharedConversationContainer from 'shared/messaging/conversation-container';
import SharedPageHeader from 'shared/page-header/page-header';
import SharedSpinner from 'shared/spinner/spinner';

const Conversation = () => {
  const { uid } = useParams();
  const { message } = App.useApp();
  const [conversation, setConversation] = useState<IConversationDao>();
  const [messages, setMessages] = useState<IMessageDao[]>([]);
  const { userData } = useUserState();
  const [baseConstraints, setBaseConstraints] = useState<QueryConstraint[]>();
  const [firstMessage, setFirstMessage] = useState<DocumentSnapshot>();

  const getConversation = useCallback(
    async (uid: string) => {
      try {
        const snap = await ConversationApiService.get(uid);
        if (snap.exists()) {
          setConversation(snap.data());
        } else {
          message.error('No conversation found, please try again');
        }
      } catch (error) {
        message.error('Failed to get conversation data, please try again');
      }
    },
    [message]
  );

  useEffect(() => {
    if (!uid) {
      message.error('No conversation found, please try again');
    } else {
      getConversation(uid);
    }
  }, [getConversation, message, uid]);

  const subscribeToMessages = useCallback(
    (conversation: IConversationDao) => {
      const constraints: QueryConstraint[] = [
        where('conversationUid', '==', conversation.uid),
        orderBy('created.at', 'desc'),
      ];
      if (userData?.accountType !== AccountType.ADMIN && userData?.attachedResourceUid) {
        constraints.push(where('participants', 'array-contains', userData.attachedResourceUid));
      }

      setBaseConstraints(constraints);

      let firstMessage: DocumentSnapshot;
      const handleSnapshot = async (querySnapshot: QuerySnapshot<IMessageDao>) => {
        querySnapshot.docChanges().forEach((change, index) => {
          if (change.type === 'added') {
            if (!firstMessage && index === querySnapshot.docs.length - 1) {
              firstMessage = change.doc;
              setFirstMessage(change.doc);
            }
            setMessages((prevState) => [...prevState, change.doc.data()]);
          }
        });
      };
      return MessagesApiService.onSnapshot(
        handleSnapshot,
        (error: any) => {
          message.error('Fetching conversation messages failed, please try again');
        },
        [...constraints, limit(20)]
      );
    },
    [message, userData?.accountType, userData?.attachedResourceUid]
  );

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (conversation) {
      unsubscribe = subscribeToMessages(conversation);
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [conversation, subscribeToMessages]);

  const loadMoreMessages = async (constraints: QueryConstraint[], startMessage: DocumentSnapshot) => {
    try {
      const snap = await MessagesApiService.list([...constraints, startAfter(startMessage), limit(20)]);
      setFirstMessage(snap.docs[snap.size - 1]);
      setMessages((prevState) => [...prevState, ...snap.docs.map((doc) => doc.data())]);
    } catch (error) {
      message.error('Failed to fetch more messages, please try again');
    }
  };

  return (
    <div className='flex flex-col h-full'>
      <SharedPageHeader title='Conversation' showBack />
      {!conversation || !baseConstraints ? (
        <div className='h-[260px] w-full flex items-center justify-center'>
          <SharedSpinner color='#FF875B' size={24} />
        </div>
      ) : (
        <SharedConversationContainer
          conversation={conversation}
          messages={messages}
          constraints={baseConstraints}
          firstMessage={firstMessage}
          loadMoreMessages={loadMoreMessages}
        />
      )}
    </div>
  );
};

export default Conversation;
