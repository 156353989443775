import StorageApiService from 'core/api/storage/storage-api.service';
import { useState, useCallback, useEffect } from 'react';
import { AlertCircle, User } from 'react-feather';
import SharedSpinner from 'shared/spinner/spinner';
import { useNavigate } from 'react-router-dom';
import { ISchoolDao } from 'core/api/school/school-api.interface';

interface ISchoolSearchTableRow {
  school: ISchoolDao;
}

const SchoolSearchTableRow = ({ school }: ISchoolSearchTableRow) => {
  const [avatar, setAvatar] = useState<string>();
  const [avatarState, setAvatarState] = useState('loading');
  const navigate = useNavigate();

  const getAvatar = useCallback(async (path: string) => {
    try {
      const downloadUrl = await StorageApiService.getFileDownloadUrl(path);
      setAvatar(downloadUrl);
      setAvatarState('success');
    } catch (error) {
      setAvatarState('error');
    }
  }, []);

  useEffect(() => {
    if (school.profilePhotoPath) {
      getAvatar(school.profilePhotoPath);
    } else {
      setAvatarState('unspecified');
    }
  }, [getAvatar, school.profilePhotoPath]);

  return (
    <div className='flex justify-between items-center cursor-pointer' onClick={() => navigate(school.uid)}>
      <div className='flex items-center'>
        <div className='h-[70px] w-[70px] bg-gray-200 rounded-full mr-6 relative flex justify-center items-center overflow-hidden'>
          {avatarState === 'loading' && <SharedSpinner color='#FF875B' size={24} />}
          {avatarState === 'error' && <AlertCircle color='red' size={24} />}
          {avatarState === 'unspecified' && <User size={32} className='text-gray-600' />}
          {avatarState === 'success' && <img src={avatar} alt='avatar' className='w-full' />}
        </div>
        <p className='text-[16px] mr-6'>{school.schoolName}</p>
      </div>
    </div>
  );
};

export default SchoolSearchTableRow;
