export const CoachLocation = {
  SCHOOL: 'school',
  BUSINESS: 'business',
  ONLINE: 'online',
};

export const CoachLocationData = {
  [CoachLocation.SCHOOL]: {
    value: CoachLocation.SCHOOL,
    label: 'At a school',
    schoolLabel: 'At my school',
  },
  [CoachLocation.BUSINESS]: {
    value: CoachLocation.BUSINESS,
    label: 'At my business premises',
    schoolLabel: 'At your business premises',
  },
  [CoachLocation.ONLINE]: {
    value: CoachLocation.ONLINE,
    label: 'Online',
    schoolLabel: 'Online',
  },
};
