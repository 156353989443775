import { App, Button, Form, Space, Upload, UploadProps } from 'antd';
import { useForm } from 'antd/es/form/Form';
import CoachApiService from 'core/api/coach/coach-api.service';
import StorageApiService from 'core/api/storage/storage-api.service';
import { FileType } from 'core/helpers/interface.helpers';
import { getActionTimestampFromUser } from 'core/helpers/user-action.helpers';
import { useUserState } from 'core/providers/user-provider';
import { deleteField } from 'firebase/firestore';
import { useEffect, useState } from 'react';

interface IEditProfileVideo {
  closeDrawer: () => void;
}

const EditProfileVideo = ({ closeDrawer }: IEditProfileVideo) => {
  const { userData, coachData } = useUserState();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const { message } = App.useApp();
  const [fileList, setFileList] = useState<FileType[]>([]);
  const [removing, setRemoving] = useState(false);
  const [videoRemovable, setVideoRemovable] = useState(false);
  const [form] = useForm();

  useEffect(() => {
    if (coachData?.profileVideoPath) {
      setVideoRemovable(true);
    }
  }, [coachData?.profileVideoPath]);

  const onFinish = async (data: any) => {
    if (fileList.length > 0) {
      try {
        setFormSubmitting(true);
        const video = fileList[0];
        const videoPath = `coach-profile/${coachData?.uid}/${video.name}`;
        await StorageApiService.uploadFile(videoPath, video, video.type ?? 'video/mp4');
        await CoachApiService.update(coachData?.uid!, {
          profileVideoPath: videoPath,
          updated: getActionTimestampFromUser(userData),
        });
        closeDrawer();
      } catch (err) {
        message.info('Failed to upload new video, please try again');
      }
    } else {
      message.info('Please upload a new video to save');
    }
  };

  const videoProps: UploadProps = {
    beforeUpload: (file: FileType) => {
      if (file.type !== 'video/mp4') {
        message.info("We're sorry, but only mp4 is supported");
        return;
      }

      if (!file.size || file.size > 10000000) {
        message.info("We're sorry, but files must be under 10MB");
        return;
      }
      setFileList([file]);
      return false;
    },
    maxCount: 1,
    fileList: fileList,
  };

  const removeExistingVideo = async () => {
    setRemoving(true);
    try {
      await CoachApiService.update(coachData?.uid!, {
        profileVideoPath: deleteField(),
        updated: getActionTimestampFromUser(userData),
      });
      closeDrawer();
    } catch (err) {
      message.error('The existing video could not be removed, please try again');
    }
  };

  return (
    <Form
      name='complete-profile-form'
      form={form}
      onFinish={onFinish}
      layout='vertical'
      variant='filled'
      requiredMark={false}
      disabled={formSubmitting}
    >
      <Form.Item label='Profile video' name='profileVideo'>
        <Upload {...videoProps}>
          <Button>Click to Upload</Button>
        </Upload>
      </Form.Item>

      <Form.Item className='flex justify-end'>
        <Space className='md:mt-4'>
          {videoRemovable && (
            <Button onClick={removeExistingVideo} loading={removing} danger>
              Remove existing video
            </Button>
          )}
          <Button type='primary' htmlType='submit' loading={formSubmitting}>
            Save
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default EditProfileVideo;
