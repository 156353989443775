import { ReactElement, useCallback, useEffect, useState } from 'react';
import { App, Button, Drawer } from 'antd';
import { CoachingTypeData } from 'core/constants/coach-type';
import { StringIndexedAnyValue } from 'core/helpers/interface.helpers';
import { CoachAudienceData } from 'core/constants/coach-audience';
import { GroupSizeData } from 'core/constants/group-size';
import { CoachLocationData } from 'core/constants/coach-location';
import { ISchoolDao } from 'core/api/school/school-api.interface';
import StorageApiService from 'core/api/storage/storage-api.service';
import { AlertCircle, User } from 'react-feather';
import SharedSpinner from 'shared/spinner/spinner';
import UsersApiService from 'core/api/user/user-api.service';
import { useDialog } from 'core/providers/dialog-provider';
import UserPasswordResetDialog from 'shared/dialog/user-password-reset-dialog';

interface IAdminSchoolDetail {
  details?: ISchoolDao;
  isOpen: boolean;
  onClose: () => void;
}

interface IAdminSchoolDetailSection {
  key: string;
  title: string;
  value: string | undefined | ReactElement;
}

const AdminSchoolDetail = ({ details, isOpen, onClose }: IAdminSchoolDetail) => {
  const { message } = App.useApp();
  const [profilePhotoUrl, setProfilePhotoUrl] = useState<string>();
  const [profilePhotoState, setProfilePhotoState] = useState('loading');
  const [resettingPassword, setResettingPassword] = useState(false);
  const dialog = useDialog();

  const getProfilePhoto = useCallback(
    async (path: string) => {
      try {
        const downloadUrl = await StorageApiService.getFileDownloadUrl(path);
        setProfilePhotoUrl(downloadUrl);
        setProfilePhotoState('success');
      } catch (error) {
        setProfilePhotoState('error');
        message.error('Profile photo failed to load');
      }
    },
    [message]
  );

  useEffect(() => {
    if (details?.profilePhotoPath) {
      getProfilePhoto(details.profilePhotoPath);
    }
  }, [details?.profilePhotoPath, getProfilePhoto]);

  const checkboxContentType = <T extends StringIndexedAnyValue>(checks: string[], dataObject: T) => (
    <>
      {checks.map((check) => (
        <p key={dataObject[check].value}>{dataObject[check].label ?? 'Unknown'}</p>
      ))}
    </>
  );

  const getSectionContent = ({
    schoolName,
    mainStaffContact,
    emailAddress,
    address,
    coachingAudience,
    coachingGroupSize,
    coachingLocation,
    coachingTypes,
  }: ISchoolDao) => {
    const sections: IAdminSchoolDetailSection[] = [
      {
        key: 'schoolName',
        title: 'School name',
        value: schoolName,
      },
      {
        key: 'mainStaffContact',
        title: 'Main staff contact',
        value: mainStaffContact,
      },
      {
        key: 'emailAddress',
        title: 'Email address',
        value: emailAddress,
      },

      {
        key: 'address',
        title: 'Address',
        value: address.formattedAddress,
      },
      {
        key: 'coachingTypes',
        title: 'Which methods of coaching are you interested in?',
        value: checkboxContentType(coachingTypes, CoachingTypeData),
      },
      {
        key: 'coachingAudience',
        title: 'Age group',
        value: checkboxContentType(coachingAudience, CoachAudienceData),
      },
      {
        key: 'coachingGroupSize',
        title: 'Group size',
        value: checkboxContentType(coachingGroupSize, GroupSizeData),
      },
      {
        key: 'coachingLocation',
        title: 'Location of coaching',
        value: checkboxContentType(coachingLocation, CoachLocationData),
      },
    ];

    if (profilePhotoUrl) {
      sections.splice(0, 0, {
        key: 'profilePhoto',
        title: 'Profile photo',
        value: (
          <div className='h-[140px] w-[140px] border-4 border-white bg-gray-200 rounded-full mr-3 relative flex justify-center items-center overflow-hidden'>
            {profilePhotoState === 'loading' && <SharedSpinner color='#FF875B' size={32} />}
            {profilePhotoState === 'error' && <AlertCircle color='red' size={32} />}
            {profilePhotoState === 'unspecified' && <User size={48} className='text-gray-600' />}
            {profilePhotoState === 'success' && <img src={profilePhotoUrl} alt='avatar' className='w-full' />}
          </div>
        ),
      });
    }

    return sections.map((section) => (
      <div className='mt-6 first:mt-0' key={section.key}>
        <p className='text-[15px] font-semibold'>{section.title}</p>
        <div>{section.value}</div>
      </div>
    ));
  };

  const resetPassword = async () => {
    try {
      if (!details) {
        return;
      }
      setResettingPassword(true);
      const newPassword = await UsersApiService.resetUserPassword(details.userUid);
      dialog?.openDialog(<UserPasswordResetDialog temporaryPassword={newPassword} fullName={details.schoolName} />);
      setResettingPassword(false);
      onClose();
    } catch (error) {
      message.error('Could not reset the password for this coach, please try again.');
      setResettingPassword(false);
    }
  };

  const drawerActions = (
    <Button loading={resettingPassword} onClick={resetPassword}>
      Reset password
    </Button>
  );

  return (
    <Drawer
      placement='right'
      size='large'
      onClose={() => {
        setProfilePhotoUrl(undefined);
        setProfilePhotoState('loading');
        onClose();
      }}
      open={isOpen}
      extra={drawerActions}
    >
      {details ? <>{getSectionContent(details)}</> : <>Error</>}
    </Drawer>
  );
};

export default AdminSchoolDetail;
