import { App, Button, Checkbox } from 'antd';
import { useState } from 'react';
import { ISchoolRegistrationData } from './school-registration';
import RegistrationApiService from 'core/api/registration/registration-api.service';

interface ICoachRegistrationDisclaimer {
  existingDetails?: ISchoolRegistrationData;
  onRegistrationComplete: () => void;
}

const SchoolRegistrationDisclaimers = ({ existingDetails, onRegistrationComplete }: ICoachRegistrationDisclaimer) => {
  const [checked, setChecked] = useState(false);
  const { message } = App.useApp();
  const [submitting, setSubmitting] = useState(false);

  const submitRegistration = async () => {
    try {
      setSubmitting(true);

      if (!existingDetails) {
        throw new Error('No registration details');
      }

      const requiredDetails = [
        'schoolName',
        'emailAddress',
        'mainStaffContact',
        'address',
        'coachingTypes',
        'coachingAudience',
        'coachingGroupSize',
        'coachingLocation',
      ];

      if (requiredDetails.some((requirement) => existingDetails[requirement] === undefined)) {
        throw new Error('Registration details are incomplete');
      }

      const { profilePhoto, ...rest } = existingDetails;

      await RegistrationApiService.registerSchool({
        ...rest,
        ...(profilePhoto && {
          profilePhoto: {
            dataUrl: profilePhoto.url,
            contentType: profilePhoto.file.type,
            name: profilePhoto.file.name,
          },
        }),
      });
      onRegistrationComplete();
    } catch (error) {
      message.error('Registration could not be completed due to an error, please check all your inputs and try again');
      setSubmitting(false);
    }
  };

  return (
    <>
      <p className='mb-4'>
        By registering as a school on the Coaches in Kind Hub, you agree to our{' '}
        <span className='hover:text-blue-500'>
          <a href='https://www.coachesinkind.com/terms-and-conditions' target='_blank' rel='noopener noreferrer'>
            terms & conditions
          </a>
        </span>
      </p>
      <p className='mb-4'>You also agree the school will ensure they see the following prior to any delivery:</p>
      <div className='text-[15px]'>
        <p>
          <b>S</b>afeguarding Level 1 or above
        </p>
        <p>
          <b>U</b>p-to-date Enhanced DBS
        </p>
        <p>
          <b>P</b>rofessional qualification certificates
        </p>
        <p>
          <b>P</b>ublic Liability and Professional Indemnity insurance
        </p>
        <p>
          <b>O</b>riginal photographic ID
        </p>
        <p>
          <b>R</b>eferee details
        </p>
        <p>
          <b>T</b>erms that clearly state methods used
        </p>
      </div>
      <p className='mt-4'>
        I agree to the CiK Hub{' '}
        <span className='text-blue-500 hover:underline'>
          <a href='https://www.coachesinkind.com/terms-and-conditions' target='_blank' rel='noopener noreferrer'>Terms & Conditions</a>
        </span>{' '}
        and{' '}
        <span className='text-blue-500 hover:underline'>
          <a href='https://www.coachesinkind.com/privacy-policy' target='_blank' rel='noopener noreferrer'>Privacy Policy</a>
        </span>
      </p>
      <Checkbox className='my-4' checked={checked} onChange={(e) => setChecked(e.target.checked)}>
        I agree
      </Checkbox>
      <Button disabled={!checked || submitting} loading={submitting} type='primary' block onClick={submitRegistration}>
        Submit registration
      </Button>
    </>
  );
};

export default SchoolRegistrationDisclaimers;
