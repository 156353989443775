import { App, Button, Checkbox } from 'antd';
import { ICoachRegistrationData } from './coach-registration';
import { useState } from 'react';
import RegistrationApiService from 'core/api/registration/registration-api.service';
import { IRegistrationUpload } from 'core/api/registration/registration-api-interface';

interface ICoachRegistrationDisclaimer {
  existingDetails?: ICoachRegistrationData;
  onRegistrationComplete: () => void;
}

const CoachRegistrationDisclaimers = ({ existingDetails, onRegistrationComplete }: ICoachRegistrationDisclaimer) => {
  const [checked, setChecked] = useState(false);
  const { message } = App.useApp();
  const [submitting, setSubmitting] = useState(false);

  const submitRegistration = async () => {
    try {
      setSubmitting(true);

      if (!existingDetails) {
        throw new Error('No registration details');
      }

      const requiredDetails = [
        'qualified',
        'fullName',
        'emailAddress',
        'phoneNumber',
        'address',
        'maxTravel',
        'plan',
        'hasQualifications',
        'uploads',
        'coachingTypes',
        'coachingAudience',
        'coachingGroupSize',
        'coachingLocation',
        'biography',
        'specialistQualifications',
      ];

      if (requiredDetails.some((requirement) => existingDetails[requirement] === undefined)) {
        throw new Error('Registration details are incomplete');
      }

      const uploadProcessingPromises = existingDetails?.uploads.map((upload: any) => {
        return new Promise<IRegistrationUpload>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve({
              name: upload.name,
              dataUrl: reader.result,
              contentType: upload.type,
            });
          };

          reader.readAsDataURL(upload);
        });
      });
      const processedUploads = await Promise.all(uploadProcessingPromises);
      await RegistrationApiService.registerCoach({ ...existingDetails, uploads: processedUploads });
      onRegistrationComplete();
    } catch (error) {
      message.error('Registration could not be completed due to an error, please check all your inputs and try again');
      setSubmitting(false);
    }
  };

  return (
    <>
      <p className='mb-4'>
        I agree to provide schools with evidence of all the below documentation prior to any support being offered:
      </p>
      <div className='text-[15px]'>
        <p>
          <b>S</b>afeguarding Level 1 or above [to train with Rachael{' '}
          <span className='text-blue-500 hover:underline'>
            <a href='https://www.coachesinkind.com/safeguarding-level-1' target='_blank' rel='noopener noreferrer'>see here</a>
          </span>
          ]
        </p>
        <p>
          <b>U</b>p-to-date Enhanced DBS
        </p>
        <p>
          <b>P</b>rofessional qualification certificates
        </p>
        <p>
          <b>P</b>ublic Liability and Professional Indemnity insurance
        </p>
        <p>
          <b>O</b>riginal photographic ID
        </p>
        <p>
          <b>R</b>eferee details
        </p>
        <p>
          <b>T</b>erms that clearly state methods used
        </p>
      </div>
      <p className='mt-4'>
        I agree to the CiK Hub{' '}
        <span className='text-blue-500 hover:underline'>
          <a href='https://www.coachesinkind.com/terms-and-conditions' target='_blank' rel='noopener noreferrer'>Terms & Conditions</a>
        </span>{' '}
        and{' '}
        <span className='text-blue-500 hover:underline'>
          <a href='https://www.coachesinkind.com/privacy-policy' target='_blank' rel='noopener noreferrer'>Privacy Policy</a>
        </span>
      </p>
      <Checkbox className='my-4' checked={checked} onChange={(e) => setChecked(e.target.checked)}>
        I agree
      </Checkbox>
      <Button disabled={!checked || submitting} loading={submitting} type='primary' block onClick={submitRegistration}>
        Submit registration
      </Button>
    </>
  );
};

export default CoachRegistrationDisclaimers;
