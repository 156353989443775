import { App, Button, Checkbox, Divider, Flex, Form } from 'antd';
import { CoachingTypeData } from 'core/constants/coach-type';
import { CoachAudienceData } from 'core/constants/coach-audience';
import { GroupSizeData } from 'core/constants/group-size';
import React, { useEffect, useState } from 'react';
import { CoachLocationData } from 'core/constants/coach-location';
import { useUserState } from 'core/providers/user-provider';
import SchoolApiService from 'core/api/school/school-api.service';
import { getActionTimestampFromUser } from 'core/helpers/user-action.helpers';

interface IEditSchoolCoachingNeeds {
  closeDrawer: () => void;
}

const EditSchoolCoachingNeeds = ({ closeDrawer }: IEditSchoolCoachingNeeds) => {
  const [form] = Form.useForm();
  const { schoolData, userData } = useUserState();
  const [submitting, setSubmitting] = useState(false);
  const { message } = App.useApp();

  useEffect(() => {
    if (schoolData) {
      form.setFieldsValue(schoolData);
    }
  }, [schoolData, form]);

  const onFinish = async (data: any) => {
    const { coachingTypes, coachingAudience, coachingGroupSize, coachingLocation } = data;
    try {
      setSubmitting(true);
      await SchoolApiService.update(schoolData?.uid!, {
        coachingTypes,
        coachingAudience,
        coachingGroupSize,
        coachingLocation,
        updated: getActionTimestampFromUser(userData),
      });
      closeDrawer();
    } catch (error) {
      message.error('There was an error updating your coaching needs, please try again');
      setSubmitting(false);
    }
  };

  const checkboxGroupValidator = (value: any) => {
    if (!value || value.length === 0) {
      return Promise.reject(new Error('At least 1 option must be selected'));
    }
    return Promise.resolve();
  };

  const checkboxSections = [
    {
      label: 'Which methods of coaching are you interested in?',
      name: 'coachingTypes',
      gap: 'large',
      customRenderer: (option: any) => (
        <div className='ml-4'>
          <p className='font-semibold mb-px'>{option.label}</p>
          <p>{option.schoolDescription}</p>
        </div>
      ),
      options: Object.values(CoachingTypeData),
    },
    {
      label: 'Age group',
      name: 'coachingAudience',
      gap: 'small',
      options: Object.values(CoachAudienceData),
    },
    {
      label: 'Group size',
      name: 'coachingGroupSize',
      gap: 'small',
      options: Object.values(GroupSizeData),
    },
    {
      label: 'Location of coaching',
      name: 'coachingLocation',
      gap: 'small',
      options: Object.values(CoachLocationData),
    },
  ];

  return (
    <Form
      name='coach-registration-my-approach-form'
      form={form}
      onFinish={onFinish}
      layout='vertical'
      variant='filled'
      requiredMark='optional'
      disabled={submitting}
    >
      {checkboxSections.map((section, index) => (
        <React.Fragment key={section.name}>
          <Form.Item
            label={section.label}
            name={section.name}
            rules={[
              () => ({
                required: true,
                validator(_, value) {
                  return checkboxGroupValidator(value);
                },
              }),
            ]}
          >
            <Checkbox.Group>
              <Flex vertical gap={section.gap}>
                {section.options.map((option) => (
                  <Checkbox key={option.value} value={option.value}>
                    {section.customRenderer ? section.customRenderer(option) : option.label}
                  </Checkbox>
                ))}
              </Flex>
            </Checkbox.Group>
          </Form.Item>
          {index < checkboxSections.length - 1 && <Divider className='my-8' />}
        </React.Fragment>
      ))}

      <Form.Item>
        <Flex gap='small' className='md:mt-4'>
          <Button type='primary' htmlType='submit' block loading={submitting}>
            Save
          </Button>
        </Flex>
      </Form.Item>
    </Form>
  );
};

export default EditSchoolCoachingNeeds;
