import { Tag } from 'antd';
import { IConversationDao } from 'core/api/conversation/conversation-api.interface';
import StorageApiService from 'core/api/storage/storage-api.service';
import { AccountType } from 'core/constants/account-type';
import { useUserState } from 'core/providers/user-provider';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { AlertCircle, User } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { IConversationParticipant } from 'shared/messaging/conversation-container';
import SharedSpinner from 'shared/spinner/spinner';

interface IMessageCenterTableRow {
  conversation: IConversationDao;
}

const directions = ['left', 'right'];

interface IParticipantAvatar {
  uid: string;
  avatar?: string;
  state: string;
}

const MessageCenterTableRow = ({ conversation }: IMessageCenterTableRow) => {
  const { userData } = useUserState();
  const [isAdminViewing, setIsAdminViewing] = useState(false);
  const [participants, setParticipants] = useState<IConversationParticipant[]>();
  const [avatars, setAvatars] = useState<IParticipantAvatar[]>(
    conversation.participants.map(({ uid }) => ({ uid, state: 'loading' }))
  );
  const navigate = useNavigate();

  const configureParticipants = useCallback(() => {
    if (!userData) {
      return;
    }
    const adminViewing =
      userData.accountType === AccountType.ADMIN && !conversation.participants.some((cp) => cp.uid === userData.uid);

    const participants: IConversationParticipant[] = conversation.participants.map((cp, index) => {
      const isSelf = cp.uid === userData.attachedResourceUid || cp.uid === userData.uid;
      let direction: string;
      if (adminViewing) {
        direction = directions[index];
      } else {
        direction = isSelf ? 'left' : 'right';
      }
      switch (cp.accountType) {
        case AccountType.ADMIN: {
          const { uid, fullName, emailAddress } = conversation.admin!;
          return {
            uid,
            name: fullName,
            accountType: cp.accountType,
            isSelf,
            direction,
            emailAddress,
          };
        }
        case AccountType.COACH: {
          const { uid, fullName, profileImagePath, emailAddress } = conversation.coach!;
          return {
            uid,
            name: fullName,
            accountType: cp.accountType,
            isSelf,
            direction,
            avatarPath: profileImagePath,
            emailAddress,
          };
        }
        default: {
          const { uid, schoolName, profilePhotoPath, emailAddress } = conversation.school!;
          return {
            uid,
            name: schoolName,
            accountType: cp.accountType,
            isSelf,
            direction,
            avatarPath: profilePhotoPath,
            emailAddress,
          };
        }
      }
    });
    setIsAdminViewing(adminViewing);
    setParticipants(participants);
  }, [conversation.admin, conversation.coach, conversation.participants, conversation.school, userData]);

  useEffect(() => {
    configureParticipants();
  }, [configureParticipants]);

  const getAvatars = useCallback(async (participants: IConversationParticipant[]) => {
    const promises = participants.map(async ({ uid, avatarPath }) => {
      if (avatarPath) {
        try {
          const downloadUrl = await StorageApiService.getFileDownloadUrl(avatarPath);
          return {
            uid,
            avatar: downloadUrl,
            state: 'success',
          };
        } catch (error) {
          return {
            uid,
            state: 'error',
          };
        }
      } else {
        return {
          uid,
          state: 'unspecified',
        };
      }
    });
    const results = await Promise.all(promises);
    setAvatars(results);
  }, []);

  useEffect(() => {
    if (participants) {
      getAvatars(participants);
    }
  }, [getAvatars, participants]);

  const getRecipientTemplate = (participants: IConversationParticipant[]) => {
    const recipient = participants.find((p) => p.direction === 'right')!;
    const { state, avatar } = avatars.find((a) => a.uid === recipient.uid)!;
    return (
      <>
        <div className='basis-[48px] flex-grow-0 flex-shrink-0 h-[48px] w-[48px] bg-gray-200 rounded-full relative flex justify-center items-center overflow-hidden'>
          {state === 'loading' && <SharedSpinner color='#FF875B' size={18} />}
          {state === 'error' && <AlertCircle color='red' size={18} />}
          {state === 'unspecified' && <User size={24} className='text-gray-600' />}
          {state === 'success' && <img src={avatar} alt='avatar' className='w-full' />}
        </div>
        <div className='w-full'>
          <div className='flex justify-between'>
            <div className='flex space-x-2 items-center'>
              <p className='font-semibold text-[15px]'>{recipient.name}</p>
              {recipient.accountType === AccountType.ADMIN && <Tag color='#CFBFCA'>CiK Admin</Tag>}
            </div>
            <p className='text-[13px] text-gray-500'>
              {dayjs(conversation.updated.at.toDate()).format('DD/MM, HH:mm')}
            </p>
          </div>
          {conversation.lastMessage && (
            <p className='opacity-70 truncate max-w-[400px] md:max-w-[900px]'>
              {conversation.lastMessage.senderUid === recipient.uid ? `${recipient.name}: ` : 'You: '}
              {conversation.lastMessage.content}
            </p>
          )}
        </div>
      </>
    );
  };

  return (
    <div className='cursor-pointer' onClick={() => navigate(`conversation/${conversation.uid}`)}>
      {participants ? (
        <div className='flex items-center space-x-4 p-1'>
          {isAdminViewing && (
            <>
              <div className='flex space-x-1'>
                {participants.map((participant) => {
                  const { state, avatar } = avatars.find((a) => a.uid === participant.uid)!;
                  return (
                    <div
                      key={participant.uid}
                      className='basis-[48px] flex-grow-0 flex-shrink-0 h-[48px] w-[48px] bg-gray-200 rounded-full relative flex justify-center items-center overflow-hidden shadow-sm'
                    >
                      {state === 'loading' && <SharedSpinner color='#FF875B' size={18} />}
                      {state === 'error' && <AlertCircle color='red' size={18} />}
                      {state === 'unspecified' && <User size={24} className='text-gray-600' />}
                      {state === 'success' && <img src={avatar} alt='avatar' className='w-full' />}
                    </div>
                  );
                })}
              </div>
              <div className='w-full'>
                <div className='flex justify-between'>
                  <p className='font-semibold text-[15px]'>{participants.map((p) => p.name).join(' & ')}</p>
                  <p className='text-[13px] text-gray-500'>
                    {dayjs(conversation.updated.at.toDate()).format('DD/MM, HH:mm')}
                  </p>
                </div>
                {conversation.lastMessage && (
                  <p className='opacity-70 truncate max-w-[400px] md:max-w-[900px]'>
                    {participants.find((p) => p.uid === conversation.lastMessage?.senderUid)?.name}:{' '}
                    {conversation.lastMessage.content}
                  </p>
                )}
              </div>
            </>
          )}
          {!isAdminViewing && getRecipientTemplate(participants)}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MessageCenterTableRow;
