import SharedPaginatedTable from 'shared/table/paginated-table';
import { firestore } from 'core/config/firebase';
import { collection, orderBy, where } from 'firebase/firestore';
import { CollectionID } from 'core/constants/collection-id';
import SharedPageHeader from 'shared/page-header/page-header';
import { App, Button, Popconfirm } from 'antd';
import { IActionTimestamp } from 'core/api/api.interface';
import dayjs from 'dayjs';
import { ColumnType } from 'antd/es/table';
import { IUserDao } from 'core/api/user/user-api.interface';
import { IDropdownMenuOnClickInfo } from 'core/helpers/interface.helpers';
import { useDialog } from 'core/providers/dialog-provider';
import AdminAddEditUserDialog from './admin-add-edit-user-dialog';
import { useUserState } from 'core/providers/user-provider';
import UsersApiService from 'core/api/user/user-api.service';
import { useTable } from 'core/providers/table-data-provider';

const AdminUsersList = () => {
  const dialog = useDialog();
  const { userData } = useUserState();
  const { message } = App.useApp();
  const table = useTable('adminUsers');

  const handleDelete = async (uid: string) => {
    try {
      await UsersApiService.deleteUser(uid);
      table.refreshTable();
    } catch (err) {
      message.error('This user could not be deleted, please check your permissions and try again');
    }
  };

  const columns: ColumnType<IUserDao>[] = [
    {
      title: 'Name',
      dataIndex: 'fullName',
      key: 'fullName',
      width: 250,
    },
    {
      title: 'Email',
      dataIndex: 'emailAddress',
      key: 'emailAddress',
    },
    {
      title: 'Last updated',
      dataIndex: 'updated',
      key: 'lastUpdated',
      render: (updated: IActionTimestamp) => (
        <p>{`${dayjs(updated.at.toDate()).format('DD/MM/YYYY HH:mm')} by ${updated.by.fullName}`}</p>
      ),
    },
    {
      key: 'actions',
      render: (_, record) => (
        <div className='space-x-2'>
          <Popconfirm
            icon={<></>}
            okButtonProps={{ danger: true }}
            placement='left'
            title='Are you sure you want to delete this user?'
            onConfirm={() => handleDelete(record.uid)}
          >
            <Button disabled={record.uid === userData?.uid || record.isCiKAdmin} danger>
              Delete
            </Button>
          </Popconfirm>
        </div>
      ),
      fixed: 'right',
      width: '90px',
    },
  ];

  const headerMenu = {
    items: [
      {
        label: 'Add new admin',
        key: 'addNewAdmin',
      },
    ],
    onClick: ({ key }: IDropdownMenuOnClickInfo) => {
      if (key === 'addNewAdmin') {
        return dialog?.openDialog(<AdminAddEditUserDialog />);
      }
    },
  };

  return (
    <>
      <SharedPageHeader title='Administrators' menu={headerMenu} />
      <div className='px-4 pt-4'>
        <SharedPaginatedTable
          tableKey='adminUsers'
          collectionRef={collection(firestore, CollectionID.USERS)}
          queryConstraints={[where('accountType', '==', 'admin')]}
          queryOrder={orderBy('updated.at', 'desc')}
          errorMessage='CiK administrators could not be retrieved, please check your permissions and try again.'
          columns={columns}
          minWidth={1000}
        />
      </div>
    </>
  );
};

export default AdminUsersList;
