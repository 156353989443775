import { Button, Steps, UploadFile } from 'antd';
import AuthWrapper from '../auth-wrapper';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IAddressDao } from 'core/api/address/address-api-interface';
import CoachRegistrationEligibility from './coach-registration-eligibility-plan';
import CoachRegistrationPersonalDetails from './coach-registration-personal-details';
import CoachRegistrationQualifications from './coach-registration-qualifications';
import CoachRegistrationMyApproach from './coach-registration-my-approach';
import CoachRegistrationSpecialisms from './coach-registration-specialisms';
import CoachRegistrationDisclaimers from './coach-registration-disclaimers';

export interface ICoachRegistrationData {
  [key: string]: boolean | string | number | IAddressDao | string[] | undefined | UploadFile[];
  qualified: boolean;
  fullName: string;
  emailAddress: string;
  phoneNumber: string;
  website?: string;
  address: IAddressDao;
  maxTravel: number;
  plan: string;
  hasQualifications: boolean;
  otherTrainingDetails?: string;
  uploads: UploadFile[];
  coachingTypes: string[];
  coachingAudience: string[];
  coachingGroupSize: string[];
  coachingLocation: string[];
  biography: string;
  rateOnlineIndividual?: number;
  rateOnlineGroup?: number;
  rateInPersonIndividual?: number;
  rateInPersonGroup?: number;
  specialistQualifications: string[];
  otherSpecialism?: string;
}

export interface ICoachRegistrationChild {
  nextStep: () => void;
  previousStep: () => void;
  existingDetails?: ICoachRegistrationData;
  save: (newData: any) => void;
}

const CoachRegistration = () => {
  const [registrationData, setRegistrationData] = useState<ICoachRegistrationData>();
  const [registrationComplete, setRegistrationComplete] = useState(false);
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);

  const steps = [
    'Eligibility & Plan',
    'Personal details',
    'Qualifications/Accreditation',
    'My approach',
    'Specialisms',
    'Disclaimers',
  ];

  const nextStep = () => setCurrentStep((prevState) => prevState + 1);
  const previousStep = () => setCurrentStep((prevState) => prevState - 1);

  const saveCoachRegistrationData = (newData: any) => {
    setRegistrationData((prevState) => (prevState ? { ...prevState, ...newData } : { ...newData }));
  };

  const onRegistrationComplete = () => {
    setRegistrationData(undefined);
    setRegistrationComplete(true);
  };

  const getStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <CoachRegistrationEligibility
            existingDetails={registrationData}
            nextStep={nextStep}
            save={saveCoachRegistrationData}
            previousStep={previousStep}
          />
        );
      case 1:
        return (
          <CoachRegistrationPersonalDetails
            existingDetails={registrationData}
            nextStep={nextStep}
            save={saveCoachRegistrationData}
            previousStep={previousStep}
          />
        );
      case 2:
        return (
          <CoachRegistrationQualifications
            existingDetails={registrationData}
            save={saveCoachRegistrationData}
            nextStep={nextStep}
            previousStep={previousStep}
          />
        );
      case 3:
        return (
          <CoachRegistrationMyApproach
            existingDetails={registrationData}
            save={saveCoachRegistrationData}
            nextStep={nextStep}
            previousStep={previousStep}
          />
        );
      case 4:
        return (
          <CoachRegistrationSpecialisms
            existingDetails={registrationData}
            save={saveCoachRegistrationData}
            nextStep={nextStep}
            previousStep={previousStep}
          />
        );
      case 5:
        return (
          <CoachRegistrationDisclaimers
            existingDetails={registrationData}
            onRegistrationComplete={onRegistrationComplete}
          />
        );
      default:
        return <></>;
    }
  };

  const cardContent = (
    <div className='flex flex-col items-center'>
      {registrationComplete ? (
        <div>
          <p className='font-semibold text-[16px] mb-4'>
            Registration complete <span className='ml-1'>🎉</span>
          </p>
          <p>
            Thank you for completing your registration to become a CiK partner. The information is now being reviewed.
            As soon as your registration has been approved, an email will be shared with you with your login details.
          </p>
          <Button type='primary' className='mt-6' onClick={() => navigate('/auth/login')}>
            Return to login
          </Button>
        </div>
      ) : (
        <>
          <Steps
            size='small'
            progressDot
            current={currentStep}
            type='inline'
            items={steps.map((_, index) => ({ title: `Step ${index + 1}` }))}
          />
          <div className='mt-4 w-full'>
            <p className='font-semibold text-[16px] mb-4'>{steps[currentStep]}</p>
            {getStepContent()}
          </div>
        </>
      )}
    </div>
  );

  return (
    <AuthWrapper
      title='Coach registration'
      cardContent={cardContent}
      followingContent={
        <p
          className='hover:underline hover:text-cik_orange transition ease-in-out cursor-pointer mt-4'
          onClick={() => navigate('/auth/login')}
        >
          Return to login
        </p>
      }
    />
  );
};

export default CoachRegistration;
