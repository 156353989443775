import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { IAddressDao } from 'core/api/address/address-api-interface';
import { IUploadDao } from 'core/api/api.interface';
import { DataStatus, IListDataSlice, StoreState } from 'store';

export interface IDomainCoachRegistration {
  uid: string;
  fullName: string;
  emailAddress: string;
  phoneNumber: string;
  website?: string;
  address: IAddressDao;
  maxTravel: number;
  plan: string;
  hasQualifications: boolean;
  otherTrainingDetails?: string;
  uploads: IUploadDao[];
  coachingTypes: string[];
  coachingAudience: string[];
  coachingGroupSize: string[];
  coachingLocation: string[];
  biography: string;
  rateOnlineIndividual?: number;
  rateOnlineGroup?: number;
  rateInPersonIndividual?: number;
  rateInPersonGroup?: number;
  specialistQualifications: string[];
  otherSpecialism?: string;
  registrationType: 'coach';
  createdAtSeconds: number;
  status: string;
}

export interface IDomainSchoolRegistration {
  uid: string;
  emailAddress: string;
  registrationType: 'school';
  createdAtSeconds: number;
  status: string;
}

const initialState: IListDataSlice<IDomainCoachRegistration | IDomainSchoolRegistration> = {
  data: [],
  status: 'loading',
};

const slice = createSlice({
  name: 'registrations',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    updateData(state, action: PayloadAction<(IDomainCoachRegistration | IDomainSchoolRegistration)[]>) {
      state.data.push(...action.payload);
    },
    resetData(state) {
      state.data = [];
    },
    updateStatus(state, action: PayloadAction<DataStatus>) {
      state.status = action.payload;
    },
    updateState(state, action: PayloadAction<IListDataSlice<IDomainCoachRegistration | IDomainSchoolRegistration>>) {
      state = action.payload;
    },
  },
});

const { reset, updateData, resetData, updateStatus, updateState } = slice.actions;

const selectState = (state: StoreState) => state.registrations;

const selectRegistrationsByType = createSelector(
  [selectState, (_: StoreState, type: string) => type],
  (registrationState, type) => ({
    status: registrationState.status,
    coachRegistrations: registrationState.data.filter((reg) => reg.registrationType === type),
  })
);

export const RegistrationsSlice = {
  reset,
  updateData,
  resetData,
  updateStatus,
  updateState,
  selectState,
  selectRegistrationsByType,
};

export default slice.reducer;
