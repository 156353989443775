export const GroupSize = {
  INDIVIDUALS: 'individuals',
  SMALL: 'small',
  LARGE: 'large',
};

export const GroupSizeData = {
  [GroupSize.INDIVIDUALS]: {
    value: GroupSize.INDIVIDUALS,
    label: 'Individuals',
  },
  [GroupSize.SMALL]: {
    value: GroupSize.SMALL,
    label: 'Small groups',
  },
  [GroupSize.LARGE]: {
    value: GroupSize.LARGE,
    label: 'Large groups',
  },
};
