import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import CoachPaidOnboardingDialog from 'shared/dialog/coach-paid-onboarding-dialog';
import SetPasswordDialog from 'shared/dialog/set-password-dialog';
import SharedLayout from 'shared/layout/layout';

interface IAuthGuard {
  requiresAuth: boolean;
}

const AuthGuard = ({ requiresAuth }: IAuthGuard) => {
  const { isAuthenticated, user, userData, coachData } = useUserState();
  const location = useLocation();

  const dialog = useDialog();

  useEffect(() => {
    if (!dialog?.showDialog && userData?.resetPassword) {
      dialog?.openDialog(<SetPasswordDialog />);
      return;
    }
    if (!dialog?.showDialog && coachData?.showPaidOnboarding) {
      dialog?.openDialog(<CoachPaidOnboardingDialog />);
      return;
    }
  }, [coachData?.showPaidOnboarding, dialog, userData?.resetPassword]);

  if (requiresAuth && (!isAuthenticated || !user)) {
    return <Navigate to='auth/login' state={{ from: location }} replace />;
  }

  if (!requiresAuth && isAuthenticated) {
    return <Navigate to='' state={{ from: location }} replace />;
  }

  if (!requiresAuth) {
    return <Outlet />;
  }

  return <SharedLayout />;
};
export default AuthGuard;
