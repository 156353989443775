export const FirebaseFunctionName = {
  CREATE_ADMIN: 'createAdmin',
  REGISTER_COACH: 'registerCoach',
  GET_FILE_SIGNED_URL: 'getFileSignedUrl',
  APPROVE_COACH: 'approveCoach',
  REJECT_COACH: 'rejectCoach',
  REGISTER_SCHOOL: 'registerSchool',
  SEND_BULK_MESSAGE: 'sendBulkMessage',
  RESET_USER_PASSWORD: 'resetUserPassword',
};
