import { App, Button, Form, Input } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthWrapper from './auth-wrapper';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from 'core/config/firebase';

interface IForgotPasswordFormOutput {
  emailAddress: string;
}

const ForgotPasswordPage = () => {
  const [form] = Form.useForm();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const { message } = App.useApp();
  const navigate = useNavigate();

  const onFinish = async (data: IForgotPasswordFormOutput) => {
    try {
      setFormSubmitting(true);
      await sendPasswordResetEmail(auth, data.emailAddress);
      navigate('/login');
      message.success(
        <p className='max-w-[400px]'>
          Thanks, if the email address you entered is associated with an account, you will receive an email with
          instructions to reset your password. If you can't find the email, be sure to check your junk mailbox
        </p>,
        5
      );
    } catch (error: any) {
      setFormSubmitting(false);
      message.error('The email address or password you entered is incorrect');
    }
  };

  const cardContent = (
    <Form
      name='forgot-password-form'
      form={form}
      onFinish={onFinish}
      layout='vertical'
      variant='filled'
      requiredMark={false}
      disabled={formSubmitting}
    >
      <p className='mb-6'>
        Enter the email address associated with your account, and we'll send you a link to reset your password.
      </p>

      <Form.Item
        label='Email address'
        name='emailAddress'
        rules={[{ required: true, message: 'Please enter your email address' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item className='m-0'>
        <div className='flex justify-end space-x-2'>
          <Button disabled={formSubmitting} onClick={() => navigate(-1)}>
            Cancel
          </Button>
          <Button type='primary' htmlType='submit' loading={formSubmitting}>
            Submit
          </Button>
        </div>
      </Form.Item>
    </Form>
  );

  return (
    <AuthWrapper
      title='Forgot password'
      cardContent={cardContent}
      followingContent={
        <p
          className='hover:underline hover:text-cik_orange transition ease-in-out cursor-pointer mt-4'
          onClick={() => navigate('/auth/login')}
        >
          Return to login
        </p>
      }
    />
  );
};

export default ForgotPasswordPage;
