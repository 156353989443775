import { Button, Flex, Form, Input, Upload, message } from 'antd';
import { useEffect, useState } from 'react';
import type { UploadFile, UploadProps } from 'antd';
import { ICoachRegistrationChild } from './coach-registration';

const CoachRegistrationQualifications = ({
  nextStep,
  previousStep,
  existingDetails,
  save,
}: ICoachRegistrationChild) => {
  const [canProvide, setCanProvide] = useState<boolean>();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    if (existingDetails) {
      setCanProvide(existingDetails.hasQualifications);
      form.setFieldsValue({
        otherTrainingDetails: existingDetails.otherTrainingDetails,
        uploads: { fileList: existingDetails.uploads ?? [] },
      });
      setFileList(existingDetails.uploads ?? []);
    }
  }, [existingDetails, form]);

  const onFinish = (data: any) => {
    save({ hasQualifications: canProvide, uploads: fileList, otherTrainingDetails: data.otherTrainingDetails });
    nextStep();
  };

  const uploadProps: UploadProps = {
    onRemove: (file: UploadFile) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file: UploadFile) => {
      if (!file.size || file.size > 3000000) {
        message.info("We're sorry, but files must be under 3MB");
      } else {
        setFileList([...fileList, file]);
        return false;
      }
    },
    fileList,
  };

  return (
    <>
      <p>
        Can you provide evidence for at least one of the coaching qualifications/accreditation that are listed below
      </p>
      <ul className='list-disc ml-4 mt-4 mb-4'>
        <li>International Coaching Federation (ICF)</li>
        <li>Association for Coaching (AC)</li>
        <li>British Association for Counselling and Psychotherapy (BACP)</li>
        <li>European Mentoring and Coaching Council UK (EMCC UK)</li>
        <li>International Authority for Professional Coaching and Mentoring (IAPC&M)</li>
        <li>Institute of Leadership and Management (ILM)</li>
        <li>UK and International Health Coaching Association(UKIHCA)</li>
        <li>An academic coaching diploma or degree qualification from a recognised body</li>
        <li>The Coaching Academy</li>
      </ul>

      <Button
        onClick={() => {
          setCanProvide(true);
          form.setFieldValue('otherTrainingDetails', undefined);
        }}
        type={canProvide === true ? 'primary' : 'default'}
        className='mr-2'
      >
        Yes, I can
      </Button>

      <Button type={canProvide === false ? 'primary' : 'default'} onClick={() => setCanProvide(false)}>
        No, I can't
      </Button>

      <Form
        name='coach-qualifications-qualifications-form'
        form={form}
        onFinish={onFinish}
        layout='vertical'
        variant='filled'
        requiredMark='optional'
      >
        {canProvide === false && (
          <div className='mt-8'>
            <div className='bg-blue-200 border border-blue-400 rounded-lg p-3 text-gray-800'>
              <p>
                If you have completed a different qualification, please upload your certificate and provide written
                details of how the training covered both theory and practice plus an ethical component. This will be
                reviewed on your application. Or, if you are a mentor-coach rather than a non-advisory coach, please
                upload your CV with details of experience and qualifications in the area. This will be reviewed on your
                application.
              </p>
            </div>
            <p></p>
            <Form.Item className='mt-4' label='Training details' name='otherTrainingDetails'>
              <Input.TextArea />
            </Form.Item>
          </div>
        )}
        {canProvide !== undefined && (
          <>
            <Form.Item
              className={canProvide ? 'mt-6' : ''}
              label='Uploads'
              name='uploads'
              rules={[
                () => ({
                  required: true,
                  validator(_, value) {
                    if (!value || value.fileList.length === 0) {
                      return Promise.reject(new Error('You must upload a file'));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Upload {...uploadProps}>
                <Button>Click to Upload</Button>
              </Upload>
            </Form.Item>

            <Form.Item>
              <Flex gap='small' className='md:mt-4'>
                <Button type='primary' block onClick={() => previousStep()}>
                  Previous
                </Button>
                <Button type='primary' htmlType='submit' block>
                  Next
                </Button>
              </Flex>
            </Form.Item>
          </>
        )}
      </Form>
    </>
  );
};

export default CoachRegistrationQualifications;
