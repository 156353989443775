import clsx from 'clsx';
import { INavbarOption } from 'core/config/navbar-options';
import { useLocation, useNavigate } from 'react-router-dom';

interface ISharedSidebarItem extends INavbarOption {
  onClick: () => void;
}

const SharedSidebarItem = ({ NavIcon, label, route, onClick }: ISharedSidebarItem) => {
  const location = useLocation();
  const active = location.pathname.startsWith(`/${route}`);
  const navigate = useNavigate();

  return (
    <div
      className={clsx(
        'rounded-full p-[16px] px-[18px] text-white flex items-center transition ease-in-out cursor-pointer',
        active ? 'bg-cik_orange' : 'hover:bg-cik_orange/10'
      )}
      onClick={() => {
        navigate(`/${route}`);
        onClick();
      }}
    >
      <NavIcon />
      <p className='ml-[16px] quicksand uppercase font-semibold text-[15px]'>{label}</p>
    </div>
  );
};

export default SharedSidebarItem;
