import { createContext, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FirestoreError, QuerySnapshot, Unsubscribe } from 'firebase/firestore';
import { RegistrationsSlice } from 'modules/admin/admin-registrations-slice';
import RegistrationApiService from 'core/api/registration/registration-api.service';
import { ICoachRegistrationDao } from 'core/api/registration/registration-api-interface';
import { App } from 'antd';

export const AdminFirestoreCacheContext = createContext({});

export const AdminFirestoreCacheProvider = ({ children }: any) => {
  const dispatch = useDispatch();
  const { message } = App.useApp();

  const handleSubscriptionError = useCallback(
    (error: FirestoreError) => {
      dispatch(RegistrationsSlice.updateStatus('error'));
      message.error('A critical error occurred, please refresh your page', 10);
    },
    [dispatch, message]
  );

  const handleSnapshot = useCallback(
    (snap: QuerySnapshot<ICoachRegistrationDao>) => {
      dispatch(RegistrationsSlice.resetData());
      const domainRegistrations =
        snap.docs.map((doc) => {
          const { createdAt, ...rest } = doc.data();
          return { ...rest, createdAtSeconds: createdAt.seconds };
        }) ?? [];
      dispatch(RegistrationsSlice.updateStatus('success'));
      dispatch(RegistrationsSlice.updateData(domainRegistrations));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(RegistrationsSlice.updateStatus('loading'));
    let unsubscribe: Unsubscribe;
    unsubscribe = RegistrationApiService.onSnapshot(handleSnapshot, handleSubscriptionError);
    return () => {
      unsubscribe();
      dispatch(RegistrationsSlice.reset());
    };
  }, [dispatch, handleSnapshot, handleSubscriptionError]);

  return <AdminFirestoreCacheContext.Provider value={{}}>{children}</AdminFirestoreCacheContext.Provider>;
};
