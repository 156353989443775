import { App } from 'antd';
import ConversationApiService from 'core/api/conversation/conversation-api.service';
import { ISchoolDao } from 'core/api/school/school-api.interface';
import SchoolApiService from 'core/api/school/school-api.service';
import { AccountType } from 'core/constants/account-type';
import { IDropdownMenuOnClickInfo } from 'core/helpers/interface.helpers';
import { getActionTimestampFromUser } from 'core/helpers/user-action.helpers';
import { useUserState } from 'core/providers/user-provider';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SharedPageHeader from 'shared/page-header/page-header';
import SharedSchoolProfile from 'shared/school-profile/school-profile';
import SharedSpinner from 'shared/spinner/spinner';
import { v4 as uuid } from 'uuid';

const SchoolSearchViewSchool = () => {
  const { uid } = useParams();
  const [loading, setLoading] = useState(true);
  const { message } = App.useApp();
  const [school, setSchool] = useState<ISchoolDao>();
  const [menuLoading, setMenuLoading] = useState(false);
  const { coachData, userData } = useUserState();
  const navigate = useNavigate();

  const getSchool = useCallback(
    async (uid: string) => {
      try {
        const snap = await SchoolApiService.get(uid);
        if (!snap.exists() || !snap.data()) {
          message.error('This school does not exist');
        } else {
          setSchool(snap.data());
          setLoading(false);
        }
      } catch (error) {
        message.error('Failed to fetch school profile, please try again');
      }
    },
    [message]
  );

  useEffect(() => {
    if (uid) {
      getSchool(uid);
    } else {
      message.error('Invalid parameter provided');
    }
  }, [getSchool, message, uid]);

  const headerMenu = {
    loading: menuLoading,
    items: [
      {
        label: 'Send message',
        key: 'sendMessage',
      },
    ],
    onClick: async ({ key }: IDropdownMenuOnClickInfo) => {
      if (key === 'sendMessage') {
        try {
          if (!school || !coachData) {
            throw new Error('Invalid arguments');
          }
          const snap = await ConversationApiService.getConversationBetweenParticipants(coachData.uid, school.uid);
          if (snap.empty) {
            const actor = getActionTimestampFromUser(userData);
            const uid = uuid();
            await ConversationApiService.set({
              coach: coachData,
              school,
              uid,
              created: actor,
              updated: actor,
              messageCount: 0,
              participants: [
                { uid: school.uid, accountType: AccountType.SCHOOL },
                { uid: coachData.uid, accountType: AccountType.COACH },
              ],
            });
            navigate(`/message-center/conversation/${uid}`);
          } else {
            navigate(`/message-center/conversation/${snap.docs[0].id}`);
          }
          setMenuLoading(false);
        } catch (error) {
          setMenuLoading(false);
          message.error('Unable to go to conversation, please try again');
        }
      }
    },
  };

  return (
    <>
      <SharedPageHeader title='School profile' showBack menu={headerMenu} />
      {loading || !school ? (
        <div className='h-[260px] w-full flex items-center justify-center'>
          <SharedSpinner color='#FF875B' size={24} />
        </div>
      ) : (
        <div className='pt-4 px-4'>
          <SharedSchoolProfile schoolData={school} />
        </div>
      )}
    </>
  );
};

export default SchoolSearchViewSchool;
