import { App, Button, Form, Input, Space } from 'antd';
import UsersApiService from 'core/api/user/user-api.service';
import { useDialog } from 'core/providers/dialog-provider';
import { useTable } from 'core/providers/table-data-provider';
import { useState } from 'react';
import SharedDialogBase from 'shared/dialog/dialog-base';
import NewUserPasswordDialog from 'shared/dialog/new-user-password-dialog';

interface IAdminAddEditUserDialogFormOutput {
  fullName: string;
  emailAddress: string;
}

interface IAdminAddEditUserDialog {
  uid?: string;
}

const AdminAddEditUserDialog = ({ uid }: IAdminAddEditUserDialog) => {
  const [form] = Form.useForm();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const creating = !uid;
  const dialog = useDialog();
  const { message } = App.useApp();
  const table = useTable('adminUsers');

  const onFinish = async ({ fullName, emailAddress }: IAdminAddEditUserDialogFormOutput) => {
    try {
      setFormSubmitting(true);
      const temporaryPassword = await UsersApiService.createAdmin({
        fullName,
        emailAddress,
      });
      table.refreshTable();
      dialog?.replaceDialog(
        <NewUserPasswordDialog fullName={fullName} emailAddress={emailAddress} temporaryPassword={temporaryPassword} />
      );
    } catch (error: any) {
      setFormSubmitting(false);
      message.error('User creation failed due to an error, please try again.');
    }
  };

  const customContent = () => {
    return (
      <div className='px-4 pt-4'>
        <Form
          name='admin-user-form'
          form={form}
          onFinish={onFinish}
          layout='vertical'
          variant='filled'
          requiredMark={false}
          disabled={formSubmitting}
        >
          <Form.Item
            label='Full name'
            name='fullName'
            rules={[{ required: true, message: 'Please enter a full name' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Email address'
            name='emailAddress'
            rules={[{ type: 'email', required: true, message: 'Please enter a valid email address' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Space className='md:mt-4'>
              <Button disabled={formSubmitting} onClick={() => dialog?.closeDialog()}>
                Cancel
              </Button>
              <Button type='primary' htmlType='submit' loading={formSubmitting}>
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    );
  };

  return (
    <SharedDialogBase
      title={creating ? 'Add administrator' : 'Edit administrator'}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AdminAddEditUserDialog;
