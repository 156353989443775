import { Routes, Route, Navigate } from 'react-router-dom';
import MessageCenter from './message-center';
import Conversation from './conversation';

const MessageCenterRouting = () => {
  return (
    <Routes>
      <Route path='' element={<MessageCenter />} />
      <Route path='conversation/:uid' element={<Conversation />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default MessageCenterRouting;
