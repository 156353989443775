import { Navigate, Route, Routes } from 'react-router-dom';
import AuthRouting from 'modules/auth/auth-routing';
import { useUserState } from 'core/providers/user-provider';
import SharedSpinner from 'shared/spinner/spinner';
import AuthGuard from './auth-guard';
import AdminGuard from './admin-guard';
import AdminRouting from 'modules/admin/admin-routing';
import AccountTypeGuard from './account-type-guard';
import { AccountType } from 'core/constants/account-type';
import SchoolSearchRouting from 'modules/school-search/school-search-routing';
import CoachSearchRouting from 'modules/coach-search/coach-search-routing';
import MyProfileRouting from 'modules/my-profile/my-profile-routing';
import OurSchoolRouting from 'modules/our-school/our-school-routing';
import MessageCenterRouting from 'modules/message-center/message-center-routing';

const AppRouting = () => {
  const { authChecked, user, userData, coachData } = useUserState();

  if (!authChecked || (user && !userData) || (userData?.accountType === AccountType.COACH && !coachData)) {
    return (
      <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
        <SharedSpinner color='#FF875B' size={24} />
      </div>
    );
  }

  return (
    <Routes>
      <Route element={<AuthGuard requiresAuth={false} />}>
        <Route path='auth/*' element={<AuthRouting />} />
      </Route>

      <Route element={<AuthGuard requiresAuth={true} />}>
        <Route element={<AdminGuard requiresAdmin={true} />}>
          <Route path='admin/*' element={<AdminRouting />} />
        </Route>

        <Route element={<AdminGuard requiresAdmin={false} />}>
          <Route element={<AccountTypeGuard allowedAccountTypes={[AccountType.SCHOOL]} />}>
            <Route path='find-a-coach/*' element={<CoachSearchRouting />} />
          </Route>

          <Route element={<AccountTypeGuard allowedAccountTypes={[AccountType.SCHOOL, AccountType.COACH]} />}>
            <Route path='message-center/*' element={<MessageCenterRouting />} />
          </Route>

          <Route element={<AccountTypeGuard allowedAccountTypes={[AccountType.COACH]} />}>
            <Route path='school-search/*' element={<SchoolSearchRouting />} />
          </Route>

          <Route element={<AccountTypeGuard allowedAccountTypes={[AccountType.COACH]} />}>
            <Route path='my-profile/*' element={<MyProfileRouting />} />
          </Route>

          <Route element={<AccountTypeGuard allowedAccountTypes={[AccountType.SCHOOL]} />}>
            <Route path='our-school/*' element={<OurSchoolRouting />} />
          </Route>
        </Route>
      </Route>

      <Route path='' element={<Navigate replace to={'school-search'} />} />
      <Route path='*' element={<Navigate replace to={'school-search'} />} />
    </Routes>
  );
};

export default AppRouting;
