import { AccountType } from 'core/constants/account-type';
import { AdminFirestoreCacheProvider } from 'core/providers/admin-firestore-cache-provider';
import { useUserState } from 'core/providers/user-provider';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

interface IAdminGuard {
  requiresAdmin: boolean;
}

const AdminGuard = ({ requiresAdmin }: IAdminGuard) => {
  const { userData } = useUserState();
  const isAdmin = userData?.accountType === AccountType.ADMIN;
  const location = useLocation();

  if (requiresAdmin && !isAdmin) {
    return <Navigate to='/' state={{ from: location }} replace />;
  }

  if (!requiresAdmin && isAdmin) {
    return <Navigate to='/admin' state={{ from: location }} replace />;
  }

  if (requiresAdmin && isAdmin) {
    return (
      <AdminFirestoreCacheProvider>
        <Outlet />
      </AdminFirestoreCacheProvider>
    );
  }

  return <Outlet />;
};
export default AdminGuard;
