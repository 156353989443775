import { Button, Checkbox, Flex, Form, Input, message } from 'antd';
import CoachApiService from 'core/api/coach/coach-api.service';
import { SpecialismQualificationData } from 'core/constants/specialism-qualification';
import { getActionTimestampFromUser } from 'core/helpers/user-action.helpers';
import { useUserState } from 'core/providers/user-provider';
import { useEffect, useState } from 'react';

interface IEditSpecialisms {
  closeDrawer: () => void;
}

const EditSpecialisms = ({ closeDrawer }: IEditSpecialisms) => {
  const [form] = Form.useForm();
  const { coachData, userData } = useUserState();
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (coachData) {
      form.setFieldsValue(coachData);
    }
  }, [coachData, form]);

  const onFinish = async (data: any) => {
    const { specialistQualifications, otherSpecialism } = data;
    try {
      setSubmitting(true);
      await CoachApiService.update(coachData?.uid!, {
        specialistQualifications,
        ...(otherSpecialism && { otherSpecialism }),
        updated: getActionTimestampFromUser(userData),
      });
      closeDrawer();
    } catch (error) {
      message.error('There was an error updating your specialisms, please try again');
      setSubmitting(false);
    }
  };

  const checkboxGroupValidator = (value: any) => {
    if (!value || value.length === 0) {
      return Promise.reject(new Error('At least 1 option must be selected'));
    }
    return Promise.resolve();
  };

  return (
    <Form
      name='coach-registration-specialisms-form'
      form={form}
      onFinish={onFinish}
      layout='vertical'
      variant='filled'
      requiredMark='optional'
      disabled={submitting}
    >
      <Form.Item
        label='I also have specialist qualifications in'
        name='specialistQualifications'
        rules={[
          () => ({
            required: true,
            validator(_, value) {
              return checkboxGroupValidator(value);
            },
          }),
        ]}
      >
        <Checkbox.Group>
          <Flex vertical gap='small'>
            {Object.values(SpecialismQualificationData).map((specialism) => (
              <Checkbox key={specialism.value} value={specialism.value}>
                {specialism.label}
              </Checkbox>
            ))}
            <Checkbox key='other' value='other'>
              Other - please specify
            </Checkbox>
          </Flex>
        </Checkbox.Group>
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.specialistQualifications !== currentValues.specialistQualifications
        }
      >
        {({ getFieldValue }) =>
          getFieldValue('specialistQualifications')?.includes('other') && (
            <Form.Item
              name='otherSpecialism'
              label='Other specialism details'
              rules={[{ required: true, message: 'Please provide details of your other specialist qualification' }]}
            >
              <Input />
            </Form.Item>
          )
        }
      </Form.Item>

      <Form.Item>
        <Button type='primary' htmlType='submit' block loading={submitting}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditSpecialisms;
