import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthWrapper from '../auth-wrapper';
import SchoolRegistrationSchoolDetails from './school-registration-details';
import { Button, Steps } from 'antd';
import { IAddressDao } from 'core/api/address/address-api-interface';
import SchoolRegistrationCoachingNeeds from './school-registration-coaching-needs';
import SchoolRegistrationDisclaimers from './school-registration-disclaimers';
import { FileType } from 'core/helpers/interface.helpers';

export interface ISchoolProfilePhoto {
  url: string;
  file: FileType;
}

export interface ISchoolRegistrationData {
  [key: string]: string | IAddressDao | ISchoolProfilePhoto | undefined | string[];
  schoolName: string;
  mainStaffContact: string;
  emailAddress: string;
  address: IAddressDao;
  profilePhoto?: ISchoolProfilePhoto;
  coachingTypes: string[];
  coachingAudience: string[];
  coachingGroupSize: string[];
  coachingLocation: string[];
}

export interface ISchoolRegistrationChild {
  nextStep: () => void;
  previousStep: () => void;
  existingDetails?: ISchoolRegistrationData;
  save: (newData: any) => void;
}

const SchoolRegistration = () => {
  const [registrationData, setRegistrationData] = useState<ISchoolRegistrationData>();
  const [registrationComplete, setRegistrationComplete] = useState(false);
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);

  const steps = ['School details', 'Coaching needs', 'Disclaimers'];

  const nextStep = () => setCurrentStep((prevState) => prevState + 1);
  const previousStep = () => setCurrentStep((prevState) => prevState - 1);

  const saveData = (newData: any) => {
    setRegistrationData((prevState) => (prevState ? { ...prevState, ...newData } : { ...newData }));
  };

  const onRegistrationComplete = () => {
    setRegistrationData(undefined);
    setRegistrationComplete(true);
  };

  const getStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <SchoolRegistrationSchoolDetails
            existingDetails={registrationData}
            nextStep={nextStep}
            save={saveData}
            previousStep={previousStep}
          />
        );
      case 1:
        return (
          <SchoolRegistrationCoachingNeeds
            existingDetails={registrationData}
            nextStep={nextStep}
            save={saveData}
            previousStep={previousStep}
          />
        );
      case 2:
        return (
          <SchoolRegistrationDisclaimers
            existingDetails={registrationData}
            onRegistrationComplete={onRegistrationComplete}
          />
        );
      default:
        return <></>;
    }
  };

  const cardContent = (
    <div className='flex flex-col items-center'>
      {registrationComplete ? (
        <div>
          <p className='font-semibold text-[16px] mb-4'>
            Registration complete <span className='ml-1'>🎉</span>
          </p>
          <p>
            Thank you for completing your registration to become a CiK partner school. You will shortly receive an email
            with details on how to access your account.
          </p>
          <Button type='primary' className='mt-6' onClick={() => navigate('/auth/login')}>
            Return to login
          </Button>
        </div>
      ) : (
        <>
          <Steps
            size='small'
            progressDot
            current={currentStep}
            type='inline'
            items={steps.map((_, index) => ({ title: `Step ${index + 1}` }))}
          />
          <div className='mt-4 w-full'>
            <p className='font-semibold text-[16px] mb-4'>{steps[currentStep]}</p>
            {getStepContent()}
          </div>
        </>
      )}
    </div>
  );

  return (
    <AuthWrapper
      title='School registration'
      cardContent={cardContent}
      followingContent={
        <p
          className='hover:underline hover:text-cik_orange transition ease-in-out cursor-pointer mt-4'
          onClick={() => navigate('/auth/login')}
        >
          Return to login
        </p>
      }
    />
  );
};

export default SchoolRegistration;
