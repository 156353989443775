import { Navigate, Route, Routes } from 'react-router-dom';
import AdminUsersRouting from './users/admin-users-routing';
import AdminCoachesList from './coaches/admin-coaches-list';
import AdminCoachRegistrationsList from './coaches/admin-coach-registrations-list';
import AdminSchoolsList from './schools/admin-schools-list';
import AdminMessages from './messages/messages';
import AdminMyConversations from './messages/my-conversations';
import Conversation from 'modules/message-center/conversation';

const AdminRouting = () => {
  return (
    <Routes>
      <Route path='users/*' element={<AdminUsersRouting />} />
      <Route path='coaches' element={<AdminCoachesList />} />
      <Route path='coaches/registrations' element={<AdminCoachRegistrationsList />} />
      <Route path='schools/*' element={<AdminSchoolsList />} />
      <Route path='messages' element={<AdminMessages />} />
      <Route path='messages/my-conversations' element={<AdminMyConversations />} />
      <Route path='messages/my-conversations/conversation/:uid' element={<Conversation />} />
      <Route path='messages/conversation/:uid' element={<Conversation />} />
      <Route path='*' element={<Navigate replace to='users' />} />
    </Routes>
  );
};

export default AdminRouting;
