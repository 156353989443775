import { Button, Dropdown, MenuProps } from 'antd';
import { ArrowLeftCircle } from 'react-feather';
import { useNavigate } from 'react-router-dom';

interface ISharedPageHeader {
  title?: string;
  showBack?: boolean;
  backPath?: string;
  backLabel?: string;
  menu?: {
    items: MenuProps['items'];
    onClick: MenuProps['onClick'];
    loading?: boolean;
  };
}

const SharedPageHeader = ({ title, showBack = false, backPath, backLabel, menu }: ISharedPageHeader) => {
  const navigate = useNavigate();

  return (
    <div className='basis-[60px] md:basis-[70px] flex-grow-0 flex-shrink-0 h-[60px] md:h-[70px] flex items-center justify-between px-4 md:px-8 border-b bg-white'>
      <div className='flex items-center space-x-4'>
        {showBack && (
          <div className='cursor-pointer' onClick={() => (backPath ? navigate(backPath) : navigate(-1))}>
            <ArrowLeftCircle className='text-cik_orange' size={25} />
          </div>
        )}
        {title && (
          <p className='text-[16px] md:text-[20px] font-semibold uppercase text-cik_red tracking-[0.5px]'>
            <span className='text-cik_orange'>{title.charAt(0)}</span>
            {title.slice(1)}
          </p>
        )}
      </div>

      {menu && (
        <Dropdown menu={{ items: menu.items, onClick: menu.onClick }} trigger={['click']}>
          <Button loading={menu.loading}>Actions</Button>
        </Dropdown>
      )}
    </div>
  );
};

export default SharedPageHeader;
