import {
  DocumentData,
  DocumentSnapshot,
  FirestoreError,
  QueryConstraint,
  QueryDocumentSnapshot,
  UpdateData,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
} from 'firebase/firestore';
import { firestore } from 'core/config/firebase';
import { CollectionID } from 'core/constants/collection-id';
import { ISchoolDao } from './school-api.interface';
import ApiService from '../api.service';

const collectionRef = collection(firestore, CollectionID.SCHOOLS);

const subscribeToDoc = (
  uid: string,
  resultHandler: (snap: DocumentSnapshot<ISchoolDao, DocumentData>) => void,
  errorHandler: (error: FirestoreError) => void
) => {
  const docRef = doc(firestore, CollectionID.SCHOOLS, uid).withConverter(converter());
  return ApiService.subscribeToDoc(docRef, resultHandler, errorHandler);
};

const deleteSchool = async (uid: string) => {
  const docRef = doc(firestore, CollectionID.SCHOOLS, uid).withConverter(converter());
  return deleteDoc(docRef);
};

const update = async (uid: string, payload: UpdateData<ISchoolDao>) => {
  const docRef = doc(firestore, CollectionID.SCHOOLS, uid).withConverter(converter());
  return updateDoc(docRef, payload);
};

const get = (uid: string) => {
  const docRef = doc(firestore, CollectionID.SCHOOLS, uid).withConverter(converter());
  return getDoc(docRef);
};

const list = (constraints: QueryConstraint[] = []) => {
  const q = query(collectionRef, ...constraints);
  return getDocs(q.withConverter(converter()));
};
const converter = () => ({
  toFirestore: (dao: ISchoolDao) => dao,
  fromFirestore: (snapshot: QueryDocumentSnapshot): ISchoolDao => snapshot.data() as ISchoolDao,
});

const SchoolApiService = {
  deleteSchool,
  subscribeToDoc,
  update,
  get,
  list,
};

export default SchoolApiService;
