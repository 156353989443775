import { functions } from 'core/config/firebase';
import {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  FirestoreError,
  Query,
  QuerySnapshot,
  onSnapshot,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

const callFunction = async <T, K>(payload: T, name: string) => {
  const fbFunction = httpsCallable<T, K>(functions, name);
  const result = await fbFunction(payload);
  return result.data;
};

const subscribeToCollection = <T = DocumentData>(
  query: Query<T>,
  resultHandler: (snap: QuerySnapshot<T, DocumentData>) => void,
  errorHandler: (error: FirestoreError) => void
) => {
  return onSnapshot(
    query,
    (snapShot) => {
      resultHandler(snapShot);
    },
    (error) => {
      errorHandler(error);
    }
  );
};

const subscribeToDoc = <T = DocumentData>(
  docRef: DocumentReference<T>,
  resultHandler: (snap: DocumentSnapshot<T, DocumentData>) => void,
  errorHandler: (error: FirestoreError) => void
) => {
  return onSnapshot(
    docRef,
    (snapShot) => {
      resultHandler(snapShot);
    },
    (error) => {
      errorHandler(error);
    }
  );
};

const ApiService = {
  callFunction,
  subscribeToCollection,
  subscribeToDoc,
};

export default ApiService;
