export const CoachRegistrationStatus = {
  AWAITING_REVIEW: 'awaitingReview',
  AWAITING_PAYMENT: 'awaitingPayment',
};

export const CoachRegistrationStatusData = {
  [CoachRegistrationStatus.AWAITING_REVIEW]: {
    value: CoachRegistrationStatus.AWAITING_REVIEW,
    label: 'Awaiting review',
    color: 'red',
  },
  [CoachRegistrationStatus.AWAITING_PAYMENT]: {
    value: CoachRegistrationStatus.AWAITING_PAYMENT,
    label: 'Awaiting payment',
    color: 'gold',
  },
};
