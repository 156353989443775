import { CoachPlan, CoachPlanData } from 'core/constants/coach-plan';
import { IDomainCoachRegistration } from '../admin-registrations-slice';
import { isNotNullOrEmpty } from 'core/helpers/null-checkers';
import { ReactElement, useState } from 'react';
import { IUploadDao } from 'core/api/api.interface';
import StorageApiService from 'core/api/storage/storage-api.service';
import { App, Button, Drawer, Space } from 'antd';
import { CoachingTypeData } from 'core/constants/coach-type';
import { StringIndexedAnyValue } from 'core/helpers/interface.helpers';
import { CoachAudienceData } from 'core/constants/coach-audience';
import { GroupSizeData } from 'core/constants/group-size';
import { CoachLocationData } from 'core/constants/coach-location';
import { SpecialismQualificationData } from 'core/constants/specialism-qualification';
import { Paperclip } from 'react-feather';
import { CoachRegistrationStatus } from 'core/constants/coach-registration-status';
import RegistrationApiService from 'core/api/registration/registration-api.service';

interface IAdminCoachRegistrationDetail {
  details?: IDomainCoachRegistration;
  isOpen: boolean;
  onClose: () => void;
}

interface IAdminCoachRegistrationDetailSection {
  key: string;
  title: string;
  value: string | undefined | ReactElement;
}

const AdminCoachRegistrationDetail = ({ details, isOpen, onClose }: IAdminCoachRegistrationDetail) => {
  const [documentsLoading, setDocumentsLoading] = useState<string[]>([]);
  const { message } = App.useApp();
  const [approving, setApproving] = useState(false);
  const [rejecting, setRejecting] = useState(false);

  const openDocument = async (selectedDoc: IUploadDao) => {
    setDocumentsLoading((prevState) => [...prevState, selectedDoc.fileName]);
    try {
      const url = await StorageApiService.getSignedFileUrl(Date.now() + 1000 * 60 * 60, selectedDoc.path);
      window.open(url);
      removeFromDocumentLoading(selectedDoc.fileName);
    } catch (error) {
      removeFromDocumentLoading(selectedDoc.fileName);
      message.error('The document cannot be viewed due to an error, please try again.');
    }
  };

  const removeFromDocumentLoading = (selectedDocUid: string) => {
    setDocumentsLoading(documentsLoading.filter((uid) => uid === selectedDocUid));
  };

  const checkboxContentType = <T extends StringIndexedAnyValue>(checks: string[], dataObject: T) => (
    <>
      {checks.map((check) => (
        <p key={dataObject[check].value}>{dataObject[check].label ?? 'Unknown'}</p>
      ))}
    </>
  );

  const getSectionContent = ({
    plan,
    fullName,
    emailAddress,
    phoneNumber,
    website,
    address,
    maxTravel,
    hasQualifications,
    uploads,
    coachingAudience,
    coachingGroupSize,
    coachingLocation,
    coachingTypes,
    biography,
    otherTrainingDetails,
    rateInPersonGroup,
    rateInPersonIndividual,
    rateOnlineGroup,
    rateOnlineIndividual,
    specialistQualifications,
    otherSpecialism,
  }: IDomainCoachRegistration) => {
    const rateInputs = [
      {
        label: 'Online (Individual)',
        name: 'rateOnlineIndividual',
        value: rateOnlineIndividual,
      },
      {
        label: 'Online (Group – illustrate total for 12 people)',
        name: 'rateOnlineGroup',
        value: rateOnlineGroup,
      },
      {
        label: 'In-Person (Individual)',
        name: 'rateInPersonIndividual',
        value: rateInPersonIndividual,
      },
      {
        label: 'In-Person (Group – illustrate total for 12 people)',
        name: 'rateInPersonGroup',
        value: rateInPersonGroup,
      },
    ];

    const sections: IAdminCoachRegistrationDetailSection[] = [
      {
        key: 'plan',
        title: 'Selected plan',
        value: CoachPlanData[plan].title ?? 'Unknown',
      },
      {
        key: 'fullName',
        title: 'Full name',
        value: fullName,
      },
      {
        key: 'emailAddress',
        title: 'Email address',
        value: emailAddress,
      },
      {
        key: 'phoneNumber',
        title: 'Phone number',
        value: phoneNumber,
      },
      {
        key: 'address',
        title: 'Address',
        value: address.formattedAddress,
      },
      {
        key: 'maxDistance',
        title: 'Maximum travel distance from postcode',
        value: `${maxTravel} miles`,
      },
      {
        key: 'hasQualifications',
        title: 'Can provide evidence for accepted qualifications/accreditation',
        value: hasQualifications ? 'Yes' : 'No',
      },
      {
        key: 'uploads',
        title: 'Qualifications/Accreditation uploads',
        value: (
          <>
            {uploads.map((upload) => (
              <div key={upload.fileName} className='flex items-center space-x-1'>
                <Paperclip size={18} className='text-gray-500' />
                <p>{upload.fileName}</p>
                <Button
                  onClick={() => openDocument(upload)}
                  loading={documentsLoading.includes(upload.fileName)}
                  type='link'
                >
                  View
                </Button>
              </div>
            ))}
          </>
        ),
      },
      {
        key: 'coachingTypes',
        title: 'Coaching types',
        value: checkboxContentType(coachingTypes, CoachingTypeData),
      },
      {
        key: 'coachingAudience',
        title: 'Can coach',
        value: checkboxContentType(coachingAudience, CoachAudienceData),
      },
      {
        key: 'coachingGroupSize',
        title: 'Can work with',
        value: checkboxContentType(coachingGroupSize, GroupSizeData),
      },
      {
        key: 'coachingLocation',
        title: 'Can work in',
        value: checkboxContentType(coachingLocation, CoachLocationData),
      },
      {
        key: 'biography',
        title: 'Biography',
        value: biography,
      },
      {
        key: 'rates',
        title: 'Average sessional rates',
        value: (
          <>
            {rateInputs.map((ri) => (
              <p key={ri.name}>
                {ri.label} - {ri.value ? `£${ri.value}` : 'N/A'}
              </p>
            ))}
          </>
        ),
      },
      {
        key: 'specialisms',
        title: 'Specialisms',
        value: (
          <>
            {checkboxContentType(
              specialistQualifications.filter((sq) => sq !== 'other'),
              SpecialismQualificationData
            )}
            {specialistQualifications.includes('other') && `Other - ${otherSpecialism}`}
          </>
        ),
      },
    ];

    if (isNotNullOrEmpty(website)) {
      sections.splice(4, 0, {
        key: 'website',
        title: 'Website',
        value: website,
      });
    }

    if (!hasQualifications && otherTrainingDetails) {
      sections.splice(8, 0, {
        key: 'otherTrainingDetails',
        title: 'Details of other training completed',
        value: otherTrainingDetails,
      });
    }

    return sections.map((section) => (
      <div className='mt-6 first:mt-0' key={section.key}>
        <p className='text-[15px] font-semibold'>{section.title}</p>
        <div>{section.value}</div>
      </div>
    ));
  };

  const approveRegistration = async () => {
    setApproving(true);
    try {
      await RegistrationApiService.approveCoach(details?.uid!);
      onClose();
      let messageText: string = '';
      switch (details?.status) {
        case CoachRegistrationStatus.AWAITING_REVIEW:
          messageText =
            details?.plan === CoachPlan.GROUND_BREAKER
              ? 'The coach was successfully approved, they will now receive an email with their login credentials attached.'
              : 'The coach was successfully approved, they will now receive an email with details of how they can pay for their membership';
          break;
        case CoachRegistrationStatus.AWAITING_PAYMENT:
          messageText =
            'The coach was successfully approved, they will now receive an email with their login credentials attached.';
      }
      message.success(messageText);
      setApproving(false);
    } catch (err) {
      setApproving(false);
      message.error('This coach could not be approved due to an error, please try again');
    }
  };

  const rejectRegistration = async () => {
    setRejecting(true);
    try {
      await RegistrationApiService.rejectCoach(details?.uid!);
      onClose();
      message.success('This coaches application was rejected, they have been notified via email');
      setRejecting(false);
    } catch (err) {
      setRejecting(false);
      message.error('This coach could not be rejected due to an error, please try again');
    }
  };

  return (
    <Drawer
      placement='right'
      size='large'
      onClose={onClose}
      open={isOpen}
      extra={
        <Space>
          <Button type='primary' onClick={approveRegistration} loading={approving} disabled={rejecting || approving}>
            {details?.status === CoachRegistrationStatus.AWAITING_REVIEW ? 'Approve' : 'Payment confirmed'}
          </Button>
          <Button onClick={rejectRegistration} danger loading={rejecting} disabled={rejecting || approving}>
            Reject
          </Button>
        </Space>
      }
    >
      {details ? <>{getSectionContent(details)}</> : <>Error</>}
    </Drawer>
  );
};

export default AdminCoachRegistrationDetail;
