import CiKLogo from 'assets/images/CiK_new.png';
import { NavbarOptions } from 'core/config/navbar-options';
import { useUserState } from 'core/providers/user-provider';
import SharedSidebarItem from './sidebar-item';
import { Drawer, Popover } from 'antd';
import { signOut } from 'firebase/auth';
import { auth } from 'core/config/firebase';
import { Dispatch, useCallback, useEffect, useState } from 'react';
import { CoachPlan, CoachPlanData } from 'core/constants/coach-plan';
import StorageApiService from 'core/api/storage/storage-api.service';
import SharedSpinner from 'shared/spinner/spinner';
import { AlertCircle, User } from 'react-feather';
import { AccountType } from 'core/constants/account-type';

interface ISharedSidebar {
  mobileOpen: boolean;
  setOpenMobileMenu: Dispatch<React.SetStateAction<boolean>>;
}

const SharedSidebar = ({ mobileOpen = false, setOpenMobileMenu }: ISharedSidebar) => {
  const { userData } = useUserState();
  const navItems = NavbarOptions.filter((item) =>
    item.allowedAccountTypes.some((accountType) => userData?.accountType === accountType)
  );
  const { coachData, schoolData } = useUserState();
  const [avatar, setAvatar] = useState<string>();
  const [avatarState, setAvatarState] = useState('loading');
  const paidPlan = coachData?.plan !== CoachPlan.GROUND_BREAKER;

  const getAvatar = useCallback(async (path: string) => {
    try {
      const downloadUrl = await StorageApiService.getFileDownloadUrl(path);
      setAvatar(downloadUrl);
      setAvatarState('success');
    } catch (error) {
      setAvatarState('error');
    }
  }, []);

  useEffect(() => {
    if (userData?.accountType === AccountType.COACH && coachData?.profileImagePath && paidPlan) {
      getAvatar(coachData?.profileImagePath);
    } else if (userData?.accountType === AccountType.SCHOOL && schoolData?.profilePhotoPath) {
      getAvatar(schoolData?.profilePhotoPath);
    } else {
      setAvatarState('unspecified');
    }
  }, [coachData?.profileImagePath, getAvatar, paidPlan, schoolData?.profilePhotoPath, userData?.accountType]);

  const onClose = () => {
    setOpenMobileMenu(false);
  };

  const UserPopoverContent = (
    <div className='w-[322px] md:w-[224px]'>
      <p className='hover:underline cursor-pointer' onClick={() => signOut(auth)}>
        Log out
      </p>
    </div>
  );

  const getAvatarSubtitle = () => {
    if (userData?.accountType === AccountType.ADMIN) {
      return 'CiK Admin';
    }

    if (userData?.accountType === AccountType.COACH) {
      const matchedPlan = CoachPlanData[coachData?.plan!];
      return matchedPlan ? matchedPlan.title : 'Unknown plan';
    }

    if (userData?.accountType === AccountType.SCHOOL) {
      return 'School';
    }

    return 'Unknown';
  };

  const sidebarContent = (
    <div className='flex flex-col h-full'>
      <div className='h-[60px] md:h-[70px] border-b border-white/10 flex items-center justify-between px-4'>
        <p className='alegreya text-cik_orange text-[20px]'>Coaches in Kind</p>
        <img src={CiKLogo} alt='cik_logo' className='max-h-[40px]' />
      </div>
      <div className='p-4 space-y-4 flex-grow overflow-y-auto'>
        {navItems.map((item) => (
          <SharedSidebarItem key={item.route} {...item} onClick={() => setOpenMobileMenu(false)} />
        ))}
      </div>
      <Popover trigger='click' content={UserPopoverContent} arrow={false} placement='top' title='Account'>
        <div className='p-4 border-t border-white/5 flex items-center hover:bg-white/10 transition ease-in-out cursor-pointer'>
          <div className='h-[42px] basis-[42px] flex-grow-0 flex-shrink-0 bg-gray-200 rounded-full mr-3 relative flex justify-center items-center overflow-hidden'>
            {avatarState === 'loading' && <SharedSpinner color='#FF875B' size={16} />}
            {avatarState === 'error' && <AlertCircle color='red' size={20} />}
            {avatarState === 'unspecified' && <User size={20} />}
            {avatarState === 'success' && <img src={avatar} alt='avatar' className='w-full' />}
          </div>
          <div className='text-white/90'>
            <p className='text-[16px] leading-tight'>{userData?.fullName}</p>
            <p className='text-[12px]'>{getAvatarSubtitle()}</p>
          </div>
        </div>
      </Popover>
    </div>
  );

  const drawerStyles = {
    content: {
      backgroundColor: '#272326',
    },
    body: {
      padding: 0,
    },
  };

  return (
    <>
      <div className='hidden md:block basis-[280px] flex-grow-0 flex-shrink-0 bg-[#272326] z-10'>{sidebarContent}</div>
      <div className='block md:hidden'>
        <Drawer styles={drawerStyles} placement='left' closable={false} onClose={onClose} open={mobileOpen}>
          {sidebarContent}
        </Drawer>
      </div>
    </>
  );
};

export default SharedSidebar;
