import { Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './login-page';
import RegisterPage from './register-page';
import ForgotPasswordPage from './forgot-password-page';

const AuthRouting = () => {
  return (
    <Routes>
      <Route path='login' element={<LoginPage />} />
      <Route path='register' element={<RegisterPage />} />
      <Route path='forgot-password' element={<ForgotPasswordPage />} />
      <Route path='*' element={<Navigate replace to='login' />} />
    </Routes>
  );
};

export default AuthRouting;
