import { AccountType } from 'core/constants/account-type';
import { Briefcase, Icon, MessageCircle, Search, Smile, User, Users } from 'react-feather';

export interface INavbarOption {
  NavIcon: Icon;
  label: string;
  route: string;
  allowedAccountTypes: string[];
}

export const NavbarOptions: INavbarOption[] = [
  {
    NavIcon: Users,
    label: 'Administrators',
    route: 'admin/users',
    allowedAccountTypes: [AccountType.ADMIN],
  },
  {
    NavIcon: Briefcase,
    label: 'Schools',
    route: 'admin/schools',
    allowedAccountTypes: [AccountType.ADMIN],
  },
  {
    NavIcon: Smile,
    label: 'Coaches',
    route: 'admin/coaches',
    allowedAccountTypes: [AccountType.ADMIN],
  },
  {
    NavIcon: MessageCircle,
    label: 'Messages',
    route: 'admin/messages',
    allowedAccountTypes: [AccountType.ADMIN],
  },
  {
    NavIcon: Search,
    label: 'Find a coach',
    route: 'find-a-coach',
    allowedAccountTypes: [AccountType.SCHOOL],
  },
  {
    NavIcon: Briefcase,
    label: 'Our school',
    route: 'our-school',
    allowedAccountTypes: [AccountType.SCHOOL],
  },
  {
    NavIcon: Search,
    label: 'School search',
    route: 'school-search',
    allowedAccountTypes: [AccountType.COACH],
  },
  {
    NavIcon: User,
    label: 'Profile',
    route: 'my-profile',
    allowedAccountTypes: [AccountType.COACH],
  },
  {
    NavIcon: MessageCircle,
    label: 'Message center',
    route: 'message-center',
    allowedAccountTypes: [AccountType.SCHOOL, AccountType.COACH],
  },
];
