import { useDialog } from 'core/providers/dialog-provider';
import { useState, useEffect } from 'react';
import SharedDialogBase from './dialog-base';
import { useUserState } from 'core/providers/user-provider';

import { getActionTimestampFromUser } from 'core/helpers/user-action.helpers';
import { App, Button } from 'antd';
import { assertIsError } from 'core/helpers/asset-is-error';
import { CoachPlanData } from 'core/constants/coach-plan';
import CoachApiService from 'core/api/coach/coach-api.service';
import SharedCoachEditPhotoVideo from 'shared/coach-profile/coach-edit-photo-video';

const CoachPaidOnboardingDialog = () => {
  const [dismissing, setDismissing] = useState(false);
  const dialog = useDialog();
  const { coachData, userData } = useUserState();
  const { message } = App.useApp();

  useEffect(() => {
    if (!coachData || coachData?.showPaidOnboarding === false) {
      dialog?.closeDialog();
    }
  }, [coachData, dialog]);

  const dismissDialog = async () => {
    try {
      setDismissing(true);
      await CoachApiService.update(coachData?.uid!, {
        showPaidOnboarding: false,
        updated: getActionTimestampFromUser(userData),
      });
    } catch (error) {
      setDismissing(false);
      const assertedError = assertIsError(error);
      message.error(assertedError.message);
    }
  };

  const customContent = () => {
    return (
      <div className='px-4 pt-4 overflow-y-auto'>
        <p className='text-sm mb-2'>Welcome to the Coaches in Kind Hub.</p>
        <p className='mb-8'>
          As you have opted for the {CoachPlanData[coachData?.plan!].title} plan, you can now complete your profile by
          uploading a profile photo and/or video
        </p>
        <SharedCoachEditPhotoVideo
          userData={userData}
          coachData={coachData!}
          extraButtons={[
            <Button key='notRightNow' loading={dismissing} onClick={dismissDialog}>
              Not right now
            </Button>,
          ]}
        />
      </div>
    );
  };

  return (
    <SharedDialogBase
      title='Complete your profile'
      customContentTemplate={!coachData ? <></> : customContent()}
      showButtons={false}
    />
  );
};

export default CoachPaidOnboardingDialog;
