import { Input, InputNumber, Button, Form, Flex } from 'antd';
import { useEffect, useState } from 'react';

import SharedAddressSearch from 'shared/address-search/address-search';
import { IAddressDao } from 'core/api/address/address-api-interface';
import { ICoachRegistrationChild } from './coach-registration';

const CoachRegistrationPersonalDetails = ({
  nextStep,
  save,
  existingDetails,
  previousStep,
}: ICoachRegistrationChild) => {
  const [form] = Form.useForm();
  const [address, setAddress] = useState<IAddressDao>();
  const [addressError, setAddressError] = useState<string>();

  useEffect(() => {
    if (existingDetails) {
      const { address, ...rest } = existingDetails;
      form.setFieldsValue(rest);
      setAddress(existingDetails.address);
    }
  }, [existingDetails, form]);

  const onFinish = async (data: any) => {
    if (!address) {
      setAddressError('You must select a valid address from the results list');
      return;
    }

    save({
      ...data,
      address,
    });
    nextStep();
  };

  return (
    <Form
      name='coach-registration-personal-details-form'
      form={form}
      onFinish={onFinish}
      layout='vertical'
      variant='filled'
      requiredMark='optional'
    >
      <Form.Item label='Full name' name='fullName' rules={[{ required: true, message: 'Please enter your name' }]}>
        <Input />
      </Form.Item>

      <Form.Item
        label='Email address'
        name='emailAddress'
        rules={[{ type: 'email', required: true, message: 'Please enter a valid email address' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='Phone number'
        name='phoneNumber'
        rules={[
          { required: true, message: 'Please enter your phone number' },
          {
            pattern: new RegExp(/^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/g),
            message: 'Please enter a valid phone number',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item label='Website' name='website'>
        <Input />
      </Form.Item>

      <Form.Item label='Address' required>
        <SharedAddressSearch onChange={setAddress} value={address} error={addressError} setError={setAddressError} />
      </Form.Item>

      <Form.Item
        label='Maximum travel distance from your postcode'
        name='maxTravel'
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <InputNumber style={{ width: '100%' }} suffix='miles' />
      </Form.Item>

      <Form.Item>
        <Flex gap='small' className='md:mt-4'>
          <Button type='primary' block onClick={() => previousStep()}>
            Previous
          </Button>
          <Button type='primary' htmlType='submit' block>
            Next
          </Button>
        </Flex>
      </Form.Item>
    </Form>
  );
};

export default CoachRegistrationPersonalDetails;
