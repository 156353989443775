import { App } from 'antd';
import CoachApiService from 'core/api/coach/coach-api.service';
import SchoolApiService from 'core/api/school/school-api.service';
import { auth } from 'core/config/firebase';
import { AccountType } from 'core/constants/account-type';
import { CoachPlan } from 'core/constants/coach-plan';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import { signOut } from 'firebase/auth';
import { useState } from 'react';
import SharedDialogBase, { ISharedDialogButton } from 'shared/dialog/dialog-base';

const CloseAccountDialog = () => {
  const { userData, coachData } = useUserState();
  const isPaidPlan = coachData && coachData.plan !== CoachPlan.GROUND_BREAKER;
  const dialog = useDialog();
  const [deleting, setDeleting] = useState(false);
  const { message } = App.useApp();

  const deleteAccount = async () => {
    setDeleting(true);
    try {
      if (userData?.accountType === AccountType.COACH) {
        await CoachApiService.deleteCoach(userData.attachedResourceUid!);
      } else if (userData?.accountType === AccountType.SCHOOL) {
        await SchoolApiService.deleteSchool(userData.attachedResourceUid!);
      }
      dialog?.closeDialog();
      signOut(auth);
    } catch (error) {
      message.error('Failed to close your account, please try again');
      setDeleting(false);
    }
  };

  const customContent = () => {
    return (
      <div className='p-4 overflow-y-auto'>
        <p>
          We are sorry to see you are leaving the CiK hub, once your account is closed, your profile and conversations
          will be erased. This action is irreversible, are you sure you want to continue?
        </p>
        {isPaidPlan && (
          <p className='mt-4'>
            Please remember to also cancel any subscriptions you have with us by clicking here -
            <a className='hover:underline' href='https://billing.stripe.com/p/login/fZe8zed5r4Ey6qI3cc' target='_blank' rel='noopener noreferrer'>
              https://billing.stripe.com/p/login/fZe8zed5r4Ey6qI3cc
            </a>
          </p>
        )}
      </div>
    );
  };

  const buttons: ISharedDialogButton[] = [
    { key: 'cancel', props: { title: 'Cancel', onClick: () => dialog?.closeDialog() } },
    {
      key: 'closeAccount',
      props: { title: 'Close account', danger: true, type: 'primary', onClick: deleteAccount, loading: deleting },
    },
  ];

  return (
    <SharedDialogBase title='Close your account' customContentTemplate={customContent()} customButtons={buttons} />
  );
};

export default CloseAccountDialog;
