import { IGetAddressAddress, IGetAddressSuggestions } from './address-api-interface';

const API_KEY = process.env.REACT_APP_GET_ADDRESS_KEY;

const autocomplete = async (term: string): Promise<IGetAddressSuggestions> => {
  try {
    const response = await fetch(`https://api.getAddress.io/autocomplete/${term}?api-key=${API_KEY}&all=true&top=20`);
    const json = await response.json();
    return json as IGetAddressSuggestions;
  } catch (error) {
    throw error;
  }
};

const getFullAddress = async (id: string): Promise<IGetAddressAddress> => {
  try {
    const response = await fetch(`https://api.getAddress.io//get/${id}?api-key=${API_KEY}`);
    const json = await response.json();
    return json as IGetAddressAddress;
  } catch (error) {
    throw error;
  }
};

const AddressApiService = {
  autocomplete,
  getFullAddress,
};

export default AddressApiService;
