import ApiService from '../api.service';
import { FirebaseFunctionName } from 'core/constants/firebase-function-name';
import { IGetFileSignedUrlRequestData } from './storage-api-interface';
import { getDownloadURL, ref, uploadBytes, uploadString } from 'firebase/storage';
import { storage } from 'core/config/firebase';

const getSignedFileUrl = async (expiration: number, filePath: string) => {
  return ApiService.callFunction<IGetFileSignedUrlRequestData, string>(
    { expiration, filePath },
    FirebaseFunctionName.GET_FILE_SIGNED_URL
  );
};

const uploadDataUrl = (filePath: string, dataUrl: string, contentType: string) => {
  const storageRef = ref(storage, filePath);
  const metadata = {
    contentType,
  };
  return uploadString(storageRef, dataUrl, 'data_url', metadata);
};

const uploadFile = (filePath: string, file: File | Blob, contentType: string) => {
  const storageRef = ref(storage, filePath);
  const metadata = {
    contentType,
  };
  return uploadBytes(storageRef, file, metadata);
};

const getFileDownloadUrl = (filePath: string) => {
  const storageRef = ref(storage, filePath);
  return getDownloadURL(storageRef);
};

const StorageApiService = {
  getSignedFileUrl,
  uploadDataUrl,
  uploadFile,
  getFileDownloadUrl,
};

export default StorageApiService;
