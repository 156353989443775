import { Navigate, Route, Routes } from 'react-router-dom';
import MyProfile from './my-profile';

const MyProfileRouting = () => {
  return (
    <Routes>
      <Route path='' element={<MyProfile />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default MyProfileRouting;
