import { QueryDocumentSnapshot, collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { IReviewDao } from './review-api.interface';
import { firestore } from 'core/config/firebase';
import { CollectionID } from 'core/constants/collection-id';

const collectionRef = collection(firestore, CollectionID.REVIEWS);

const getAllForCoach = (uid: string) => {
  const q = query(collectionRef, where('coachUid', '==', uid)).withConverter(converter());
  return getDocs(q);
};

const getForCoachFromReviewer = (coachUid: string, reviewerUid: string) => {
  const q = query(
    collectionRef,
    where('coachUid', '==', coachUid),
    where('created.by.uid', '==', reviewerUid)
  ).withConverter(converter());
  return getDocs(q);
};

const set = (dao: IReviewDao) => {
  const docRef = doc(firestore, CollectionID.REVIEWS, dao.uid);
  return setDoc(docRef, dao);
};

const converter = () => ({
  toFirestore: (dao: IReviewDao) => dao,
  fromFirestore: (snapshot: QueryDocumentSnapshot): IReviewDao => snapshot.data() as IReviewDao,
});

const ReviewApiService = {
  getAllForCoach,
  getForCoachFromReviewer,
  set,
};

export default ReviewApiService;
