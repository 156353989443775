export const SpecialismQualification = {
  TEACHING: 'teaching',
  SAFEGUARDING: 'safeguarding',
  TRAUMA_INFORMED_PRACTICE: 'traumaInformedPractice',
  NEURODIVERSITY: 'neurodiversity',
  SEND: 'send',
  VISUAL_IMPAIRMENTS: 'visualImpairments',
  HEARING_IMPAIRMENTS: 'hearingImpairments',
  PHYSICAL_IMPAIRMENTS: 'physicalImpairments',
  DISORDERED_EATING: 'disorderEating',
  SELF_HARM_AND_SUICIDAL_IDEATION: 'selfHarmAndSuicidalIdeation',
  COUNSELLING_OR_PSYCHOTHERAPY: 'counsellingOrPsychotherapy',
  CLINICAL_PSYCHOLOGY: 'clinicalPsychology',
  EDUCATIONAL_PSYCHOLOGY: 'educationalPsychology',
  MENTAL_HEALTH_NURSING: 'mentalHealthNursing',
};

export const SpecialismQualificationData = {
  [SpecialismQualification.TEACHING]: {
    value: SpecialismQualification.TEACHING,
    label: 'Teaching',
  },
  [SpecialismQualification.SAFEGUARDING]: {
    value: SpecialismQualification.SAFEGUARDING,
    label: 'Safeguarding',
  },
  [SpecialismQualification.NEURODIVERSITY]: {
    value: SpecialismQualification.NEURODIVERSITY,
    label: 'Trauma-informed practice',
  },
  [SpecialismQualification.SEND]: {
    value: SpecialismQualification.SEND,
    label: 'Neurodiversity',
  },
  [SpecialismQualification.VISUAL_IMPAIRMENTS]: {
    value: SpecialismQualification.VISUAL_IMPAIRMENTS,
    label: 'SEND',
  },
  [SpecialismQualification.HEARING_IMPAIRMENTS]: {
    value: SpecialismQualification.HEARING_IMPAIRMENTS,
    label: 'Visual impairments',
  },
  [SpecialismQualification.PHYSICAL_IMPAIRMENTS]: {
    value: SpecialismQualification.PHYSICAL_IMPAIRMENTS,
    label: 'Hearing impairments',
  },
  [SpecialismQualification.DISORDERED_EATING]: {
    value: SpecialismQualification.DISORDERED_EATING,
    label: 'Physical impairments',
  },
  [SpecialismQualification.SELF_HARM_AND_SUICIDAL_IDEATION]: {
    value: SpecialismQualification.SELF_HARM_AND_SUICIDAL_IDEATION,
    label: 'Disordered eating',
  },
  [SpecialismQualification.COUNSELLING_OR_PSYCHOTHERAPY]: {
    value: SpecialismQualification.COUNSELLING_OR_PSYCHOTHERAPY,
    label: 'Counselling or psychotherapy',
  },
  [SpecialismQualification.CLINICAL_PSYCHOLOGY]: {
    value: SpecialismQualification.CLINICAL_PSYCHOLOGY,
    label: 'Clinical psychology',
  },
  [SpecialismQualification.EDUCATIONAL_PSYCHOLOGY]: {
    value: SpecialismQualification.EDUCATIONAL_PSYCHOLOGY,
    label: 'Educational psychology',
  },
  [SpecialismQualification.MENTAL_HEALTH_NURSING]: {
    value: SpecialismQualification.MENTAL_HEALTH_NURSING,
    label: 'Mental health nursing',
  },
};
