import { App, Button, Checkbox, Form, Rate } from 'antd';
import { useForm } from 'antd/es/form/Form';
import ReviewApiService from 'core/api/review/review-api.service';
import { getActionTimestampFromUser } from 'core/helpers/user-action.helpers';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import { useState } from 'react';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { v4 as uuid } from 'uuid';

interface IReviewCoachDialog {
  coachUid: string;
}

const ReviewCoachDialog = ({ coachUid }: IReviewCoachDialog) => {
  const [form] = useForm();
  const dialog = useDialog();
  const [submitting, setSubmitting] = useState(false);
  const { message } = App.useApp();
  const [checked, setChecked] = useState(false);
  const { userData } = useUserState();

  const onFinish = async (data: any) => {
    try {
      setSubmitting(true);
      const actionTimestamp = getActionTimestampFromUser(userData);
      await ReviewApiService.set({
        coachUid: coachUid,
        rating: data.rating,
        uid: uuid(),
        created: actionTimestamp,
        updated: actionTimestamp,
      });
      dialog?.closeDialog();
      message.success('Coach review was successfully submitted');
    } catch (error) {
      message.error('Coach review submission failed, please try again');
      setSubmitting(false);
    }
  };
  const content = (
    <>
      <Checkbox className='mt-4 px-4' checked={checked} onChange={(e) => setChecked(e.target.checked)}>
        Please confirm that the first session was this coach was provided free of charge
      </Checkbox>
      <Form
        className='px-4 pt-4'
        name='review-coach-form'
        form={form}
        onFinish={onFinish}
        layout='vertical'
        variant='filled'
        requiredMark='optional'
        disabled={submitting}
      >
        <Form.Item
          label='Review score'
          name='rating'
          rules={[{ required: true, message: 'Please input a review score for the coach' }]}
        >
          <Rate />
        </Form.Item>

        <Form.Item>
          <div className='flex space-x-2 justify-end'>
            <Button disabled={submitting} onClick={() => dialog?.closeDialog()}>
              Cancel
            </Button>
            <Button disabled={!checked} type='primary' htmlType='submit' loading={submitting}>
              Submit
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
  return <SharedDialogBase title='Review coach' showButtons={false} customContentTemplate={content} />;
};

export default ReviewCoachDialog;
