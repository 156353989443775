import { Button, Segmented } from 'antd';
import { CoachPlan, CoachPlanData, CoachPlans } from 'core/constants/coach-plan';
import { useEffect, useState } from 'react';
import { ICoachRegistrationChild } from './coach-registration';

const CoachRegistrationEligibilityAndPlan = ({ nextStep, save, existingDetails }: ICoachRegistrationChild) => {
  const [qualified, setQualified] = useState<boolean>();
  const [selectedPlan, setSelectedPlan] = useState<string>(CoachPlan.GROUND_BREAKER);

  useEffect(() => {
    if (existingDetails) {
      setQualified(existingDetails.qualified);
      setSelectedPlan(existingDetails.plan ?? CoachPlan.GROUND_BREAKER);
    }
  }, [existingDetails]);

  const getCoachPlanData = () => {
    const coachPlan = CoachPlanData[selectedPlan];
    return (
      <div className='mt-6'>
        <p className='text-cik_red font-semibold mb-1'>{coachPlan.price.concatenated}</p>
        <p className='mb-2'>{coachPlan.commitment}</p>
        {coachPlan.benefits.map((benefit) => (
          <li key={benefit} className='text-sm'>
            {benefit}
          </li>
        ))}
      </div>
    );
  };
  return (
    <>
      <p className='mb-2'>Are you a qualified coach?</p>
      <div className='flex flex-col space-y-2 md:flex-row md:space-x-2 md:space-y-0'>
        <Button onClick={() => setQualified(true)} type={qualified ? 'primary' : 'default'}>
          Yes - I am a qualified coach
        </Button>

        <Button type={qualified === false ? 'primary' : 'default'} onClick={() => setQualified(false)}>
          No - I am a trainee coach
        </Button>
      </div>
      {qualified === false && (
        <div className='mt-6 bg-blue-200 border border-blue-400 rounded-lg p-3 text-gray-800'>
          <p>
            Coaches in Kind understand trainee coaches have a lot to offer schools. Please{' '}
            <span className='text-blue-500 hover:underline'>
              <a href='https://www.coachesinkind.com/trainee-coaches' target='_blank' rel='noopener noreferrer'>
                click here
              </a>
            </span>{' '}
            to follow our trainee coach pathway.
          </p>
        </div>
      )}
      {qualified === true && (
        <div className='mt-8'>
          <p>Select a plan</p>
          <p className='text-gray-600 mb-2'>
            For more information on our plans,{' '}
            <span className='text-blue-500 hover:underline'>
              <a href='https://www.coachesinkind.com/join' target='_blank' rel='noopener noreferrer'>
                click here
              </a>
            </span>
          </p>

          <div className='max-w-full overflow-y-auto'>
            <Segmented
              onChange={setSelectedPlan}
              options={CoachPlans.map((plan) => ({
                label: <p className='text-wrap leading-4 py-1.5'>{plan.title}</p>,
                value: plan.key,
              }))}
              value={selectedPlan}
            />
          </div>

          {getCoachPlanData()}

          <Button
            className='mt-10'
            type='primary'
            block
            onClick={() => {
              save({ plan: selectedPlan, qualified });
              nextStep();
            }}
          >
            Next
          </Button>
        </div>
      )}
    </>
  );
};

export default CoachRegistrationEligibilityAndPlan;
