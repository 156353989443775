import SharedPageHeader from 'shared/page-header/page-header';
import SharedPaginatedTable from 'shared/table/paginated-table';
import { collection, orderBy } from 'firebase/firestore';
import { firestore } from 'core/config/firebase';
import { CollectionID } from 'core/constants/collection-id';
import { ColumnType } from 'antd/es/table';
import { IActionTimestamp } from 'core/api/api.interface';
import dayjs from 'dayjs';
import { App, Button, Popconfirm } from 'antd';
import { useTable } from 'core/providers/table-data-provider';
import SchoolApiService from 'core/api/school/school-api.service';
import { ISchoolDao } from 'core/api/school/school-api.interface';
import { useState } from 'react';
import AdminSchoolDetail from './admin-school-detail';
import ConversationApiService from 'core/api/conversation/conversation-api.service';
import { useUserState } from 'core/providers/user-provider';
import { AccountType } from 'core/constants/account-type';
import { getActionTimestampFromUser } from 'core/helpers/user-action.helpers';
import { v4 as uuid } from 'uuid';
import { useNavigate } from 'react-router-dom';

const AdminSchoolsList = () => {
  const table = useTable('adminSchools');
  const { message } = App.useApp();
  const [viewSchoolDetails, setViewSchoolDetails] = useState<ISchoolDao>();
  const { userData } = useUserState();
  const navigate = useNavigate();
  const [messageButtonLoading, setMessageButtonLoading] = useState(false);

  const handleDelete = async (uid: string) => {
    try {
      await SchoolApiService.deleteSchool(uid);
      table.refreshTable();
    } catch (err) {
      message.error('This school could not be deleted, please check your permissions and try again');
    }
  };

  const messageSchool = async (record: ISchoolDao) => {
    if (!userData) {
      return;
    }
    setMessageButtonLoading(true);
    try {
      const snap = await ConversationApiService.getConversationBetweenParticipantAndAdmin(
        record.uid,
        userData.uid,
        AccountType.SCHOOL
      );
      if (snap.empty) {
        const actor = getActionTimestampFromUser(userData);
        const uid = uuid();
        await ConversationApiService.set({
          admin: userData,
          school: record,
          uid,
          created: actor,
          updated: actor,
          messageCount: 0,
          participants: [
            { uid: userData.uid, accountType: AccountType.ADMIN },
            { uid: record.uid, accountType: AccountType.SCHOOL },
          ],
        });
        navigate(`/admin/messages/conversation/${uid}`);
      } else {
        navigate(`/admin/messages/conversation/${snap.docs[0].id}`);
      }
      setMessageButtonLoading(false);
    } catch (error) {
      setMessageButtonLoading(false);
      message.error('Unable to go to conversation, please try again');
    }
  };

  const columns: ColumnType<ISchoolDao>[] = [
    {
      title: 'Name',
      dataIndex: 'schoolName',
      key: 'schoolName',
      width: 250,
    },
    {
      title: 'Main staff contact',
      dataIndex: 'mainStaffContact',
      key: 'schoolName',
    },
    {
      title: 'Email',
      dataIndex: 'emailAddress',
      key: 'emailAddress',
      width: 250,
    },
    {
      title: 'Last updated',
      dataIndex: 'updated',
      key: 'lastUpdated',
      render: (updated: IActionTimestamp) => (
        <p>{`${dayjs(updated.at.toDate()).format('DD/MM/YYYY HH:mm')} by ${updated.by.fullName}`}</p>
      ),
    },
    {
      key: 'actions',
      render: (_, record) => (
        <div className='flex space-x-2'>
          <Button
            onClick={() => {
              setViewSchoolDetails(record);
            }}
          >
            View
          </Button>
          <Button
            loading={messageButtonLoading}
            onClick={() => {
              messageSchool(record);
            }}
          >
            Message
          </Button>
          <Popconfirm
            icon={<></>}
            okButtonProps={{ danger: true }}
            placement='left'
            title='The school will be immediately removed from the CiK hub, this action is irreversible, are you sure you want to continue?'
            onConfirm={() => handleDelete(record.uid)}
          >
            <Button danger>Delete</Button>
          </Popconfirm>
        </div>
      ),
      width: '260px',
    },
  ];

  return (
    <>
      <SharedPageHeader title='Schools' />
      <AdminSchoolDetail
        details={viewSchoolDetails}
        isOpen={viewSchoolDetails !== undefined}
        onClose={() => setViewSchoolDetails(undefined)}
      />
      <div className='p-4'>
        <SharedPaginatedTable
          tableKey='adminSchools'
          collectionRef={collection(firestore, CollectionID.SCHOOLS)}
          queryConstraints={[]}
          queryOrder={orderBy('updated.at', 'desc')}
          errorMessage='The schools list could not be retrieved, please check your permissions and try again.'
          columns={columns}
          minWidth={1000}
        />
      </div>
    </>
  );
};

export default AdminSchoolsList;
