import { useEffect, useState } from 'react';
import AppRouting from './core/routing/app-routing';
import { UserProvider } from 'core/providers/user-provider';
import { App, ConfigProvider } from 'antd';
import { DialogProvider } from 'core/providers/dialog-provider';
import { TableDataProvider } from 'core/providers/table-data-provider';

function CiKApp() {
  const [screenHeight, setScreenHeight] = useState(`${window.innerHeight}px`);

  useEffect(() => {
    window.addEventListener('resize', () => setScreenHeight(`${window.innerHeight}px`));

    return () => {
      window.removeEventListener('resize', () => setScreenHeight(`${window.innerHeight}px`));
    };
  }, []);

  return (
    <App>
      <div style={{ minHeight: screenHeight }} className='relative flex flex-col bg-cik_gray/20'>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: 'Quicksand',
              colorPrimary: '#FF875B',
            },
            components: {
              Segmented: {
                itemSelectedBg: '#FF875B',
                itemSelectedColor: '#ffffff',
              },
            },
          }}
        >
          <TableDataProvider>
            <UserProvider>
              <DialogProvider>
                <AppRouting />
              </DialogProvider>
            </UserProvider>
          </TableDataProvider>
        </ConfigProvider>
      </div>
    </App>
  );
}

export default CiKApp;
