import { IConversationDao } from 'core/api/conversation/conversation-api.interface';
import ConversationHeader from './conversation-header';
import { IMessageDao } from 'core/api/messages/messages-api.interface';
import { useCallback, useEffect, useState } from 'react';
import { useUserState } from 'core/providers/user-provider';
import { AccountType } from 'core/constants/account-type';
import { Button } from 'antd';
import MessagesApiService from 'core/api/messages/messages-api.service';
import { v4 as uuid } from 'uuid';
import { getActionTimestampFromUser } from 'core/helpers/user-action.helpers';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { QueryConstraint, DocumentSnapshot, startAfter, limit } from 'firebase/firestore';
import ConversationInput from './conversation-input';

export interface IConversationParticipant {
  uid: string;
  name: string;
  avatarPath?: string;
  accountType: string;
  direction: string;
  isSelf: boolean;
  emailAddress: string;
}

interface ISharedConversationContainer {
  conversation: IConversationDao;
  messages: IMessageDao[];
  constraints: QueryConstraint[];
  firstMessage?: DocumentSnapshot;
  loadMoreMessages: (constraints: QueryConstraint[], startMessage: DocumentSnapshot) => Promise<void>;
}

const directions = ['left', 'right'];

const SharedConversationContainer = ({
  conversation,
  messages,
  constraints,
  firstMessage,
  loadMoreMessages,
}: ISharedConversationContainer) => {
  const { userData } = useUserState();
  const [isAdminViewing, setIsAdminViewing] = useState(false);
  const [participants, setParticipants] = useState<IConversationParticipant[]>();
  const [sendingMessage, setSendingMessage] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const configureParticipants = useCallback(() => {
    if (!userData) {
      return;
    }
    const adminViewing =
      userData.accountType === AccountType.ADMIN && !conversation.participants.some((cp) => cp.uid === userData.uid);

    const participants: IConversationParticipant[] = conversation.participants.map((cp, index) => {
      const isSelf = cp.uid === userData.attachedResourceUid || cp.uid === userData.uid;
      let direction: string;
      if (adminViewing) {
        direction = directions[index];
      } else {
        direction = isSelf ? 'left' : 'right';
      }
      switch (cp.accountType) {
        case AccountType.ADMIN: {
          const { uid, fullName, emailAddress } = conversation.admin!;
          return {
            uid,
            name: fullName,
            accountType: cp.accountType,
            isSelf,
            direction,
            emailAddress,
          };
        }
        case AccountType.COACH: {
          const { uid, fullName, profileImagePath, emailAddress } = conversation.coach!;
          return {
            uid,
            name: fullName,
            accountType: cp.accountType,
            isSelf,
            direction,
            avatarPath: profileImagePath,
            emailAddress,
          };
        }
        default: {
          const { uid, schoolName, profilePhotoPath, emailAddress } = conversation.school!;
          return {
            uid,
            name: schoolName,
            accountType: cp.accountType,
            isSelf,
            direction,
            avatarPath: profilePhotoPath,
            emailAddress,
          };
        }
      }
    });
    setIsAdminViewing(adminViewing);
    setParticipants(participants);
  }, [conversation.admin, conversation.coach, conversation.participants, conversation.school, userData]);

  useEffect(() => {
    configureParticipants();
  }, [configureParticipants]);

  const checkForMoreDocs = useCallback(async (baseConstraints: QueryConstraint[], firstDoc: DocumentSnapshot) => {
    const snap = await MessagesApiService.list([...baseConstraints, startAfter(firstDoc), limit(1)]);
    if (snap.empty) {
      setShowLoadMore(false);
    } else {
      setShowLoadMore(true);
    }
  }, []);

  useEffect(() => {
    if (constraints && firstMessage) {
      checkForMoreDocs(constraints, firstMessage);
    }
  }, [checkForMoreDocs, constraints, firstMessage]);

  const sendMessage = async (message?: string) => {
    setSendingMessage(true);
    const actor = getActionTimestampFromUser(userData);
    try {
      const recipient = participants?.find((cp) =>
        cp.accountType === AccountType.ADMIN ? cp.uid !== userData?.uid : cp.uid !== userData?.attachedResourceUid
      )!;

      await MessagesApiService.set({
        senderUid: userData?.accountType === AccountType.ADMIN ? userData.uid : userData?.attachedResourceUid!,
        content: message!,
        conversationUid: conversation.uid,
        participants: conversation.participants.map((cp) => cp.uid),
        uid: uuid(),
        created: actor,
        updated: actor,
        recipient: {
          uid: recipient.uid,
          emailAddress: recipient.emailAddress,
          name: recipient.name,
        },
      });
      setSendingMessage(false);
    } catch (error) {
      setSendingMessage(false);
    }
  };

  const getProcessedMessages = () => {
    return messages
      .sort((a, b) => b.created.at.seconds - a.created.at.seconds)
      .map((msg) => {
        const sender = participants?.find((cp) => cp.uid === msg.senderUid);
        const right = sender?.direction === 'right';
        return (
          <div key={msg.uid} className={clsx(right ? 'justify-start' : 'justify-end', 'flex w-full mt-6 last:mt-0')}>
            <div className='flex flex-col md:max-w-[60%]'>
              <div className={clsx(!right && 'flex-row-reverse', 'flex items-end mb-px')}>
                <p className='font-semibold text-[15px]'>{sender?.name}</p>
                <p className={clsx(right ? 'ml-2' : 'mr-2', 'text-[12px] text-gray-400')}>
                  {dayjs(msg.created.at.toDate()).format('DD/MM, HH:mm')}
                </p>
              </div>
              <div
                className={clsx(
                  right ? 'bg-gray-200 rounded-tl-none' : 'bg-blue-400 text-white rounded-tr-none self-end',
                  'p-2 px-3 rounded-xl w-fit whitespace-pre-wrap'
                )}
              >
                <p>{msg.content}</p>
              </div>
            </div>
          </div>
        );
      });
  };

  const triggerLoadMore = async () => {
    setLoadingMore(true);
    await loadMoreMessages(constraints, firstMessage!);
    setLoadingMore(false);
  };

  return (
    <div className='p-4' style={{ height: 'calc(100vh - 200px' }}>
      <div className='bg-white shadow-sm rounded-md w-full h-full overflow-hidden'>
        {!participants ? (
          <></>
        ) : (
          <div className='h-full flex flex-col'>
            <ConversationHeader
              isAdminViewing={isAdminViewing}
              participants={participants}
              conversation={conversation}
            />
            <div className='flex-grow overflow-y-auto p-4 flex flex-col-reverse'>
              {getProcessedMessages()}
              {showLoadMore && (
                <div className='flex justify-center'>
                  <Button onClick={triggerLoadMore} loading={loadingMore}>
                    Load more
                  </Button>
                </div>
              )}
            </div>
            {!isAdminViewing && <ConversationInput sendMessage={sendMessage} sending={sendingMessage} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default SharedConversationContainer;
