import { ReactElement, useCallback, useEffect, useState } from 'react';
import { App, Button, Drawer, Dropdown, Rate, Space, Switch } from 'antd';
import { CoachingTypeData } from 'core/constants/coach-type';
import { IDropdownMenuOnClickInfo, StringIndexedAnyValue } from 'core/helpers/interface.helpers';
import { CoachAudienceData } from 'core/constants/coach-audience';
import { GroupSizeData } from 'core/constants/group-size';
import { CoachLocationData } from 'core/constants/coach-location';
import StorageApiService from 'core/api/storage/storage-api.service';
import { AlertCircle, User } from 'react-feather';
import SharedSpinner from 'shared/spinner/spinner';
import { ICoachDao } from 'core/api/coach/coach-api.interface';
import { IReviewDao } from 'core/api/review/review-api.interface';
import ReviewApiService from 'core/api/review/review-api.service';
import { SpecialismQualificationData } from 'core/constants/specialism-qualification';
import { CoachPlan, CoachPlanData } from 'core/constants/coach-plan';
import { DownOutlined } from '@ant-design/icons';
import CoachApiService from 'core/api/coach/coach-api.service';
import { getActionTimestampFromUser } from 'core/helpers/user-action.helpers';
import { useUserState } from 'core/providers/user-provider';
import { useTable } from 'core/providers/table-data-provider';
import UsersApiService from 'core/api/user/user-api.service';
import { useDialog } from 'core/providers/dialog-provider';
import UserPasswordResetDialog from 'shared/dialog/user-password-reset-dialog';

interface IAdminCoachDetail {
  details?: ICoachDao;
  isOpen: boolean;
  onClose: () => void;
}

interface IAdminCoachDetailSection {
  key: string;
  title: string;
  value: string | undefined | ReactElement;
}

const AdminCoachDetail = ({ details, isOpen, onClose }: IAdminCoachDetail) => {
  const { message } = App.useApp();
  const [avatar, setAvatar] = useState<string>();
  const [avatarState, setAvatarState] = useState('loading');
  const [videoUrl, setVideoUrl] = useState<string>();
  const [reviews, setReviews] = useState<IReviewDao[]>();
  const [averageReviewScore, setAverageReviewScore] = useState<number>();
  const [updatingPlan, setUpdatingPlan] = useState(false);
  const [updatingRecommendation, setUpdatingRecommendation] = useState(false);
  const { userData } = useUserState();
  const [coach, setCoach] = useState(details);
  const paidPlan = coach?.plan !== CoachPlan.GROUND_BREAKER;
  const table = useTable('adminCoaches');
  const [resettingPassword, setResettingPassword] = useState(false);
  const dialog = useDialog();

  const getAvatar = useCallback(async (path: string) => {
    try {
      const downloadUrl = await StorageApiService.getFileDownloadUrl(path);
      setAvatar(downloadUrl);
      setAvatarState('success');
    } catch (error) {
      setAvatarState('error');
    }
  }, []);

  const getVideo = useCallback(
    async (path: string) => {
      try {
        const downloadUrl = await StorageApiService.getFileDownloadUrl(path);
        setVideoUrl(downloadUrl);
      } catch (error) {
        message.info('We tried fetching the profile video, but this was unsuccessful');
      }
    },
    [message]
  );

  const getReviews = useCallback(async () => {
    if (coach?.uid) {
      try {
        const snaps = await ReviewApiService.getAllForCoach(coach.uid);
        const reviews: IReviewDao[] = [];
        let total = 0;
        snaps.docs.forEach((doc) => {
          const data = doc.data();
          reviews.push(data);
          total += data.rating;
        });
        setReviews(snaps.docs.map((doc) => doc.data()));
        setAverageReviewScore(total / snaps.docs.length);
      } catch (error) {
        message.info('We tried fetching the profile reviews, but this was unsuccessful');
      }
    }
  }, [coach?.uid, message]);

  useEffect(() => {
    if (coach?.profileImagePath && paidPlan) {
      getAvatar(coach?.profileImagePath);
    } else {
      setAvatarState('unspecified');
    }
  }, [coach?.profileImagePath, getAvatar, paidPlan]);

  useEffect(() => {
    if (coach?.profileVideoPath && paidPlan) {
      getVideo(coach.profileVideoPath);
    }
  }, [coach?.profileVideoPath, getVideo, paidPlan]);

  useEffect(() => {
    getReviews();
  }, [getReviews]);

  const checkboxContentType = <T extends StringIndexedAnyValue>(checks: string[], dataObject: T) => (
    <>
      {checks.map((check) => (
        <p key={dataObject[check].value}>{dataObject[check].label ?? 'Unknown'}</p>
      ))}
    </>
  );

  const getSectionContent = ({
    fullName,
    emailAddress,
    phoneNumber,
    address,
    maxTravel,
    plan,
    coachingTypes,
    coachingAudience,
    coachingGroupSize,
    coachingLocation,
    biography,
    rateOnlineIndividual,
    rateOnlineGroup,
    rateInPersonIndividual,
    rateInPersonGroup,
    specialistQualifications,
    otherSpecialism,
    marketingAllowed,
  }: ICoachDao) => {
    const rateInputs = [
      {
        label: 'Online (Individual)',
        name: 'rateOnlineIndividual',
        value: rateOnlineIndividual,
      },
      {
        label: 'Online (Group – illustrate total for 12 people)',
        name: 'rateOnlineGroup',
        value: rateOnlineGroup,
      },
      {
        label: 'In-Person (Individual)',
        name: 'rateInPersonIndividual',
        value: rateInPersonIndividual,
      },
      {
        label: 'In-Person (Group – illustrate total for 12 people)',
        name: 'rateInPersonGroup',
        value: rateInPersonGroup,
      },
    ];

    const sections: IAdminCoachDetailSection[] = [
      {
        key: 'fullName',
        title: 'Coach name',
        value: fullName,
      },
      {
        key: 'plan',
        title: 'Plan',
        value: CoachPlanData[plan].title,
      },
      {
        key: 'about',
        title: 'About',
        value: biography,
      },
      {
        key: 'emailAddress',
        title: 'Email address',
        value: emailAddress,
      },
      {
        key: 'phoneNumber',
        title: 'Phone number',
        value: phoneNumber,
      },
      {
        key: 'address',
        title: 'Address',
        value: address.formattedAddress,
      },
      {
        key: 'maxTravel',
        title: 'Maximum travel distance from postcode',
        value: `${maxTravel} miles`,
      },
      {
        key: 'coachingTypes',
        title: 'Coaching types',
        value: checkboxContentType(coachingTypes, CoachingTypeData),
      },
      {
        key: 'coachingAudience',
        title: 'Age group',
        value: checkboxContentType(coachingAudience, CoachAudienceData),
      },
      {
        key: 'coachingGroupSize',
        title: 'Group size',
        value: checkboxContentType(coachingGroupSize, GroupSizeData),
      },
      {
        key: 'coachingLocation',
        title: 'Location of coaching',
        value: checkboxContentType(coachingLocation, CoachLocationData),
      },
      {
        key: 'specialisms',
        title: 'Specialisms:',
        value: (
          <>
            {checkboxContentType(
              specialistQualifications.filter((specialism) => specialism !== 'other'),
              SpecialismQualificationData
            )}
            {specialistQualifications.includes('other') && otherSpecialism && <li>{otherSpecialism}</li>}
          </>
        ),
      },
      {
        key: 'rates',
        title: 'Average sessional rates:',
        value: (
          <>
            {rateInputs.map((ri) => (
              <p key={ri.name}>
                {ri.label} - {ri.value ? `£${ri.value}` : 'N/A'}
              </p>
            ))}
          </>
        ),
      },
      {
        key: 'averageReview',
        title: 'Average review score',
        value: (
          <div className='flex items-center space-x-2'>
            {!reviews || reviews?.length === 0 ? (
              <p>No reviews yet</p>
            ) : (
              <>
                <Rate disabled defaultValue={averageReviewScore} />
                <p>
                  ({reviews.length} {`${reviews.length > 1 ? 'reviews' : 'review'}`})
                </p>
              </>
            )}
          </div>
        ),
      },
      {
        key: 'marketingAllowed',
        title: 'Marketing allowed',
        value: marketingAllowed ? 'Yes' : 'No',
      },
    ];

    if (avatar) {
      sections.splice(0, 0, {
        key: 'profilePhoto',
        title: 'Profile photo',
        value: (
          <div className='h-[140px] w-[140px] border-4 border-white bg-gray-200 rounded-full mr-3 relative flex justify-center items-center overflow-hidden'>
            {avatarState === 'loading' && <SharedSpinner color='#FF875B' size={32} />}
            {avatarState === 'error' && <AlertCircle color='red' size={32} />}
            {avatarState === 'unspecified' && <User size={48} className='text-gray-600' />}
            {avatarState === 'success' && <img src={avatar} alt='avatar' className='w-full' />}
          </div>
        ),
      });
    }

    if (videoUrl) {
      sections.splice(1, 0, {
        key: 'profileVideo',
        title: 'Profile video',
        value: (
          <video controls autoPlay>
            <source src={videoUrl} type='video/mp4' />
          </video>
        ),
      });
    }

    return sections.map((section) => (
      <div className='mt-6 first:mt-0' key={section.key}>
        <p className='text-[15px] font-semibold'>{section.title}</p>
        <div>{section.value}</div>
      </div>
    ));
  };

  const handleMenuClick = async ({ key }: IDropdownMenuOnClickInfo) => {
    if (!coach) {
      return;
    }
    try {
      setUpdatingPlan(true);
      await CoachApiService.update(coach?.uid, { updated: getActionTimestampFromUser(userData), plan: key });
      setCoach((prevState) => ({ ...prevState!, plan: key }));
      table.refreshTable();
      message.success('The coaches plan was updated');
      setUpdatingPlan(false);
    } catch (error) {
      setUpdatingPlan(false);
      message.error('Could not modify the plan for this coach, please try again.');
    }
  };

  const resetPassword = async () => {
    try {
      if (!coach) {
        return;
      }
      setResettingPassword(true);
      const newPassword = await UsersApiService.resetUserPassword(coach.userUid);
      dialog?.openDialog(<UserPasswordResetDialog temporaryPassword={newPassword} fullName={coach.fullName} />);
      setResettingPassword(false);
      onClose();
    } catch (error) {
      message.error('Could not reset the password for this coach, please try again.');
      setResettingPassword(false);
    }
  };

  const drawerActions = (
    <div className='flex space-x-5 items-center'>
      <div className='flex items-center space-x-1'>
        <p className='text-[14px]'>Recommended</p>
        <Switch
          loading={updatingRecommendation}
          checked={coach?.recommended}
          onChange={async (checked) => {
            if (!coach) {
              return;
            }
            try {
              setUpdatingRecommendation(true);
              await CoachApiService.update(coach?.uid, {
                updated: getActionTimestampFromUser(userData),
                recommended: checked,
              });
              setCoach((prevState) => ({ ...prevState!, recommended: checked }));
              table.refreshTable();
              message.success('The coaches recommended status was updated');
              setUpdatingRecommendation(false);
            } catch (error) {
              setUpdatingRecommendation(false);
              message.error('Could not modify the recommended status for this coach, please try again.');
            }
          }}
        />
      </div>
      <Dropdown
        trigger={['click']}
        menu={{
          items: Object.values(CoachPlanData)
            .filter((plan) => plan.key !== coach?.plan)
            .map((plan) => ({ key: plan.key, label: plan.title })),
          onClick: handleMenuClick,
        }}
      >
        <Button loading={updatingPlan}>
          <Space>
            Update plan
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
      <Button loading={resettingPassword} onClick={resetPassword}>
        Reset password
      </Button>
    </div>
  );

  return (
    <Drawer
      extra={coach ? drawerActions : undefined}
      placement='right'
      size='large'
      onClose={() => {
        setAvatar(undefined);
        setAvatarState('loading');
        setVideoUrl(undefined);
        onClose();
      }}
      open={isOpen}
    >
      {coach ? <>{getSectionContent(coach)}</> : <>Error</>}
    </Drawer>
  );
};

export default AdminCoachDetail;
