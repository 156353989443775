import { Navigate, Route, Routes } from 'react-router-dom';
import SchoolSearch from './school-search';
import SchoolSearchViewSchool from './school-search-view-school';

const SchoolSearchRouting = () => {
  return (
    <Routes>
      <Route path='' element={<SchoolSearch />} />
      <Route path=':uid' element={<SchoolSearchViewSchool />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default SchoolSearchRouting;
