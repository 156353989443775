import { App, Button, Form, Input } from 'antd';
import { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from 'core/config/firebase';
import { useNavigate } from 'react-router-dom';
import AuthWrapper from './auth-wrapper';

interface ILoginFormOutput {
  emailAddress: string;
  password: string;
}

const LoginPage = () => {
  const [form] = Form.useForm();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const { message } = App.useApp();
  const navigate = useNavigate();

  const onFinish = async (data: ILoginFormOutput) => {
    try {
      setFormSubmitting(true);
      await signInWithEmailAndPassword(auth, data.emailAddress, data.password);
    } catch (error: any) {
      setFormSubmitting(false);
      message.error('The email address or password you entered is incorrect');
    }
  };

  const cardContent = (
    <Form
      name='login-form'
      form={form}
      onFinish={onFinish}
      layout='vertical'
      variant='filled'
      requiredMark={false}
      disabled={formSubmitting}
    >
      <Form.Item
        label='Email address'
        name='emailAddress'
        rules={[{ required: true, message: 'Please enter your email address' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label='Password' name='password' rules={[{ required: true, message: 'Please enter your password' }]}>
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <p
          onClick={() => navigate('/auth/forgot-password')}
          className='quicksand text-sm font-semibold text-right text-gray-700 hover:underline hover:text-cik_orange transition ease-in-out cursor-pointer'
        >
          Forgot password?
        </p>
      </Form.Item>

      <Form.Item>
        <Button className='md:mt-4' type='primary' htmlType='submit' block loading={formSubmitting}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );

  const followingContent = (
    <>
      <p className='quicksand text-sm text-gray-700 mt-6'>New here?</p>
      <div className='flex space-x-4 items-stretch mt-2'>
        <p
          className='font-semibold hover:underline hover:text-cik_orange transition ease-in-out cursor-pointer'
          onClick={() => navigate('/auth/register?accountType=coach')}
        >
          Register as a coach
        </p>
        <div className='w-px bg-gray-300' />
        <p
          className='font-semibold hover:underline hover:text-cik_orange transition ease-in-out cursor-pointer'
          onClick={() => navigate('/auth/register?accountType=school')}
        >
          Register as a school
        </p>
      </div>
    </>
  );

  return <AuthWrapper title='Welcome back' cardContent={cardContent} followingContent={followingContent} />;
};

export default LoginPage;
