import { INavbarOption, NavbarOptions } from 'core/config/navbar-options';
import { useUserState } from 'core/providers/user-provider';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

interface IAccountTypeGuard {
  allowedAccountTypes: string[];
  navList?: INavbarOption[];
}

const AccountTypeGuard = ({ allowedAccountTypes, navList = NavbarOptions }: IAccountTypeGuard) => {
  const { userData } = useUserState();
  const userAccountType = userData?.accountType;
  const location = useLocation();

  if (!userAccountType || !allowedAccountTypes.includes(userAccountType)) {
    const allowedRoute = navList.find((item) =>
      item.allowedAccountTypes.some((accountType) => userAccountType === accountType)
    )?.route;
    return <Navigate to={allowedRoute ? `/${allowedRoute}` : ''} state={{ from: location }} replace />;
  }

  return <Outlet />;
};
export default AccountTypeGuard;
