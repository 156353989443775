import { App, Button, Form, Space, Upload, UploadProps } from 'antd';
import { ICoachDao } from 'core/api/coach/coach-api.interface';
import CoachApiService from 'core/api/coach/coach-api.service';
import StorageApiService from 'core/api/storage/storage-api.service';
import { IUserDao } from 'core/api/user/user-api.interface';
import { assertIsError } from 'core/helpers/asset-is-error';
import { FileType } from 'core/helpers/interface.helpers';
import { getActionTimestampFromUser } from 'core/helpers/user-action.helpers';
import { UpdateData } from 'firebase/firestore';
import { Fragment, useState } from 'react';

interface ISharedCoachEditPhotoVideo {
  userData?: IUserDao;
  coachData: ICoachDao;
  extraButtons?: JSX.Element[];
}

const SharedCoachEditPhotoVideo = ({ userData, coachData, extraButtons }: ISharedCoachEditPhotoVideo) => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const [photoImageUrl, setPhotoImageUrl] = useState<string>();
  const [photoImageFileList, setPhotoImageFileList] = useState<FileType[]>([]);
  const [videoFileList, setVideoFileList] = useState<FileType[]>([]);

  const onFinish = async (data: any) => {
    try {
      setFormSubmitting(true);

      try {
        let updatePayload: UpdateData<ICoachDao> = {};

        if (photoImageFileList.length > 0) {
          const photo = photoImageFileList[0];
          const photoPath = `coach-profile/${coachData?.uid}/${photo.name}`;
          await StorageApiService.uploadFile(photoPath, photo, photo.type ?? 'image/jpeg');
          updatePayload.profileImagePath = photoPath;
        }

        if (videoFileList.length > 0) {
          const video = videoFileList[0];
          const videoPath = `coach-profile/${coachData?.uid}/${video.name}`;
          await StorageApiService.uploadFile(videoPath, video, video.type ?? 'video/mp4');
          updatePayload.profileVideoPath = videoPath;
        }

        if (updatePayload.profileImagePath || updatePayload.profileVideoPath) {
          await CoachApiService.update(coachData?.uid!, {
            ...updatePayload,
            updated: getActionTimestampFromUser(userData),
            showPaidOnboarding: false,
          });
        } else {
          message.error('Please upload a photo or a video.');
        }

        setFormSubmitting(false);
      } catch (error) {
        throw new Error('An error has occurred, if this persists, contact your administrator.');
      }
    } catch (error) {
      setFormSubmitting(false);
      const assertedError = assertIsError(error);
      message.error(assertedError.message);
    }
  };

  const photoProps: UploadProps = {
    beforeUpload: (file: FileType) => {
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        message.info("We're sorry, but only jpg or png is supported");
        return;
      }

      if (!file.size || file.size > 2000000) {
        message.info("We're sorry, but files must be under 2MB");
        return;
      }
      setPhotoImageFileList([file]);
      const reader = new FileReader();
      reader.onload = () => setPhotoImageUrl(reader.result as string);
      reader.readAsDataURL(file);
      return false;
    },
    showUploadList: false,
    listType: 'picture-circle',
    maxCount: 1,
    fileList: photoImageFileList,
  };

  const videoProps: UploadProps = {
    beforeUpload: (file: FileType) => {
      if (file.type !== 'video/mp4') {
        message.info("We're sorry, but only mp4 is supported");
        return;
      }

      if (!file.size || file.size > 10000000) {
        message.info("We're sorry, but files must be under 10MB");
        return;
      }
      setVideoFileList([file]);
      return false;
    },
    maxCount: 1,
    fileList: videoFileList,
  };

  return (
    <Form
      name='complete-profile-form'
      form={form}
      onFinish={onFinish}
      layout='vertical'
      variant='filled'
      requiredMark={false}
      disabled={formSubmitting}
    >
      <Form.Item label='Profile photo' name='profilePhoto'>
        <Upload {...photoProps}>
          {photoImageUrl ? (
            <div className='h-[90px] w-[90px] flex items-center justify-center rounded-full overflow-hidden'>
              <img src={photoImageUrl} alt='avatar' />
            </div>
          ) : (
            <button style={{ border: 0, background: 'none' }} type='button'>
              <div style={{ marginTop: 8 }}>Upload</div>
            </button>
          )}
        </Upload>
      </Form.Item>

      <Form.Item label='Profile video' name='profileVideo'>
        <Upload {...videoProps}>
          <Button>Click to Upload</Button>
        </Upload>
      </Form.Item>

      <Form.Item className='flex justify-end'>
        <Space className='md:mt-4'>
          {extraButtons?.map((button) => (
            <Fragment key={button.key}>{button}</Fragment>
          ))}
          <Button type='primary' htmlType='submit' loading={formSubmitting}>
            Save
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default SharedCoachEditPhotoVideo;
