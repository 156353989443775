import { onAuthStateChanged, Unsubscribe, User } from 'firebase/auth';
import { createContext, useContext, useEffect, useState } from 'react';
import { auth } from 'core/config/firebase';
import UsersApiService from 'core/api/user/user-api.service';
import { IUserDao } from 'core/api/user/user-api.interface';
import { AccountType } from 'core/constants/account-type';
import CoachApiService from 'core/api/coach/coach-api.service';
import { ICoachDao } from 'core/api/coach/coach-api.interface';
import { App } from 'antd';
import { ISchoolDao } from 'core/api/school/school-api.interface';
import SchoolApiService from 'core/api/school/school-api.service';

interface IUserContext {
  user: User | null;
  error?: Error;
  authChecked: boolean;
  userData?: IUserDao;
  coachData?: ICoachDao;
  schoolData?: ISchoolDao;
}

export const UserContext = createContext<IUserContext | null>(null);

export const useUserState = () => {
  const state = useContext(UserContext);
  return { ...state, isAuthenticated: state?.user !== null };
};

export const UserProvider = ({ children }: any) => {
  const { message } = App.useApp();
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<Error>();
  const [authChecked, setAuthChecked] = useState<boolean>(false);
  const [userData, setUserData] = useState<IUserDao>();
  const [coachData, setCoachData] = useState<ICoachDao>();
  const [schoolData, setSchoolData] = useState<ISchoolDao>();

  useEffect(() => {
    const unsubscribeFromAuth = onAuthStateChanged(
      auth,
      async (user) => {
        setUser(user);
        setTimeout(() => {
          setAuthChecked(true);
        }, 1000);
      },
      setError
    );
    return () => {
      unsubscribeFromAuth();
    };
  }, []);

  useEffect(() => {
    let unsubscribeFromUserDoc: Unsubscribe;
    if (user) {
      unsubscribeFromUserDoc = UsersApiService.subscribeToUser(
        user.uid,
        (snap) => setUserData(snap.data()),
        (error) => {
          message.error('A critical application error occurred, please refresh and try again');
          setUserData(undefined);
        }
      );
    } else {
      setUserData(undefined);
    }

    return () => {
      if (unsubscribeFromUserDoc) {
        unsubscribeFromUserDoc();
      }
    };
  }, [message, user]);

  useEffect(() => {
    let unsubscribeFromCoachDoc: Unsubscribe;
    let unsubscribeFromSchoolDoc: Unsubscribe;

    if (userData?.accountType === AccountType.COACH && userData.attachedResourceUid) {
      unsubscribeFromCoachDoc = CoachApiService.subscribeToDoc(
        userData.attachedResourceUid,
        (snap) => setCoachData(snap.data()),
        (error) => {
          message.error('A critical application error occurred, please refresh and try again');
          setCoachData(undefined);
        }
      );
    } else {
      setCoachData(undefined);
    }

    if (userData?.accountType === AccountType.SCHOOL && userData.attachedResourceUid) {
      unsubscribeFromSchoolDoc = SchoolApiService.subscribeToDoc(
        userData.attachedResourceUid,
        (snap) => setSchoolData(snap.data()),
        (error) => {
          message.error('A critical application error occurred, please refresh and try again');
          setSchoolData(undefined);
        }
      );
    } else {
      setSchoolData(undefined);
    }

    return () => {
      if (unsubscribeFromCoachDoc) {
        unsubscribeFromCoachDoc();
      }

      if (unsubscribeFromSchoolDoc) {
        unsubscribeFromSchoolDoc();
      }
    };
  }, [message, userData?.accountType, userData?.attachedResourceUid]);

  return (
    <UserContext.Provider value={{ user, error, authChecked, userData, coachData, schoolData }}>
      {children}
    </UserContext.Provider>
  );
};
