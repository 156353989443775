import { ICoachDao } from 'core/api/coach/coach-api.interface';
import { useDialog } from 'core/providers/dialog-provider';
import { useCallback, useEffect, useState } from 'react';
import { AlertCircle, User } from 'react-feather';
import SharedSpinner from 'shared/spinner/spinner';
import { PlayCircleOutlined } from '@ant-design/icons';
import { App, Button, Rate, Tag, Tooltip } from 'antd';
import StorageApiService from 'core/api/storage/storage-api.service';
import { CoachingTypeData } from 'core/constants/coach-type';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { CoachAudienceData } from 'core/constants/coach-audience';
import { CoachLocationData } from 'core/constants/coach-location';
import { GroupSizeData } from 'core/constants/group-size';
import { SpecialismQualificationData } from 'core/constants/specialism-qualification';
import { CoachPlan } from 'core/constants/coach-plan';
import Recommended from 'assets/images/recommended_badge.png';
import Sponsor from 'assets/images/sponsor_badge.png';
import ReviewApiService from 'core/api/review/review-api.service';
import { IReviewDao } from 'core/api/review/review-api.interface';

interface ISharedCoachProfile {
  coachData: ICoachDao;
}

const SharedCoachProfile = ({ coachData }: ISharedCoachProfile) => {
  const [avatar, setAvatar] = useState<string>();
  const [avatarState, setAvatarState] = useState('loading');
  const [videoUrl, setVideoUrl] = useState<string>();
  const dialog = useDialog();
  const { message } = App.useApp();
  const [reviews, setReviews] = useState<IReviewDao[]>();
  const [averageReviewScore, setAverageReviewScore] = useState<number>();
  const paidPlan = coachData.plan !== CoachPlan.GROUND_BREAKER;

  const getAvatar = useCallback(async (path: string) => {
    try {
      const downloadUrl = await StorageApiService.getFileDownloadUrl(path);
      setAvatar(downloadUrl);
      setAvatarState('success');
    } catch (error) {
      setAvatarState('error');
    }
  }, []);

  const getVideo = useCallback(
    async (path: string) => {
      try {
        const downloadUrl = await StorageApiService.getFileDownloadUrl(path);
        setVideoUrl(downloadUrl);
      } catch (error) {
        message.info('We tried fetching the profile video, but this was unsuccessful');
      }
    },
    [message]
  );

  const getReviews = useCallback(async () => {
    try {
      const snaps = await ReviewApiService.getAllForCoach(coachData.uid);
      const reviews: IReviewDao[] = [];
      let total = 0;
      snaps.docs.forEach((doc) => {
        const data = doc.data();
        reviews.push(data);
        total += data.rating;
      });
      setReviews(snaps.docs.map((doc) => doc.data()));
      setAverageReviewScore(total / snaps.docs.length);
    } catch (error) {
      message.info('We tried fetching the profile reviews, but this was unsuccessful');
    }
  }, [coachData.uid, message]);

  useEffect(() => {
    if (coachData.profileImagePath && paidPlan) {
      getAvatar(coachData?.profileImagePath);
    } else {
      setAvatarState('unspecified');
    }
  }, [coachData.profileImagePath, getAvatar, paidPlan]);

  useEffect(() => {
    if (coachData?.profileVideoPath && paidPlan) {
      getVideo(coachData.profileVideoPath);
    }
  }, [coachData.profileVideoPath, getVideo, paidPlan]);

  useEffect(() => {
    getReviews();
  }, [getReviews]);

  const openVideo = () => {
    dialog?.openDialog(
      <SharedDialogBase
        title='Profile video'
        customContentTemplate={
          <video controls autoPlay>
            <source src={videoUrl} type='video/mp4' />
          </video>
        }
      />
    );
  };

  const getBulletContent = (bullets: string[], data: any) => {
    return (
      <ul className='list-disc ml-4'>
        {bullets?.map((key, index) => {
          const match = data[key];
          if (match) {
            return <li key={match.value}>{match.label}</li>;
          } else {
            return <li key={`unknown${key}`}>Unknown</li>;
          }
        })}
      </ul>
    );
  };

  const getContentSections = (coachData: ICoachDao) => {
    const rateInputs = [
      {
        label: 'Online (Individual)',
        name: 'rateOnlineIndividual',
        value: coachData.rateOnlineIndividual,
      },
      {
        label: 'Online (Group – illustrate total for 12 people)',
        name: 'rateOnlineGroup',
        value: coachData.rateOnlineGroup,
      },
      {
        label: 'In-Person (Individual)',
        name: 'rateInPersonIndividual',
        value: coachData.rateInPersonIndividual,
      },
      {
        label: 'In-Person (Group – illustrate total for 12 people)',
        name: 'rateInPersonGroup',
        value: coachData.rateInPersonGroup,
      },
    ];

    return [
      {
        key: 'about',
        title: 'About:',
        content: <p>{coachData.biography}</p>,
      },
      {
        key: 'audience',
        title: 'I can coach:',
        content: getBulletContent(coachData.coachingAudience, CoachAudienceData),
      },
      {
        key: 'group',
        title: 'I can work with:',
        content: getBulletContent(coachData.coachingGroupSize, GroupSizeData),
      },
      {
        key: 'location',
        title: 'I can work in:',
        content: getBulletContent(coachData.coachingLocation, CoachLocationData),
      },
      {
        key: 'specialisms',
        title: 'Specialisms:',
        content: (
          <>
            {getBulletContent(
              coachData.specialistQualifications.filter((specialism) => specialism !== 'other'),
              SpecialismQualificationData
            )}
            {coachData.specialistQualifications.includes('other') && coachData.otherSpecialism && (
              <li>{coachData.otherSpecialism}</li>
            )}
          </>
        ),
      },
      {
        key: 'rates',
        title: 'Average sessional rates:',
        content: rateInputs.map((ri) => (
          <p key={ri.name}>
            {ri.label} - {ri.value ? `£${ri.value}` : 'N/A'}
          </p>
        )),
      },
      {
        key: 'averageReview',
        title: 'Average review score',
        content: (
          <div className='flex items-center space-x-2'>
            {!reviews || reviews?.length === 0 ? (
              <p>No reviews yet</p>
            ) : (
              <>
                <Rate disabled defaultValue={averageReviewScore} />
                <p>
                  ({reviews.length} {`${reviews.length > 1 ? 'reviews' : 'review'}`})
                </p>
              </>
            )}
          </div>
        ),
      },
    ];
  };

  const getBadges = () => {
    const recommended = (
      <Tooltip title='Recommended' color='#FF875B'>
        <img src={Recommended} alt='recommended' className='max-h-[28px]' />
      </Tooltip>
    );
    const sponsor = (
      <Tooltip title='Coaching sponsor 2024/25' color='#DA004D'>
        <img src={Sponsor} alt='sponsor' className='max-h-[28px]' />
      </Tooltip>
    );

    return (
      <div className='flex items-center space-x-4'>
        {coachData.recommended && recommended}
        {coachData.plan === CoachPlan.GIFT_BEARER && sponsor}
      </div>
    );
  };
  return (
    <div className='bg-white shadow-sm rounded-md overflow-hidden'>
      <div className='w-full h-[160px] bg-cik_orange' />
      <div className='px-4 pb-4 -mt-[80px]'>
        <div className='h-[140px] w-[140px] border-4 border-white bg-gray-200 rounded-full mr-3 relative flex justify-center items-center overflow-hidden'>
          {avatarState === 'loading' && <SharedSpinner color='#FF875B' size={32} />}
          {avatarState === 'error' && <AlertCircle color='red' size={32} />}
          {avatarState === 'unspecified' && <User size={48} className='text-gray-600' />}
          {avatarState === 'success' && <img src={avatar} alt='avatar' className='w-full' />}
        </div>

        <div className='flex space-x-6 items-center my-3'>
          <p className='text-xl font-semibold'>{coachData.fullName}</p>
          {getBadges()}
        </div>

        <div className='flex'>
          {coachData.coachingTypes.map((type) => {
            const match = CoachingTypeData[type];
            return (
              <Tooltip key={match.value} title={match.description}>
                <Tag>{match.altLabel}</Tag>
              </Tooltip>
            );
          })}
          {coachData.profileVideoPath && (
            <Button type='dashed' icon={<PlayCircleOutlined />} size='small' onClick={openVideo}>
              Video
            </Button>
          )}
        </div>

        <div>
          {getContentSections(coachData).map((section) => (
            <div key={section.key} className='mt-4 border-t border-dashed pt-4 first:border-0'>
              <p className='font-semibold'>{section.title}</p>
              <>{section.content}</>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SharedCoachProfile;
