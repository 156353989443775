import { App, Button, Form, Switch } from 'antd';
import CoachApiService from 'core/api/coach/coach-api.service';
import SchoolApiService from 'core/api/school/school-api.service';
import { AccountType } from 'core/constants/account-type';
import { getActionTimestampFromUser } from 'core/helpers/user-action.helpers';
import { useUserState } from 'core/providers/user-provider';
import { useEffect, useState } from 'react';

interface ISharedEditMarketingPreferences {
  closeDrawer: () => void;
}

const SharedEditMarketingPreferences = ({ closeDrawer }: ISharedEditMarketingPreferences) => {
  const [form] = Form.useForm();
  const { coachData, userData, schoolData } = useUserState();
  const isCoach = userData?.accountType === AccountType.COACH;
  const [submitting, setSubmitting] = useState(false);
  const { message } = App.useApp();

  useEffect(() => {
    if (isCoach && coachData) {
      form.setFieldsValue(coachData);
    } else if (schoolData) {
      form.setFieldsValue(schoolData);
    }
  }, [coachData, form, isCoach, schoolData]);

  const onFinish = async (data: any) => {
    const { marketingAllowed } = data;
    try {
      setSubmitting(true);
      if (isCoach) {
        await CoachApiService.update(coachData?.uid!, {
          marketingAllowed,
          updated: getActionTimestampFromUser(userData),
        });
      } else {
        await SchoolApiService.update(schoolData?.uid!, {
          marketingAllowed,
          updated: getActionTimestampFromUser(userData),
        });
      }
      closeDrawer();
    } catch (error) {
      message.error('There was an error updating your marketing preferences, please try again');
      setSubmitting(false);
    }
  };

  return (
    <Form
      name='marketing-preferences-form'
      form={form}
      onFinish={onFinish}
      layout='vertical'
      variant='filled'
      requiredMark={false}
      disabled={submitting}
    >
      <Form.Item
        extra='With this enabled, you will receive tailored messages from the CiK team, directly to your message center inbox'
        label='Marketing messages'
        name='marketingAllowed'
        valuePropName='checked'
      >
        <Switch />
      </Form.Item>

      <Form.Item>
        <Button type='primary' htmlType='submit' block loading={submitting}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SharedEditMarketingPreferences;
