import { Drawer } from 'antd';
import { useState } from 'react';
import SharedEditProfilePhoto from 'shared/profile/edit-profile-photo';
import EditSchoolCoachingNeeds from './edit-school-coaching-needs';
import EditSchoolDetails from './edit-school-details';
import SharedEditMarketingPreferences from 'shared/marketing-preferences/edit-marketing-preferences';

interface IEditSchoolDrawer {
  isOpen: boolean;
  onClose: () => void;
}

interface IEditOption {
  key: string;
  label: string;
  content: JSX.Element;
}

const EditSchoolDrawer = ({ isOpen, onClose }: IEditSchoolDrawer) => {
  const [selectedOption, setSelectedOption] = useState<IEditOption>();
  const onChildClose = () => setSelectedOption(undefined);

  const options = [
    {
      key: 'avatar',
      label: 'Profile photo',
      content: (
        <div>
          <SharedEditProfilePhoto accountType='school' closeDrawer={onChildClose} />
        </div>
      ),
    },
    {
      key: 'details',
      label: 'School details',
      content: (
        <div>
          <EditSchoolDetails closeDrawer={onChildClose} />
        </div>
      ),
    },
    {
      key: 'coachingNeeds',
      label: 'Coaching needs',
      content: (
        <div>
          <EditSchoolCoachingNeeds closeDrawer={onChildClose} />
        </div>
      ),
    },
    {
      key: 'marketingPreferences',
      label: 'Marketing preferences',
      content: (
        <div>
          <SharedEditMarketingPreferences closeDrawer={onChildClose} />
        </div>
      ),
    },
  ];

  return (
    <Drawer
      styles={{ body: { padding: 0 } }}
      placement='right'
      size='default'
      title='Edit profile'
      onClose={onClose}
      open={isOpen}
    >
      <Drawer width={450} title={selectedOption?.label} onClose={onChildClose} open={selectedOption !== undefined}>
        {selectedOption?.content}
      </Drawer>
      {options.map((option) => (
        <div
          className='border-b px-6 p-4 hover:bg-gray-50 cursor-pointer transition ease-in-out'
          key={option.key}
          onClick={() => setSelectedOption(option)}
        >
          {option.label}
        </div>
      ))}
    </Drawer>
  );
};

export default EditSchoolDrawer;
