import { App, Select, Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import { ISchoolDao } from 'core/api/school/school-api.interface';
import SchoolApiService from 'core/api/school/school-api.service';
import { CoachAudienceData } from 'core/constants/coach-audience';
import { CoachLocationData } from 'core/constants/coach-location';
import { CoachingTypeData } from 'core/constants/coach-type';
import { GroupSizeData } from 'core/constants/group-size';
import { useUserState } from 'core/providers/user-provider';
import { where } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { ParamKeyValuePair, useSearchParams } from 'react-router-dom';
import SharedPageHeader from 'shared/page-header/page-header';
import SchoolSearchTableRow from './school-search-table-row';

interface ISchoolSearchFilters {
  [key: string]: undefined | string[];
  coachingTypes?: string[];
  coachingAudience?: string[];
  coachingGroupSize?: string[];
  coachingLocation?: string[];
}

const SchoolSearch = () => {
  const { coachData } = useUserState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterValues, setFilterValues] = useState<ISchoolSearchFilters>({});
  const { message } = App.useApp();
  const [schools, setSchools] = useState<ISchoolDao[]>();
  const [filteredSchools, setFilteredSchools] = useState<ISchoolDao[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setFilterValues({});
    searchParams.forEach((value, key) => {
      setFilterValues((prevState) => {
        return { ...prevState, [key]: value.split(',') };
      });
    });
  }, [searchParams]);

  const fetchSchoolsInRange = useCallback(
    async (schoolIds: string[]) => {
      setLoading(true);
      setSchools([]);
      const schoolGroups = [];
      while (schoolIds.length) {
        schoolGroups.push(schoolIds.splice(0, 30));
      }
      try {
        const promises = schoolGroups.map((group) => SchoolApiService.list([where('uid', 'in', group)]));
        const result = await Promise.all(promises);
        const list: ISchoolDao[] = [];
        result.forEach((snap) => {
          list.push(...snap.docs.map((doc) => doc.data()));
        });
        setSchools(list);
        setLoading(false);
      } catch (error) {
        message.error('Failed to perform school search, please try again');
        setLoading(false);
      }
    },
    [message]
  );

  useEffect(() => {
    fetchSchoolsInRange([...(coachData?.schoolsInRange ?? [])]);
  }, [coachData?.schoolsInRange, fetchSchoolsInRange]);

  useEffect(() => {
    if (schools) {
      const { coachingAudience, coachingGroupSize, coachingLocation, coachingTypes } = filterValues;
      const filtered = schools.filter((school) => {
        const { coachingAudience: ca, coachingGroupSize: cg, coachingLocation: cl, coachingTypes: ct } = school;

        const checks = [
          {
            inputs: coachingAudience,
            values: ca,
          },
          {
            inputs: coachingGroupSize,
            values: cg,
          },
          {
            inputs: coachingLocation,
            values: cl,
          },
          {
            inputs: coachingTypes,
            values: ct,
          },
        ];

        return checks.every(
          (check) => check.inputs === undefined || check.inputs.every((input) => check.values.includes(input))
        );
      });
      setFilteredSchools(filtered);
    }
  }, [schools, filterValues]);

  const checkboxSections = [
    {
      label: 'Type of coach',
      name: 'coachingTypes',
      gap: 'small',
      options: Object.values(CoachingTypeData).map((option) => ({ value: option.value, label: option.altLabel })),
    },
    {
      label: 'Supporting Age',
      name: 'coachingAudience',
      gap: 'small',
      options: Object.values(CoachAudienceData),
    },
    {
      label: 'Ability to work with',
      name: 'coachingGroupSize',
      gap: 'small',
      options: Object.values(GroupSizeData),
    },
    {
      label: 'Location of coaching',
      name: 'coachingLocation',
      gap: 'small',
      options: Object.values(CoachLocationData),
    },
  ];

  const handleFilterChange = (key: string, checkedValues: string[]) => {
    if (!checkedValues || checkedValues.length === 0) {
      searchParams.delete(key);
      setSearchParams(searchParams, { replace: true });
    } else {
      const newValues: ParamKeyValuePair[] = [];
      searchParams.forEach((currentVal, currentKey) => {
        if (currentKey !== key) {
          newValues.push([currentKey, currentVal]);
        }
      });
      newValues.push([key, checkedValues.join(',')]);
      setSearchParams(newValues);
    }
  };

  const columns: ColumnType<ISchoolDao>[] = [
    {
      key: 'school',
      title: 'Matching schools within your travel radius',
      render: (_, record) => <SchoolSearchTableRow school={record} />,
    },
  ];

  return (
    <>
      <SharedPageHeader title='School search' />
      <div className='px-4 pt-4'>
        <div className='shadow-sm bg-white rounded-md mb-4'>
          <div className='px-4 py-2 border-b bg-gray-50'>
            <p className='font-semibold text-[16px]'>Filters</p>
          </div>
          <div className='p-4 grid grid-cols-1 md:grid-cols-4 gap-4'>
            {checkboxSections.map((section) => (
              <div key={section.name}>
                <p className='font-semibold mb-2'>{section.label}</p>
                <Select
                  mode='multiple'
                  allowClear
                  style={{ width: '100%' }}
                  placeholder='Nothing selected'
                  onChange={(values) => handleFilterChange(section.name, values)}
                  options={section.options}
                  value={filterValues[section.name]}
                />
              </div>
            ))}
          </div>
        </div>
        <Table
          loading={loading}
          columns={columns}
          dataSource={filteredSchools}
          pagination={false}
          scroll={{ x: '300px' }}
        />
      </div>
    </>
  );
};

export default SchoolSearch;
