export const CoachingType = {
  NON_ADVISORY: 'nonAdvisory',
  MENTOR: 'mentor',
};

export const CoachingTypeData = {
  [CoachingType.NON_ADVISORY]: {
    value: CoachingType.NON_ADVISORY,
    label: 'NON-ADVISORY COACH',
    altLabel: 'Non-advisory coach',
    description:
      'I am a coach who helps the coachee set goals, plan actions, identify limiting beliefs and create healthy habits. I do not give advice or guidance. The rapport I develop with the coachee is key for the process and is based in non-judgment.',
    schoolDescription:
      'You are looking for a coach who helps the coachee set goals, plan actions, identify limiting beliefs and create healthy habits. They do not give advice or guidance. The rapport they develop with the coachee is key for the process and is based in non-judgment.',
  },
  [CoachingType.MENTOR]: {
    value: CoachingType.MENTOR,
    label: 'MENTOR-COACH',
    altLabel: 'Mentor-coach',
    description:
      'I am a coach who introduces a coachee to a skill or experience that is a gateway to improving their wellbeing. This might be in areas such as health, music, art, dance, drama, sport, character strengths, animals or nature. My knowledge and experience in this area make me a natural mentor-coach and I use a blend of skills. ',
    schoolDescription:
      'You are looking for a coach who introduces a coachee to a skill or experience that is a gateway to improving their wellbeing. This might be in areas such as health, music, art, dance, drama, sport, character strengths, animals or nature. Their knowledge and experience in this area makes them a natural mentor-coach and they use a blend of skills.',
  },
};
