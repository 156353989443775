import { ColumnType } from 'antd/es/table';
import SharedPageHeader from 'shared/page-header/page-header';
import { Button, Table, Tag } from 'antd';
import { IDomainCoachRegistration, RegistrationsSlice } from '../admin-registrations-slice';
import { useSelector } from 'react-redux';
import { secondsToDateString } from 'core/helpers/date.helpers';
import { CoachRegistrationStatusData } from 'core/constants/coach-registration-status';
import { useState } from 'react';
import AdminCoachRegistrationDetail from './admin-coach-registration-detail';
import { StoreState } from 'store';
import { CoachPlanData } from 'core/constants/coach-plan';
const { Column } = Table;

const AdminCoachRegistrationsList = () => {
  const [openDetail, setOpenDetail] = useState(false);
  const [viewRegistrationDetails, setViewRegistrationDetails] = useState<IDomainCoachRegistration>();
  const { coachRegistrations, status } = useSelector((state: StoreState) =>
    RegistrationsSlice.selectRegistrationsByType(state, 'coach')
  );

  const columns: ColumnType<IDomainCoachRegistration>[] = [
    {
      title: 'Name',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Email',
      dataIndex: 'emailAddress',
      key: 'emailAddress',
    },
    {
      title: 'Plan',
      dataIndex: 'plan',
      key: 'plan',
      render: (plan: string) => {
        const match = CoachPlanData[plan];
        return match?.title ?? 'Unknown';
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        const matchingStatus = CoachRegistrationStatusData[status];
        return <Tag color={matchingStatus.color ?? 'default'}>{matchingStatus?.label ?? 'Unknown'}</Tag>;
      },
    },
    {
      title: 'Created',
      dataIndex: 'createdAtSeconds',
      key: 'createdAt',
      render: (createdAtSeconds: number) => {
        return <p>{secondsToDateString(createdAtSeconds)}</p>;
      },
    },
    {
      key: 'actions',
      render: (_, record) => (
        <Button
          onClick={() => {
            setViewRegistrationDetails(record);
            setOpenDetail(true);
          }}
        >
          View
        </Button>
      ),
      width: '90px',
    },
  ];

  const onClose = () => {
    setOpenDetail(false);
    setViewRegistrationDetails(undefined);
  };

  return (
    <>
      <SharedPageHeader title='Coach registrations' showBack />
      <AdminCoachRegistrationDetail details={viewRegistrationDetails} isOpen={openDetail} onClose={onClose} />
      <div className='px-4 pt-4'>
        <Table
          size='small'
          loading={status !== 'success'}
          pagination={false}
          dataSource={coachRegistrations
            .map((reg) => ({ ...reg, key: reg.uid }))
            .sort((a, b) => b.createdAtSeconds - a.createdAtSeconds)}
          scroll={{ x: `20px` }}
        >
          {columns.map((column) => (
            <Column {...column} />
          ))}
        </Table>
      </div>
    </>
  );
};

export default AdminCoachRegistrationsList;
