import { ColumnType } from 'antd/es/table';
import { IConversationDao } from 'core/api/conversation/conversation-api.interface';
import { firestore } from 'core/config/firebase';
import { CollectionID } from 'core/constants/collection-id';
import { useUserState } from 'core/providers/user-provider';
import { collection, orderBy, where } from 'firebase/firestore';
import MessageCenterTableRow from 'modules/message-center/message-center-table-row';
import SharedPageHeader from 'shared/page-header/page-header';
import SharedPaginatedTable from 'shared/table/paginated-table';

const AdminMyConversations = () => {
  const { userData } = useUserState();

  const columns: ColumnType<IConversationDao>[] = [
    {
      key: 'conversation',
      title: 'Your conversations',
      render: (_, record) => {
        return <MessageCenterTableRow conversation={record} />;
      },
    },
  ];

  return (
    <>
      <SharedPageHeader title='My conversations' showBack />
      <div className='p-4'>
        <SharedPaginatedTable
          tableKey='messageCenter'
          collectionRef={collection(firestore, CollectionID.CONVERSATIONS)}
          queryConstraints={[where('messageCount', '>', 0), where('admin.uid', '==', userData?.uid)]}
          queryOrder={orderBy('updated.at', 'desc')}
          errorMessage='Your conversations could not be retrieved, please try again.'
          columns={columns}
          minWidth={400}
        />
      </div>
    </>
  );
};

export default AdminMyConversations;
