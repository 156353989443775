import StorageApiService from 'core/api/storage/storage-api.service';
import { useState, useCallback, useEffect } from 'react';
import SharedSpinner from 'shared/spinner/spinner';
import { AlertCircle, User } from 'react-feather';
import { CoachingTypeData } from 'core/constants/coach-type';
import { ISchoolDao } from 'core/api/school/school-api.interface';
import { CoachAudienceData } from 'core/constants/coach-audience';
import { CoachLocationData } from 'core/constants/coach-location';
import { GroupSizeData } from 'core/constants/group-size';

interface ISharedSchoolProfile {
  schoolData: ISchoolDao;
}

const SharedSchoolProfile = ({ schoolData }: ISharedSchoolProfile) => {
  const [avatar, setAvatar] = useState<string>();
  const [avatarState, setAvatarState] = useState('loading');

  const getAvatar = useCallback(async (path: string) => {
    try {
      const downloadUrl = await StorageApiService.getFileDownloadUrl(path);
      setAvatar(downloadUrl);
      setAvatarState('success');
    } catch (error) {
      setAvatarState('error');
    }
  }, []);

  useEffect(() => {
    if (schoolData?.profilePhotoPath) {
      getAvatar(schoolData.profilePhotoPath);
    } else {
      setAvatarState('unspecified');
    }
  }, [schoolData?.profilePhotoPath, getAvatar]);

  const getBulletContent = (bullets: string[], data: any, labelKey: string = 'label') => {
    return (
      <ul className='list-disc ml-4'>
        {bullets?.map((key, index) => {
          const match = data[key];
          if (match) {
            return <li key={match.value}>{match[labelKey]}</li>;
          } else {
            return <li key={`unknown${key}`}>Unknown</li>;
          }
        })}
      </ul>
    );
  };

  const getContentSections = (data: ISchoolDao) => {
    return [
      {
        key: 'types',
        title: 'Which methods of coaching are you interested in',
        content: getBulletContent(data.coachingTypes, CoachingTypeData, 'altLabel'),
      },
      {
        key: 'audience',
        title: 'Age group',
        content: getBulletContent(data.coachingAudience, CoachAudienceData),
      },
      {
        key: 'group',
        title: 'Group size',
        content: getBulletContent(data.coachingGroupSize, GroupSizeData),
      },
      {
        key: 'location',
        title: 'Location of coaching',
        content: getBulletContent(data.coachingLocation, CoachLocationData, 'schoolLabel'),
      },
    ];
  };

  return (
    <div className='bg-white shadow-md rounded-md overflow-hidden'>
      <div className='w-full h-[160px] bg-cik_gray' />
      <div className='px-4 pb-4 -mt-[80px]'>
        <div className='h-[140px] w-[140px] border-4 border-white bg-gray-200 rounded-full mr-3 relative flex justify-center items-center overflow-hidden'>
          {avatarState === 'loading' && <SharedSpinner color='#FF875B' size={32} />}
          {avatarState === 'error' && <AlertCircle color='red' size={32} />}
          {avatarState === 'unspecified' && <User size={48} className='text-gray-600' />}
          {avatarState === 'success' && <img src={avatar} alt='avatar' className='w-full' />}
        </div>

        <div className='my-3'>
          <p className='text-xl font-semibold'>{schoolData.schoolName}</p>
          <p>{schoolData.address.formattedAddress}</p>
        </div>

        <div>
          {getContentSections(schoolData).map((section) => (
            <div key={section.key} className='mt-4 border-t border-dashed pt-4 first:border-0'>
              <p className='font-semibold'>{section.title}</p>
              <>{section.content}</>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SharedSchoolProfile;
