import { Button, Checkbox, Divider, Flex, Form } from 'antd';
import { CoachingTypeData } from 'core/constants/coach-type';
import { CoachAudienceData } from 'core/constants/coach-audience';
import { GroupSizeData } from 'core/constants/group-size';
import React, { useEffect } from 'react';
import { CoachLocationData } from 'core/constants/coach-location';
import { ISchoolRegistrationChild } from './school-registration';

const SchoolRegistrationCoachingNeeds = ({
  nextStep,
  previousStep,
  save,
  existingDetails,
}: ISchoolRegistrationChild) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (existingDetails) {
      form.setFieldsValue(existingDetails);
    }
  }, [existingDetails, form]);

  const onFinish = (data: any) => {
    save({ ...data });
    nextStep();
  };

  const checkboxGroupValidator = (value: any) => {
    if (!value || value.length === 0) {
      return Promise.reject(new Error('At least 1 option must be selected'));
    }
    return Promise.resolve();
  };

  const checkboxSections = [
    {
      label: 'Which methods of coaching are you interested in?',
      name: 'coachingTypes',
      gap: 'large',
      customRenderer: (option: any) => (
        <div className='ml-4'>
          <p className='font-semibold mb-px'>{option.label}</p>
          <p>{option.schoolDescription}</p>
        </div>
      ),
      options: Object.values(CoachingTypeData),
    },
    {
      label: 'Age group',
      name: 'coachingAudience',
      gap: 'small',
      options: Object.values(CoachAudienceData),
    },
    {
      label: 'Group size',
      name: 'coachingGroupSize',
      gap: 'small',
      options: Object.values(GroupSizeData),
    },
    {
      label: 'Location of coaching',
      name: 'coachingLocation',
      gap: 'small',
      options: Object.values(CoachLocationData).map((val) => ({ value: val.value, label: val.schoolLabel })),
    },
  ];

  return (
    <Form
      name='coach-registration-my-approach-form'
      form={form}
      onFinish={onFinish}
      layout='vertical'
      variant='filled'
      requiredMark='optional'
    >
      {checkboxSections.map((section, index) => (
        <React.Fragment key={section.name}>
          <Form.Item
            label={section.label}
            name={section.name}
            rules={[
              () => ({
                required: true,
                validator(_, value) {
                  return checkboxGroupValidator(value);
                },
              }),
            ]}
          >
            <Checkbox.Group>
              <Flex vertical gap={section.gap}>
                {section.options.map((option) => (
                  <Checkbox key={option.value} value={option.value}>
                    {section.customRenderer ? section.customRenderer(option) : option.label}
                  </Checkbox>
                ))}
              </Flex>
            </Checkbox.Group>
          </Form.Item>
          {index < checkboxSections.length - 1 && <Divider className='my-8' />}
        </React.Fragment>
      ))}

      <Form.Item>
        <Flex gap='small' className='md:mt-4'>
          <Button type='primary' block onClick={() => previousStep()}>
            Previous
          </Button>
          <Button type='primary' htmlType='submit' block>
            Next
          </Button>
        </Flex>
      </Form.Item>
    </Form>
  );
};

export default SchoolRegistrationCoachingNeeds;
