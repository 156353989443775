import { IActionTimestamp } from 'core/api/api.interface';
import { IUserDao } from 'core/api/user/user-api.interface';
import { Timestamp } from 'firebase/firestore';

export const getActionTimestampFromUser = (user?: IUserDao): IActionTimestamp => {
  return user
    ? {
        at: Timestamp.fromDate(new Date()),
        by: {
          fullName: user.fullName,
          uid: user.uid,
        },
      }
    : {
        at: Timestamp.fromDate(new Date()),
        by: {
          fullName: 'Unknown',
          uid: 'Unknown',
        },
      };
};
