import {
  QueryDocumentSnapshot,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { firestore } from 'core/config/firebase';
import { CollectionID } from 'core/constants/collection-id';
import { IConversationDao } from './conversation-api.interface';
import { AccountType } from 'core/constants/account-type';

const collectionRef = collection(firestore, CollectionID.CONVERSATIONS);

const get = (uid: string) => {
  const docRef = doc(firestore, CollectionID.CONVERSATIONS, uid).withConverter(converter());
  return getDoc(docRef);
};

const getConversationBetweenParticipants = (coachUid: string, schoolUid: string) => {
  const q = query(
    collectionRef,
    where('coach.uid', '==', coachUid),
    where('school.uid', '==', schoolUid),
    limit(1)
  ).withConverter(converter());
  return getDocs(q);
};

const getConversationBetweenParticipantAndAdmin = (
  participantUid: string,
  adminUid: string,
  participantType: string
) => {
  let q = query(collectionRef, where('admin.uid', '==', adminUid), limit(1));
  if (participantType === AccountType.COACH) {
    q = query(q, where('coach.uid', '==', participantUid));
  } else {
    q = query(q, where('school.uid', '==', participantUid));
  }

  return getDocs(q.withConverter(converter()));
};

const set = (dao: IConversationDao) => {
  const docRef = doc(firestore, CollectionID.CONVERSATIONS, dao.uid);
  return setDoc(docRef, dao);
};

const converter = () => ({
  toFirestore: (dao: IConversationDao) => dao,
  fromFirestore: (snapshot: QueryDocumentSnapshot): IConversationDao => snapshot.data() as IConversationDao,
});

const ConversationApiService = {
  get,
  getConversationBetweenParticipants,
  set,
  getConversationBetweenParticipantAndAdmin,
};

export default ConversationApiService;
