import { AccountType, AccountTypes } from 'core/constants/account-type';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import CoachRegistration from './coach-registration/coach-registration';
import SchoolRegistration from './school-registration/school-registration';

const RegisterPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const accountType = searchParams.get('accountType');

  useEffect(() => {
    if (!AccountTypes.some((type) => type === accountType)) {
      setSearchParams(`accountType=${AccountType.COACH}`, { replace: true });
    }
  }, [accountType, setSearchParams]);

  switch (accountType) {
    case 'coach':
      return <CoachRegistration />;
    default:
      return <SchoolRegistration />;
  }
};

export default RegisterPage;
