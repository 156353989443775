import StorageApiService from 'core/api/storage/storage-api.service';
import { useState, useCallback, useEffect } from 'react';
import { AlertCircle, User } from 'react-feather';
import SharedSpinner from 'shared/spinner/spinner';
import { IConversationParticipant } from './conversation-container';
import { useUserState } from 'core/providers/user-provider';
import { AccountType } from 'core/constants/account-type';
import { App, Button } from 'antd';
import { useDialog } from 'core/providers/dialog-provider';
import ReviewApiService from 'core/api/review/review-api.service';
import { IConversationDao } from 'core/api/conversation/conversation-api.interface';
import ReviewCoachDialog from './review-coach-dialog';

interface IConversationHeader {
  isAdminViewing: boolean;
  participants: IConversationParticipant[];
  conversation: IConversationDao;
}

interface IParticipantAvatar {
  uid: string;
  avatar?: string;
  state: string;
}

const ConversationHeader = ({ isAdminViewing, participants, conversation }: IConversationHeader) => {
  const { userData } = useUserState();
  const [avatars, setAvatars] = useState<IParticipantAvatar[]>(
    participants.map(({ uid }) => ({ uid, state: 'loading' }))
  );
  const checkForReviews =
    userData?.accountType === AccountType.SCHOOL && !conversation.admin && conversation.messageCount > 0;
  const dialog = useDialog();
  const [reviewed, setReviewed] = useState(true);
  const { message } = App.useApp();

  const fetchReview = useCallback(async () => {
    try {
      const snap = await ReviewApiService.getForCoachFromReviewer(conversation.coach?.uid!, userData?.uid!);
      if (snap.empty) {
        setReviewed(false);
      }
    } catch (error) {
      message.error('Failed to fetch reviews');
    }
  }, [conversation.coach?.uid, message, userData?.uid]);

  useEffect(() => {
    if (checkForReviews) {
      fetchReview();
    }
  }, [checkForReviews, fetchReview]);

  const getAvatars = useCallback(async () => {
    const promises = participants.map(async ({ uid, avatarPath }) => {
      if (avatarPath) {
        try {
          const downloadUrl = await StorageApiService.getFileDownloadUrl(avatarPath);
          return {
            uid,
            avatar: downloadUrl,
            state: 'success',
          };
        } catch (error) {
          return {
            uid,
            state: 'error',
          };
        }
      } else {
        return {
          uid,
          state: 'unspecified',
        };
      }
    });
    const results = await Promise.all(promises);
    setAvatars(results);
  }, [participants]);

  useEffect(() => {
    getAvatars();
  }, [getAvatars]);

  const getRecipientTemplate = () => {
    const recipient = participants.find((p) => p.direction === 'right')!;
    const { state, avatar } = avatars.find((a) => a.uid === recipient.uid)!;
    return (
      <>
        <div className='h-[48px] w-[48px] bg-gray-200 rounded-full relative flex justify-center items-center overflow-hidden'>
          {state === 'loading' && <SharedSpinner color='#FF875B' size={18} />}
          {state === 'error' && <AlertCircle color='red' size={18} />}
          {state === 'unspecified' && <User size={24} className='text-gray-600' />}
          {state === 'success' && <img src={avatar} alt='avatar' className='w-full' />}
        </div>
        <p className='text-white font-semibold text-[18px]'>{recipient.name}</p>
      </>
    );
  };

  return (
    <div className='bg-cik_orange p-4 w-full flex justify-between items-center'>
      <div className='flex items-center space-x-4'>
        {isAdminViewing && (
          <>
            <div className='flex space-x-1'>
              {participants.map((participant) => {
                const { state, avatar } = avatars.find((a) => a.uid === participant.uid)!;
                return (
                  <div
                    key={participant.uid}
                    className='h-[48px] w-[48px] bg-gray-200 rounded-full relative flex justify-center items-center overflow-hidden'
                  >
                    {state === 'loading' && <SharedSpinner color='#FF875B' size={18} />}
                    {state === 'error' && <AlertCircle color='red' size={18} />}
                    {state === 'unspecified' && <User size={24} className='text-gray-600' />}
                    {state === 'success' && <img src={avatar} alt='avatar' className='w-full' />}
                  </div>
                );
              })}
            </div>
            <p className='text-white font-semibold text-[18px]'>{participants.map((p) => p.name).join(' & ')}</p>
          </>
        )}

        {!isAdminViewing && getRecipientTemplate()}
      </div>

      {checkForReviews && (
        <Button
          disabled={reviewed}
          onClick={() => dialog?.openDialog(<ReviewCoachDialog coachUid={conversation.coach?.uid!} />)}
        >
          {reviewed ? 'Reviewed' : 'Review Coach'}
        </Button>
      )}
    </div>
  );
};

export default ConversationHeader;
