import { App } from 'antd';
import { ICoachDao } from 'core/api/coach/coach-api.interface';
import CoachApiService from 'core/api/coach/coach-api.service';
import ConversationApiService from 'core/api/conversation/conversation-api.service';
import { AccountType } from 'core/constants/account-type';
import { IDropdownMenuOnClickInfo } from 'core/helpers/interface.helpers';
import { getActionTimestampFromUser } from 'core/helpers/user-action.helpers';
import { useUserState } from 'core/providers/user-provider';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SharedCoachProfile from 'shared/coach-profile/coach-profile';
import SharedPageHeader from 'shared/page-header/page-header';
import SharedSpinner from 'shared/spinner/spinner';
import { v4 as uuid } from 'uuid';

const CoachSearchViewCoach = () => {
  const { uid } = useParams();
  const [loading, setLoading] = useState(true);
  const { message } = App.useApp();
  const [coach, setCoach] = useState<ICoachDao>();
  const [menuLoading, setMenuLoading] = useState(false);
  const { schoolData, userData } = useUserState();
  const navigate = useNavigate();

  const getCoach = useCallback(
    async (uid: string) => {
      try {
        const snap = await CoachApiService.get(uid);
        if (!snap.exists() || !snap.data()) {
          message.error('This coach does not exist');
        } else {
          setCoach(snap.data());
          setLoading(false);
        }
      } catch (error) {
        message.error('Failed to fetch coach profile, please try again');
      }
    },
    [message]
  );

  useEffect(() => {
    if (uid) {
      getCoach(uid);
    } else {
      message.error('Invalid parameter provided');
    }
  }, [getCoach, message, uid]);

  const headerMenu = {
    loading: menuLoading,
    items: [
      {
        label: 'Send message',
        key: 'sendMessage',
      },
    ],
    onClick: async ({ key }: IDropdownMenuOnClickInfo) => {
      if (key === 'sendMessage') {
        try {
          if (!coach || !schoolData) {
            throw new Error('Invalid arguments');
          }
          const snap = await ConversationApiService.getConversationBetweenParticipants(coach?.uid!, schoolData?.uid!);
          if (snap.empty) {
            const actor = getActionTimestampFromUser(userData);
            const uid = uuid();
            await ConversationApiService.set({
              coach: coach,
              school: schoolData,
              uid,
              created: actor,
              updated: actor,
              messageCount: 0,
              participants: [
                { uid: coach.uid, accountType: AccountType.COACH },
                { uid: schoolData.uid, accountType: AccountType.SCHOOL },
              ],
            });
            navigate(`/message-center/conversation/${uid}`);
          } else {
            navigate(`/message-center/conversation/${snap.docs[0].id}`);
          }
          setMenuLoading(false);
        } catch (error) {
          setMenuLoading(false);
          message.error('Unable to go to conversation, please try again');
        }
      }
    },
  };

  return (
    <>
      <SharedPageHeader title='Coach profile' showBack menu={headerMenu} />
      {loading || !coach ? (
        <div className='h-[260px] w-full flex items-center justify-center'>
          <SharedSpinner color='#FF875B' size={24} />
        </div>
      ) : (
        <div className='pt-4 px-4'>
          <SharedCoachProfile coachData={coach} />
        </div>
      )}
    </>
  );
};

export default CoachSearchViewCoach;
