import { Drawer } from 'antd';
import { CoachPlan } from 'core/constants/coach-plan';
import { useUserState } from 'core/providers/user-provider';
import { useState } from 'react';

import EditProfileVideo from './edit-profile-video';
import EditPersonalDetails from './edit-personal-details';
import EditMyApproach from './edit-my-approach';
import EditSpecialisms from './edit-specialisms';
import EditProfilePhoto from 'shared/profile/edit-profile-photo';
import SharedEditMarketingPreferences from '../../shared/marketing-preferences/edit-marketing-preferences';

interface IEditProfileDrawer {
  isOpen: boolean;
  onClose: () => void;
}

interface IEditOption {
  key: string;
  label: string;
  content: JSX.Element;
}

const EditProfileDrawer = ({ isOpen, onClose }: IEditProfileDrawer) => {
  const [selectedOption, setSelectedOption] = useState<IEditOption>();
  const { coachData } = useUserState();

  const onChildClose = () => setSelectedOption(undefined);

  const options = [
    {
      key: 'avatar',
      label: 'Profile photo',
      content: (
        <div>
          <EditProfilePhoto accountType='coach' closeDrawer={onChildClose} />
        </div>
      ),
      hide: coachData?.plan === CoachPlan.GROUND_BREAKER,
    },
    {
      key: 'video',
      label: 'Profile video',
      content: (
        <div>
          <EditProfileVideo closeDrawer={onChildClose} />
        </div>
      ),
      hide: coachData?.plan === CoachPlan.GROUND_BREAKER,
    },
    {
      key: 'personalDetails',
      label: 'Personal details',
      content: (
        <div>
          <EditPersonalDetails closeDrawer={onChildClose} />
        </div>
      ),
    },
    {
      key: 'myApproach',
      label: 'My approach',
      content: (
        <div>
          <EditMyApproach closeDrawer={onChildClose} />
        </div>
      ),
    },
    {
      key: 'specialisms',
      label: 'Specialisms',
      content: (
        <div>
          <EditSpecialisms closeDrawer={onChildClose} />
        </div>
      ),
    },
    {
      key: 'marketingPreferences',
      label: 'Marketing preferences',
      content: (
        <div>
          <SharedEditMarketingPreferences closeDrawer={onChildClose} />
        </div>
      ),
    },
  ];

  return (
    <Drawer
      styles={{ body: { padding: 0 } }}
      placement='right'
      size='default'
      title='Edit profile'
      onClose={onClose}
      open={isOpen}
    >
      <Drawer width={450} title={selectedOption?.label} onClose={onChildClose} open={selectedOption !== undefined}>
        {selectedOption?.content}
      </Drawer>
      {options
        .filter((option) => !option.hide)
        .map((option) => (
          <div
            className='border-b px-6 p-4 hover:bg-gray-50 cursor-pointer transition ease-in-out'
            key={option.key}
            onClick={() => setSelectedOption(option)}
          >
            {option.label}
          </div>
        ))}
    </Drawer>
  );
};

export default EditProfileDrawer;
