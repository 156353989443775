import { ColumnType } from 'antd/es/table';
import { IConversationDao } from 'core/api/conversation/conversation-api.interface';
import { firestore } from 'core/config/firebase';
import { AccountType } from 'core/constants/account-type';
import { CollectionID } from 'core/constants/collection-id';
import { useUserState } from 'core/providers/user-provider';
import { QueryConstraint, collection, orderBy, where } from 'firebase/firestore';
import SharedPageHeader from 'shared/page-header/page-header';
import SharedPaginatedTable from 'shared/table/paginated-table';
import MessageCenterTableRow from './message-center-table-row';

const MessageCenter = () => {
  const { userData } = useUserState();
  const constraints: QueryConstraint[] = [];

  if (userData?.accountType === AccountType.SCHOOL) {
    constraints.push(where('school.uid', '==', userData.attachedResourceUid));
  } else if (userData?.accountType === AccountType.COACH) {
    constraints.push(where('coach.uid', '==', userData.attachedResourceUid));
  }

  const columns: ColumnType<IConversationDao>[] = [
    {
      key: 'conversation',
      title: 'Your conversations',
      render: (_, record) => {
        return <MessageCenterTableRow conversation={record} />;
      },
    },
  ];

  return (
    <>
      <SharedPageHeader title='Message center' />
      <div className='px-4 pt-4'>
        <SharedPaginatedTable
          tableKey='messageCenter'
          collectionRef={collection(firestore, CollectionID.CONVERSATIONS)}
          queryConstraints={[...constraints, where('messageCount', '>', 0)]}
          queryOrder={orderBy('updated.at', 'desc')}
          errorMessage='Your conversations could not be retrieved, please try again.'
          columns={columns}
          minWidth={400}
        />
      </div>
    </>
  );
};

export default MessageCenter;
