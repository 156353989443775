import { useState } from 'react';
import SharedPageHeader from 'shared/page-header/page-header';
import { IDropdownMenuOnClickInfo } from 'core/helpers/interface.helpers';
import EditSchoolDrawer from './edit-school-drawer';
import { useUserState } from 'core/providers/user-provider';
import { Alert } from 'antd';
import SharedSchoolProfile from 'shared/school-profile/school-profile';
import CloseAccountDialog from 'modules/my-profile/close-account-dialog';
import { useDialog } from 'core/providers/dialog-provider';

const OurSchool = () => {
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const { schoolData } = useUserState();
  const dialog = useDialog();

  const headerMenu = {
    items: [
      {
        label: 'Edit profile',
        key: 'editProfile',
      },
      {
        label: 'Close my account',
        key: 'closeMyAccount',
      },
    ],
    onClick: ({ key }: IDropdownMenuOnClickInfo) => {
      if (key === 'editProfile') {
        return setOpenEditDrawer(true);
      } else if (key === 'closeMyAccount') {
        dialog?.openDialog(<CloseAccountDialog />);
      }
    },
  };

  return (
    <>
      <SharedPageHeader title='Our school' menu={headerMenu} />
      <EditSchoolDrawer isOpen={openEditDrawer} onClose={() => setOpenEditDrawer(false)} />
      <div className='pt-4 px-4'>
        {!schoolData ? (
          <Alert
            message='Profile details have failed to load, please refresh your page to try again'
            type='error'
            showIcon
          />
        ) : (
          <SharedSchoolProfile schoolData={schoolData} />
        )}
      </div>
    </>
  );
};

export default OurSchool;
