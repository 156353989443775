import { ColumnType } from 'antd/es/table';
import { IConversationDao } from 'core/api/conversation/conversation-api.interface';
import { firestore } from 'core/config/firebase';
import { CollectionID } from 'core/constants/collection-id';
import { IDropdownMenuOnClickInfo } from 'core/helpers/interface.helpers';
import { useDialog } from 'core/providers/dialog-provider';
import { collection, orderBy, where } from 'firebase/firestore';
import MessageCenterTableRow from 'modules/message-center/message-center-table-row';
import { ChevronRight } from 'react-feather';
import { Link } from 'react-router-dom';
import SharedPageHeader from 'shared/page-header/page-header';
import SharedPaginatedTable from 'shared/table/paginated-table';
import AdminBulkMessageDialog from './bulk-message-dialog';

const AdminMessages = () => {
  const dialog = useDialog();
  const columns: ColumnType<IConversationDao>[] = [
    {
      key: 'conversation',
      title: 'All conversations',
      render: (_, record) => {
        return <MessageCenterTableRow conversation={record} />;
      },
    },
  ];

  const headerMenu = {
    items: [
      {
        label: 'Send bulk message',
        key: 'sendBulkMessage',
      },
    ],
    onClick: ({ key }: IDropdownMenuOnClickInfo) => {
      if (key === 'sendBulkMessage') {
        return dialog?.openDialog(<AdminBulkMessageDialog />);
      }
    },
  };

  return (
    <>
      <SharedPageHeader title='Messages' menu={headerMenu} />
      <div className='p-4'>
        <Link to='my-conversations'>
          <div className='p-4 bg-white rounded-md shadow-sm flex justify-between items-center cursor-pointer hover:shadow-md transition ease-in-out'>
            <div className='flex space-x-2'>
              <p>My conversations</p>
            </div>
            <ChevronRight className='text-gray-600' />
          </div>
        </Link>
        <div className='mt-4'>
          <SharedPaginatedTable
            tableKey='adminMessageCenter'
            collectionRef={collection(firestore, CollectionID.CONVERSATIONS)}
            queryConstraints={[where('messageCount', '>', 0)]}
            queryOrder={orderBy('updated.at', 'desc')}
            errorMessage='All conversations could not be retrieved, please try again.'
            columns={columns}
            minWidth={400}
          />
        </div>
      </div>
    </>
  );
};

export default AdminMessages;
