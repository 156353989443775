import { App, Button, Checkbox, Divider, Flex, Form, Input, InputNumber } from 'antd';
import { useForm } from 'antd/es/form/Form';
import CoachApiService from 'core/api/coach/coach-api.service';
import { CoachAudienceData } from 'core/constants/coach-audience';
import { CoachLocationData } from 'core/constants/coach-location';
import { CoachingTypeData } from 'core/constants/coach-type';
import { GroupSizeData } from 'core/constants/group-size';
import { getActionTimestampFromUser } from 'core/helpers/user-action.helpers';
import { useUserState } from 'core/providers/user-provider';
import React, { useEffect, useState } from 'react';

interface IEditMyApproach {
  closeDrawer: () => void;
}

const EditMyApproach = ({ closeDrawer }: IEditMyApproach) => {
  const [form] = useForm();
  const { message } = App.useApp();
  const { coachData, userData } = useUserState();
  const [submitting, setSubmitting] = useState(false);
  const biographyValue = Form.useWatch('biography', form);
  const bioWordCount = biographyValue ? biographyValue.trim().split(/\s+/).length : 0;

  useEffect(() => {
    if (coachData) {
      form.setFieldsValue(coachData);
    }
  }, [coachData, form]);

  const onFinish = async (data: any) => {
    const {
      coachingTypes,
      coachingAudience,
      coachingGroupSize,
      coachingLocation,
      biography,
      rateInPersonGroup,
      rateInPersonIndividual,
      rateOnlineGroup,
      rateOnlineIndividual,
    } = data;
    try {
      setSubmitting(true);
      await CoachApiService.update(coachData?.uid!, {
        coachingTypes,
        coachingAudience,
        coachingGroupSize,
        coachingLocation,
        biography,
        rateInPersonGroup,
        rateInPersonIndividual,
        rateOnlineGroup,
        rateOnlineIndividual,
        updated: getActionTimestampFromUser(userData),
      });
      closeDrawer();
    } catch (error) {
      message.error('There was an error updating your approach, please try again');
      setSubmitting(false);
    }
  };

  const checkboxGroupValidator = (value: any) => {
    if (!value || value.length === 0) {
      return Promise.reject(new Error('At least 1 option must be selected'));
    }
    return Promise.resolve();
  };

  const checkboxSections = [
    {
      label: 'Coaching types',
      name: 'coachingTypes',
      gap: 'large',
      customRenderer: (option: any) => (
        <div className='ml-4'>
          <p className='font-semibold mb-px'>{option.label}</p>
          <p>{option.description}</p>
        </div>
      ),
      options: Object.values(CoachingTypeData),
    },
    {
      label: 'I can coach',
      name: 'coachingAudience',
      gap: 'small',
      options: Object.values(CoachAudienceData),
    },
    {
      label: 'I can work with',
      name: 'coachingGroupSize',
      gap: 'small',
      options: Object.values(GroupSizeData),
    },
    {
      label: 'I can work in',
      name: 'coachingLocation',
      gap: 'small',
      options: Object.values(CoachLocationData),
    },
  ];

  const rateInputs = [
    {
      label: 'Online (Individual)',
      name: 'rateOnlineIndividual',
    },
    {
      label: 'Online (Group – illustrate total for 12 people)',
      name: 'rateOnlineGroup',
    },
    {
      label: 'In-Person (Individual)',
      name: 'rateInPersonIndividual',
    },
    {
      label: 'In-Person (Group – illustrate total for 12 people)',
      name: 'rateInPersonGroup',
    },
  ];

  return (
    <Form
      name='edit-my-approach-form'
      form={form}
      onFinish={onFinish}
      layout='vertical'
      variant='filled'
      requiredMark='optional'
      disabled={submitting}
    >
      {checkboxSections.map((section, index) => (
        <React.Fragment key={section.name}>
          <Form.Item
            label={section.label}
            name={section.name}
            rules={[
              () => ({
                required: true,
                validator(_, value) {
                  return checkboxGroupValidator(value);
                },
              }),
            ]}
          >
            <Checkbox.Group>
              <Flex vertical gap={section.gap}>
                {section.options.map((option) => (
                  <Checkbox key={option.value} value={option.value}>
                    {section.customRenderer ? section.customRenderer(option) : option.label}
                  </Checkbox>
                ))}
              </Flex>
            </Checkbox.Group>
          </Form.Item>
          <Divider className='my-8' />
        </React.Fragment>
      ))}

      <Form.Item
        label='Biography'
        name='biography'
        rules={[
          { required: true, message: 'Please enter your biography' },
          () => ({
            validator(_, value) {
              if (value && value.trim().split(/\s+/).length > 100) {
                return Promise.reject(new Error('Sorry, your biography cannot exceed 100 words'));
              }
              return Promise.resolve();
            },
          }),
        ]}
        extra={bioWordCount < 101 ? `${100 - bioWordCount} words remaining` : undefined}
      >
        <Input.TextArea rows={6} />
      </Form.Item>

      <p>Your average sessional rates</p>
      <p className='text-[12.5px] text-gray-500 mb-4'>Please provide your rates with the 25% CiK discount applied.</p>

      {rateInputs.map((input) => (
        <Form.Item key={input.name} {...input}>
          <InputNumber addonBefore='£' style={{ width: '100%' }} />
        </Form.Item>
      ))}

      <Form.Item>
        <Button type='primary' htmlType='submit' block loading={submitting}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditMyApproach;
