import SharedFooter from 'shared/layout/footer';

interface IAuthWrapper {
  title: string;
  cardContent: any;
  followingContent?: any;
}
const AuthWrapper = ({ title, cardContent, followingContent }: IAuthWrapper) => {
  return (
    <div className='flex-grow pb-[160px] md:pb-[120px] px-4 flex flex-col justify-center items-center'>
      <div className='hidden md:block fixed top-0 w-full p-2 md:p-6'>
        <p className='alegreya text-cik_gray text-3xl mb-24'>Coaches in Kind</p>
      </div>
      <p className='apricots font-bold text-3xl md:text-5xl text-cik_red my-8 md:my-16'>
        <span className='text-cik_orange'>{title.charAt(0)}</span>
        {title.slice(1)}
      </p>
      <div className='bg-white rounded-md p-4 md:p-8 shadow-sm w-full md:w-[500px]'>{cardContent}</div>
      <>{followingContent}</>
      <SharedFooter />
    </div>
  );
};

export default AuthWrapper;
