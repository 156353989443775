import { App, Button, Form, Input, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import MessagesApiService from 'core/api/messages/messages-api.service';
import { useDialog } from 'core/providers/dialog-provider';
import { useTable } from 'core/providers/table-data-provider';
import { useState } from 'react';
import SharedDialogBase from 'shared/dialog/dialog-base';

const AdminBulkMessageDialog = () => {
  const [form] = useForm();
  const dialog = useDialog();
  const [submitting, setSubmitting] = useState(false);
  const { message } = App.useApp();
  const table = useTable('adminMessageCenter');

  const onFinish = async (data: any) => {
    const { bulkMessage, audience } = data;
    try {
      setSubmitting(true);
      await MessagesApiService.sendBulkMessage(bulkMessage, audience);
      setTimeout(() => {
        dialog?.closeDialog();
        message.success('All messages were sent to their recipients');
        table.refreshTable();
      }, 1500);
    } catch (error) {
      message.error('There was an error updating your approach, please try again');
      setSubmitting(false);
    }
  };
  const content = (
    <Form
      className='px-4 pt-4'
      name='bulk-message-form'
      form={form}
      onFinish={onFinish}
      layout='vertical'
      variant='filled'
      requiredMark='optional'
      disabled={submitting}
    >
      <Form.Item label='Message' name='bulkMessage' rules={[{ required: true, message: 'You must provide a message' }]}>
        <Input.TextArea rows={6} />
      </Form.Item>

      <Form.Item label='Audience' name='audience' rules={[{ required: true, message: 'You must select an audience' }]}>
        <Select
          options={[
            { value: 'coaches', label: 'Coaches' },
            { value: 'schools', label: 'Schools' },
            { value: 'all', label: 'All users' },
          ]}
        />
      </Form.Item>

      <Form.Item>
        <div className='flex space-x-2 justify-end'>
          <Button disabled={submitting} onClick={() => dialog?.closeDialog()}>
            Cancel
          </Button>
          <Button type='primary' htmlType='submit' loading={submitting}>
            Send
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
  return <SharedDialogBase title='Bulk message' showButtons={false} customContentTemplate={content} />;
};

export default AdminBulkMessageDialog;
