import { QuerySnapshot, endBefore, getDocs, limit, limitToLast, query, startAfter } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { ISharedPaginatedTable } from './table.interface';
import { useTablesState } from 'core/providers/table-data-provider';
import { Button, Table, App } from 'antd';
const { Column } = Table;

const SharedPaginatedTable = <T,>({
  tableKey,
  collectionRef,
  queryConstraints = [],
  queryLimit = 20,
  queryOrder,
  errorMessage,
  columns,
  minWidth = 200,
}: ISharedPaginatedTable<T>) => {
  const initialQuery = query(collectionRef, queryOrder, ...queryConstraints);
  const [cursor, setCursor] = useState(0);
  const [onLastPage, setOnLastPage] = useState(false);
  const [q, setQ] = useState(query(initialQuery, limit(queryLimit)));
  const [querySnapshot, setQuerySnapshot] = useState<QuerySnapshot>();
  const [loading, setLoading] = useState(true);
  const { register } = useTablesState();
  const { message } = App.useApp();

  const getTableData = useCallback(async () => {
    setLoading(true);
    try {
      const snapshot = await getDocs(q);
      setQuerySnapshot(snapshot);
      setLoading(false);
    } catch (error) {
      message.error(errorMessage);
    }
  }, [errorMessage, message, q]);

  useEffect(() => {
    register({ key: tableKey, refreshTable: getTableData });
  }, [tableKey, getTableData, register]);

  useEffect(() => {
    getTableData();
  }, [getTableData]);

  useEffect(() => {
    const checkForMorePages = async () => {
      if (!querySnapshot) {
        return;
      }
      const { docs, size, empty } = querySnapshot;
      if (empty) {
        setOnLastPage(true);
        return;
      }
      const docCheck = await getDocs(query(initialQuery, startAfter(docs[size - 1]), limit(queryLimit)));
      if (docCheck.empty) {
        setOnLastPage(true);
      } else {
        setOnLastPage(false);
      }
    };
    checkForMorePages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querySnapshot]);

  const previousPage = () => {
    if (!querySnapshot) {
      return;
    }
    const { docs } = querySnapshot;
    setQ(query(initialQuery, endBefore(docs[0]), limitToLast(queryLimit)));
    setCursor((prevState) => prevState - 1);
  };

  const nextPage = () => {
    if (!querySnapshot) {
      return;
    }
    const { docs, size } = querySnapshot;
    setQ(query(initialQuery, startAfter(docs[size - 1]), limit(queryLimit)));
    setCursor((prevState) => prevState + 1);
  };

  return (
    <>
      <Table
        size='small'
        loading={loading}
        pagination={false}
        dataSource={querySnapshot?.docs.map((doc) => ({ ...doc.data(), key: doc.id }))}
        scroll={{ x: `${minWidth}px` }}
      >
        {columns.map((column) => (
          <Column {...column} />
        ))}
      </Table>

      <div className='py-3 space-x-3 flex justify-end'>
        <Button type='primary' htmlType='button' disabled={cursor === 0 || loading} onClick={previousPage}>
          Previous
        </Button>
        <Button type='primary' htmlType='button' disabled={onLastPage || loading} onClick={nextPage}>
          Next
        </Button>
      </div>
    </>
  );
};

export default SharedPaginatedTable;
